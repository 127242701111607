import _findIndex from 'lodash/findIndex';
import _filter from 'lodash/filter';

import { 
    FETCHING_ACCOUNTS, ACCOUNTS_FETCHED, ACCOUNT_EDITED, ACCOUNT_ADDED, 
    FETCHING_TRANSACTIONS, TRANSACTIONS_FETCHED, 
    RELOADING_DETAILS, DETAILS_RELOADED, 
    TRANSFER_COMPLETE, 
    SET_CURRENT_ACCOUNT, 
    ACCOUNT_CREDITED,
    TRANSACTION_COMPLETE,
    ASSETS_CHANGED,
    ASSETS_DEPLETED
} from '../actions/account';
const initialState = { fetchingAccounts: true, accounts: [], assets: 0, liabilities: 0, currentAccount: null, currentAccountIndex: -1 };

const account = (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_ACCOUNTS:
            return {
                ...state,
                fetchingAccounts: action.fetchingAccounts
            };
        case ASSETS_CHANGED:{
            const { assets, liability, balance } = action.payload;

            return {
                ...state, 
                assets,
                balance,
                liabilities: liability
            };
        }
        case ASSETS_DEPLETED:
            return {
                ...state, 
                assets: state.assets - action.amount
            };
        case ACCOUNTS_FETCHED: {
            const accounts = action.accounts.map(a => {
                delete a.user;
                a.amount = a.amount !== undefined ? a.amount : 0;
                a.interest = '0%';
                a.transactions = [];
                a.fetchingTransactions = false;
                a.transactionsFetched = false;
                a.totalTransactionPages = 0;
                a.currentTransactionsPage = 0;
                return a;
            });
            return {...state, accounts};
        }
        case ACCOUNT_ADDED:
            return {
                ...state,
                accounts: [
                    ...state.accounts,
                    action.account
                ]
            };
        case ACCOUNT_EDITED: {
            const accountsAfterEdit = state.accounts.map((account) => {
                if(account._id === action.accountId){
                    account = { ...account, ...action.editedAccount }
                }

                return account;
            });
            return {
                ...state,
                accounts: accountsAfterEdit,
                currentAccount: state.currentAccountIndex !== -1 ? accountsAfterEdit[state.currentAccountIndex] : null
            };
        }
        case ACCOUNT_CREDITED: {
            const accountsAfterCredit = state.accounts.map((account) => {
                if(account._id === action.accountId){
                    let transactions = account.transactions;
                    if(account.transactionsFetched && action.fromAccount){
                        transactions.push({
                            type: 'transfer',
                            fromAccount: action.fromAccount,
                            amount: action.amount
                        });
                    }

                    account = { ...account, balance: account.balance + action.amount, transactions }
                }

                return account;
            });
            return {
                ...state,
                accounts: accountsAfterCredit,
                currentAccount: state.currentAccountIndex !== -1 ? accountsAfterCredit[state.currentAccountIndex] : null
            };
        }
        case SET_CURRENT_ACCOUNT: {
            if(action.id === -1)
                return {
                    ...state,
                    currentAccountIndex: -1,
                    currentAccount: null
                };

            const currentAccountIndex = _findIndex(state.accounts, ['_id', action.id]);
            return {
                ...state,
                currentAccountIndex,
                currentAccount: state.accounts[currentAccountIndex]
            };
        }
        case FETCHING_TRANSACTIONS: {
            const accountsWhenFetching = state.accounts.map((account) => {
                if(account._id === action.accountId){
                    account.fetchingTransactions = action.fetchingTransactions
                }

                return account;
            });
            return {
                ...state,
                accounts: accountsWhenFetching,
                currentAccount: state.currentAccountIndex !== -1 ? accountsWhenFetching[state.currentAccountIndex] : null
            };
        }
        case TRANSACTIONS_FETCHED: {
            const accountsAfterFetch = state.accounts.map((account) => {
                if(account._id === action.accountId){
                    const {data, pagination} = action.transactions;
                    if(pagination.currentPage === 1 || pagination.currentPage === account.currentTransactionsPage)
                        account.transactions = data;
                    else
                        account.transactions = [...account.transactions, ...data];

                    account.totalTransactionPages = pagination.totalPages;
                    account.currentTransactionsPage = pagination.currentPage;
                    account.fetchingTransactions = false;
                    account.transactionsFetched = true;
                }

                return account;
            });
            return {
                ...state,
                accounts: accountsAfterFetch,
                currentAccount: state.currentAccountIndex !== -1 ? accountsAfterFetch[state.currentAccountIndex] : null
            };
        }
        case RELOADING_DETAILS: {
            const accountsWhenReloading = state.accounts.map((account) => {
                if(account._id === action.accountId){
                    account.reloadingDetails = action.reloadingDetails
                }

                return account;
            });
            return {
                ...state,
                accounts: accountsWhenReloading,
                currentAccount: state.currentAccountIndex !== -1 ? accountsWhenReloading[state.currentAccountIndex] : null
            };
        }
        case DETAILS_RELOADED: {
            const { account, transactions } = action;
            const accountsAfterReload = state.accounts.map((ac) => {
                if(ac._id === account._id){
                    const {data, pagination} = transactions;
                    ac = {...ac, ...account, reloadingDetails: false};
                    ac.transactions = data;
                    ac.totalTransactionPages = pagination.totalPages;
                    ac.currentTransactionsPage = pagination.currentPage;
                }

                return ac;
            });
            return {
                ...state,
                accounts: accountsAfterReload,
                currentAccount: state.currentAccountIndex !== -1 ? accountsAfterReload[state.currentAccountIndex] : null
            };
        }
        case TRANSFER_COMPLETE: {
            const accountsAfterTransfer = state.accounts.map((account) => {
            if(account._id === action.fromAccountId || 
                account._id === action.accountId){
                    account.transactions = [...account.transactions, action.transaction]
                }
                
                return account;
            });
            return {
                ...state,
                accounts: accountsAfterTransfer,
                currentAccount: state.currentAccountIndex !== -1 ? accountsAfterTransfer[state.currentAccountIndex] : null
            };
        }
        case TRANSACTION_COMPLETE: {
            const accountsAfterTransaction = state.accounts.map((account) => {
                if(account._id === action.fromAccountId || 
                    account._id === action.accountId){
                    account.balance = action.balance;

                    if(!account.transactions)
                        account.transactions = [];

                    account.transactions = [action.transaction, ...account.transactions];
                }

                return account;
            });
            return {
                ...state,
                accounts: accountsAfterTransaction,
                currentAccount: state.currentAccountIndex !== -1 ? accountsAfterTransaction[state.currentAccountIndex] : null
            };
        }
        default:
            return state;
    }
};

export default account;
