import _filter from 'lodash/filter'
import _clone from 'lodash/clone'
import { formLoading } from './app'
import { userChanged } from './auth'
import { Alert } from './notifications'
import { setPrefs, USER_KEY, getPrefs } from '../../services/prefs';
import { replace, push } from 'connected-react-router';

export const SELECT_DEPOSITER_ACCOUNT = 'SELECT_DEPOSITER_ACCOUNT';
export const SET_DEPOSITER = 'SET_DEPOSITER';
export const SET_DEPOSIT_AMOUNT = 'SET_DEPOSIT_AMOUNT';
export const PAYMENT_METHOD_ADDED = 'PAYMENT_METHOD_ADDED';
export const RESET_DEPOSIT_DETAILS = 'RESET_DEPOSIT_DETAILS';

export const paymentMethodAdded = (paymentMethod) => ({
    type: PAYMENT_METHOD_ADDED,
    paymentMethod
});

export const selectDepositerAccount = (depositerAccountId) => ({
    type: SELECT_DEPOSITER_ACCOUNT,
    depositerAccountId
});

export const setDepositer = (depositer) => ({
    type: SET_DEPOSITER,
    depositer
});

export const setDepositAmount = (amount) => ({
    type: SET_DEPOSIT_AMOUNT,
    amount
});

export const resetDepositDetails = () => ({type: RESET_DEPOSIT_DETAILS});

const checkIfPaymentMethodExists = (paymentMethods, paymentMethod) => {
    return new Promise(resolve => {
        const similarPaymentMethods = _filter(paymentMethods, (p) => {
            const sameName = p.name.toLowerCase() === paymentMethod.name.toLowerCase();
            const sameNumber = p.number.toString().toLowerCase() === paymentMethod.number.toString().toLowerCase();
    
            return sameName || sameNumber;
        });
    
        if(similarPaymentMethods.length){
            const sameName = similarPaymentMethods[0].name.toLowerCase() === paymentMethod.name.toLowerCase();
            const message = sameName ? `name '<strong>${paymentMethod.name}</strong>'` : `number '<strong>${paymentMethod.number}</strong>'`;
    
            resolve({ 
                paymentMethodExists: true, 
                errorMessage: `You already have a payment method with ${message}`
            });
        }
        else{
            resolve({ paymentMethodExists: false })
        }
    });
}

export const persistPaymentMethod = (paymentMethod) => async dispatch => {
    dispatch(formLoading(true));
    let authUser = await getPrefs(USER_KEY);
    let paymentMethods = authUser.paymentMethods || {
        mobilewallet: [], bankaccount: [], card: []
    };

    const shortType = paymentMethod.type.toLowerCase().replace(' ', '');
    const paymentMethodsForType = paymentMethods[shortType] ? paymentMethods[shortType] : [];
    paymentMethod.default = paymentMethodsForType.length < 1;
    paymentMethod._id = shortType + '-' + Math.random().toString(36).substr(2, 5);

    const { paymentMethodExists, errorMessage } = await checkIfPaymentMethodExists(paymentMethodsForType, paymentMethod);
    if(paymentMethodExists){
        dispatch(Alert(errorMessage));
        dispatch(formLoading(false));
        return;
    }

    paymentMethods[shortType] = [...paymentMethodsForType, paymentMethod];
    authUser.paymentMethods = paymentMethods;

    await setPrefs(USER_KEY, authUser);

    dispatch(formLoading(false));
    dispatch(paymentMethodAdded(paymentMethod));
    dispatch(userChanged(authUser));

    dispatch(selectDepositerAccount(paymentMethod._id));

    let depositor = _clone(paymentMethod);
    delete depositor.type;
    delete depositor.default;

    dispatch(setDepositer(depositor))

    /**
     * Close url for picking payment method
     * with replace to remove it from back stack
    */
    dispatch(replace({
        hash: '#finlinkModal/WithdrawMoney',
        search: ''
    }))
    /**
     * Open modal to enter deposit amount
     * Replace with addding a listener for depositer
     * on deposit modal and when depositer changes, 
     * open deposit amount dialpad
    */
    dispatch(push({
        hash: '#finlinkModal/WithdrawMoney',
        search: 'enterDepositAmount=true'
    }))
}