import React from 'react';
import Spacer from '../../../../components/Spacer';
import FlexBox from '../../../../components/FlexBox';

const GenericTransaction = ({ transaction }) => {
    transaction.currency = transaction.currency || "TZS";
    transaction.to = transaction.toAccount ? transaction.toAccount.name : "";
    transaction.from = transaction.fromExternalAccount || "";
    if(transaction.fromAccount)
        transaction.from = transaction.fromAccount.name;

    if(transaction.type === "commission")
        transaction.from = "Credit Interests";
    else if(transaction.type === "interest")
        transaction.to = "Debit Interest";

    return (
        <div className={'transactionItem ' + transaction.type}>
           <div>
                <h3>{ transaction.currency.toUpperCase() } { Math.abs(transaction.amount).toLocaleString() }</h3>
                <span>
                    <FlexBox align="center">
                        {transaction.from.toLowerCase()}
                        <Spacer width="8" />
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#cecece" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><polyline points="12 16 16 12 12 8"></polyline><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                        <Spacer width="8" />
                        {transaction.to.toLowerCase()}
                    </FlexBox>
                </span>
                <strong>{ transaction.date }</strong>
            </div>

            <small>{transaction.type}</small>
        </div>
    );
}
 
export default GenericTransaction;