import React from 'react';

const ThaTitle = ({startMonth, endMonth}) => {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ];
    let label = 'Click on a month to filter';
    if((startMonth === undefined || startMonth !== -1) && (endMonth || endMonth === -1))
        label = "Click on another month to set end month.";
    // label += ' month';
    // label += endMonth !== -1 ? 's' : '';

    const startMonthName = startMonth && months[startMonth] ? months[startMonth] : '';
    const endMonthName = endMonth && months[endMonth] ? months[endMonth] : '';
    return (
        <div id="title" className={'layout center' + ( startMonth && startMonth !== -1 ? ' not-empty' : '' )}>
            <span>{label}</span>
            <strong>
                {startMonth !== -1 && startMonthName.toUpperCase()}
            </strong>


            { endMonth && endMonth !== -1 && 
                <React.Fragment>
                    {/* <svg viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"/></svg> */}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#cecece" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><polyline points="12 16 16 12 12 8"></polyline><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                    <strong> { endMonth !== -1 && endMonthName }</strong>
                </React.Fragment>
            }
        </div>
    )
}

export default ThaTitle