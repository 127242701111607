/* eslint-disable no-console */
import * as localForage from "localforage";
import store from '../store';
export const PREFS_KEY = 'FINLINK_PREFS';
export const USER_KEY = 'user';

export const getPrefs = async (key) => {
    return new Promise(resolve => {
        try{
            localForage.getItem(PREFS_KEY).then(prefs => {
                if(!prefs){
                    prefs = key ? null : {};
                }
                else if(prefs && key){
                    prefs = prefs[key];
                }
    
                resolve(prefs);
            });
        }
        catch(error){
            resolve({});
        }
    });
}

export const getToken = async () => {
    let user = await store.getState().auth.user;
    try {
        return user.token;
    } catch(err) {
        return null;
    }
}

export const setPrefs = async (key, value) => {
    let prefs = await getPrefs();
    return new Promise((resolve, reject) => {
        try {
            prefs[key] = value;
            localForage.setItem(PREFS_KEY, prefs);
            resolve();
        } catch(err) {
            console.log("Error setting prefs", err);
            reject(false);
        }
    });
}

export const clearPrefs = async () => {
    return new Promise((resolve, reject) => {
        try {
            localForage.removeItem(PREFS_KEY).then(() => resolve());
        } catch(err) {
            console.log("Error clearing prefs", err);
            reject(false);
        }
    });
}