import React from 'react';
import _filter from 'lodash/filter';
import FlAlert from "../../../../components/FlAlert";

class PickPaymentMethod extends React.Component{
    componentDidMount(){
        const {type, paymentMethods, depositerAccountId, onSelectDepositorAccount} = this.props;
        const shortType = type.toLowerCase().replace(' ', '');
        const paymentMethodChoices = paymentMethods ? paymentMethods[shortType] : [];
        const defaultPaymentMethods = _filter(paymentMethodChoices, 'default');
        const defaultPaymentMethod = defaultPaymentMethods.length ? defaultPaymentMethods[0] : null;

        if(defaultPaymentMethod){
            const defaultPaymentMethodType = defaultPaymentMethod._id.split('-')[0];
            const curPaymentMethodType = depositerAccountId ? depositerAccountId.split('-')[0] : null;
            const sameTypes = defaultPaymentMethodType === curPaymentMethodType;

            if(!depositerAccountId || !sameTypes)
                onSelectDepositorAccount(defaultPaymentMethod._id);
        }
    }

    render(){
        const {type, paymentMethods, depositerAccountId, 
            onCancel, onAddClicked, onSubmitClicked} = this.props;
        const shortType = type.toLowerCase().replace(' ', '');
        const paymentMethodChoices = paymentMethods ? paymentMethods[shortType] : [];
        const onOkay = depositerAccountId ? onSubmitClicked : null;
        return ( 
            <FlAlert onCancel={onCancel} onOkay={onOkay}>
                <p className="accountChoiceTitle">Pick {type}</p>
    
                <button type="button" className="accountChoice"
                    onClick={onAddClicked}>
                    <i className="icon">
                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                    </i>
                    Add {type}
                </button>
                
                {
                    paymentMethodChoices && paymentMethodChoices.map(paymentMethod => {
                        const selected = depositerAccountId && paymentMethod._id === depositerAccountId;
                        return (
                            <button key={paymentMethod._id} type="button" 
                                className={'accountChoice ' + (selected ? 'selected' : '')}
                                onClick={() => this.props.onSelectDepositorAccount(paymentMethod._id)}>
                                <i className="icon">
                                    { selected && 
                                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                    }
                                    
                                    { !selected && 
                                        <span>{paymentMethod.name.charAt(0).toUpperCase()}</span>
                                    }
                                </i>
                                <div>
                                    { paymentMethod.name }
                                    <small>
                                        { paymentMethod.number }
                                    </small>
                                </div>
                            </button>
                        )
                    })
                }
    
                <div style={{marginBottom: '0.5em'}}></div>
            </FlAlert>
        );
    }
}
 
export default PickPaymentMethod;