import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames/bind';

import _flatten from 'lodash/flatten';
import { canNotify } from '../../components/FlNotifications';
import { resetPassword } from '../../store/actions/auth';

import styles from './styles.scss';

import { FlInput } from '../../components/FormControls';
import FlButton from '../../components/FlButton';
import FormValidator from '../../services/FormValidator';

const cx = classnames.bind(styles);

class ResetPassword extends React.Component {
    state = {
        password: "",
        confirmPassword: "",
        validationErrors: {},
        initialValidation: false
    };

    validator = new FormValidator({
        password: 'min:6',
        confirmPassword: 'min:6|asField:password'
    });

    validateForm = async () => {
        const validationErrors = await this.validator.validate(this.state);
        this.setState({validationErrors, initialValidation: true});
        const firstInvalidInput = Object.keys(validationErrors)[0];
        
        //get the first input that is not a radio or checkbox
        const inputNode = document.querySelector(`[name=${firstInvalidInput}]`);
    
        return [_flatten(Object.values(validationErrors)), inputNode];
    }

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, 
          () => this.state.initialValidation ? this.validateForm() : null
        );
    }
    
    handleSubmit = async (e) => {
        e.preventDefault();
        const [errors, inputNode] = await this.validateForm();
    
        if(errors.length){
            if(inputNode)
                inputNode.focus();

            return this.props.Toast('Form has some errors');
        }
        
        const { location } = this.props.history;
        const queryString = location.search;
        let resetToken = '';
        if(queryString && queryString.length){
            resetToken = queryString.split('&')[0].replace('?token=', '');
        }

        const data = {password: this.state.password, confirmPassword: this.state.confirmPassword};
        const usingPhone = queryString.indexOf('usingPhone') !== -1;
        this.props.onResetPassword(data, resetToken, usingPhone);
    }

    render() {
        const {
            password,
            confirmPassword,
            validationErrors
        } = this.state;
        
        return (
            <div className={cx('authScene confirmScene')}>
                <form className={cx('form')}
                    onSubmit={this.handleSubmit}>

                    <div className={cx('logoImage circle')}>
                        {/* <svg viewBox="0 0 24 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 9.9-1"></path></svg> */}
                        <svg viewBox="0 0 24 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                    </div>

                    <div className={cx('formTitle')} 
                        style={{marginBottom: '0.9em'}}>
                        Reset Password
                    </div>

                    <p style={{color: '#9d9d9d'}}>
                        Enter and confirm new password then click button to reset.
                    </p>

                    <FlInput
                        type="password"
                        label="New Password"
                        name='password'
                        value={password} 
                        onChange={this.handleChange}
                        errors={validationErrors.password} />
                    
                    <FlInput
                        type="password"
                        label="Confirm New Password"
                        disabled={!password.length}
                        name='confirmPassword'
                        value={confirmPassword} 
                        onChange={this.handleChange}
                        errors={validationErrors.confirmPassword} />

                    <FlButton type="submit" block primary large
                            loading={this.props.saving}>
                        RESET PASSWORD
                    </FlButton>
                </form>
            </div>
        );
    }
}


function mapStateToProps(state){
    return {
        saving: state.app.formLoading
    }
}

function mapDispatchToProps(dispatch){
    return {
        onResetPassword: (data, resetToken, usingPhone) => {
            dispatch( resetPassword(data, resetToken, usingPhone) )
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(canNotify(ResetPassword));