import React from 'react';
import { useState } from 'react';
import { withRouter } from 'react-router';
import { canNotify } from '../../../../components/FlNotifications';
import FlButton from '../../../../components/FlButton';
import FlInput from '../../../../components/FormControls/FlInput';
import { makeDeposit } from '../../../../services/API';
import { makePhoneNumberValid } from '../../../../utils';
import { connect } from 'react-redux';
import { setDepositAmount, setDepositer } from '../../../../store/actions/deposit-modal';
import _flatten from 'lodash/flatten';

import FormValidator from '../../../../services/FormValidator';
import { useEffect } from 'react';

const DepositForm = ({history, depositer, toAccount, depositAmount, saving, onChangeDepositer, onPersistDeposit, ...props}) => {
    const [cardName, setCardName] = useState(depositer && depositer.name || "Visa Main");
    const [savingCard, setSavingCard] = useState(false);
    const [validationTriggered, setValidationTriggered] = useState(false);
    const [validationErrors, setValidationErrors] = useState(null);

    useEffect(() => {
        if(validationTriggered) validateForm();
    }, [cardName, depositer, depositAmount])

    async function validateForm(){
        setValidationTriggered(true);
        let { _id, tempId } = depositer;
        const depositerId = tempId || _id;
        const isCard = depositerId && depositerId.indexOf('card') !== -1;

        const validator = new FormValidator({
            name: 'min:3',
            amount: 'number|min:1000'
        });

        const errors = await validator.validate({
            amount: depositAmount,
            name: isCard ? cardName: depositer.name,
        });

        setValidationErrors(errors);
    
        return [_flatten(Object.values(errors)), errors];
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let { _id, tempId, number, provider, ...otherDepositorDetails } = depositer;
        const depositerId = tempId || _id;
        const isCard = depositerId && depositerId.indexOf('card') !== -1;
        const isMobileWallet = depositerId && depositerId.indexOf('mobilewallet') !== -1;
        
        const [flattenedErrors] = await validateForm();

        if(flattenedErrors.length) return;

        if(isMobileWallet){
            number = makePhoneNumberValid(number);
            number = number.substring(1);
        }

        else if(isCard){
            const depositorDetails = {
                provider, number, amount: depositAmount,
                ...otherDepositorDetails,
                cardName,
            };

            setSavingCard(true);
            
            const cardDepositMessage = `${depositAmount.toLocaleString()} has been added to your account.`;
            const cardDepositRes = await makeDeposit(toAccount._id, depositorDetails);
            setSavingCard(false);
            history.replace('/');
            props.onSetDepositor(null);

            if((!cardDepositRes.redirectUrl || !cardDepositRes.redirectUrl.length)){
                if(cardDepositRes.error)
                    props.Alert("Failed to process your card!", "Please check card details and try again.");
                else
                    props.Alert("Deposit Successful", cardDepositMessage);

                return;
            }

            props.Toast("Card payment request submitted");
            setTimeout(() => {
                window.location.href = cardDepositRes.redirectUrl;
            }, 1500);
            return;
        }

        onPersistDeposit(toAccount._id, depositAmount, number, provider);
    }

    function cardNameNode(){
        const depositerId = depositer.tempId || depositer._id;
        const isCard = depositerId && depositerId.indexOf('card') !== -1;

        if(isCard){
            return (
                <div style={{ marginTop: "3rem", marginBottom: "-1.5rem" }}>
                    <FlInput
                        label="Card Name"
                        value={cardName}
                        onChange={e => setCardName(e.target.value)}
                        errors={validationErrors && validationErrors.name}
                    />
                </div>
            );
        }

        return (
            <>
                <label>Deposit From</label>
                <div className="accountChoice selected">
                    <div className="flex" style={{ maxWidth: "calc(100% - 70px)" }}>
                        <div style={{ lineHeight: "1.8" }}>
                            { depositer.name }
                        </div>
                        <small style={{ whiteSpace: "nowrap", width: "100%", overflow: "hidden", textOverflow: "ellipsis", paddingRight: "0.5rem" }}>
                            { depositer.buyerUserId || depositer.number }
                        </small>
                    </div>
                    
                    <button type="button" className="accountChoiceChanger"
                        style={{ flexShrink: 0 }}
                        onClick={onChangeDepositer}>
                        Change
                    </button>
                </div>
            </>
        );

    }

    return (  
        <form onSubmit={handleSubmit}>
            { cardNameNode() }
            
            {/* <label>Amount</label>
            <div className="accountChoice selected">
                <div className="flex">
                    <span>{ depositAmount.toLocaleString() }</span>
                </div>
                
                <button type="button" className="accountChoiceChanger"
                    onClick={onChangeAmount}>
                    Change
                </button>
            </div> */}

            <FlInput
                label="Amount"
                type="number"
                defaultValue={depositAmount}
                onChange={e => props.onSetDepositAmount(e.target.value)}
                errors={validationErrors && validationErrors.amount}
            />

            <FlButton type="submit" rounded primary block loading={saving || savingCard}>
                Make Deposit
            </FlButton>
        </form>
    );
}

function mapDispatchToProps(dispatch){
    return {
        onSetDepositor: (account) => {
            dispatch(setDepositer(account))
        },
        onSetDepositAmount: (amount) => {
            dispatch(setDepositAmount(amount));
        },
    }
}
 
export default connect(null, mapDispatchToProps)(canNotify(withRouter(DepositForm)));