import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import _flatten from 'lodash/flatten';

import { canNotify } from '../../../components/FlNotifications';
import FormValidator from '../../../services/FormValidator';

import classnames from 'classnames/bind';
import styles from '../styles.scss';

import { FlInput } from '../../../components/FormControls';
import FlButton from '../../../components/FlButton';
import { sendResetPasswordEmail } from '../../../store/actions/auth';

const cx = classnames.bind(styles);

class ForgotPasswordEmail extends React.Component {
    state = {
        email: '',
        validationErrors: {},
        initialValidation: false
    }

    validator = new FormValidator({
        email: 'email'
    });

    validateForm = async () => {
        const validationErrors = await this.validator.validate(this.state);
        this.setState({validationErrors, initialValidation: true});

        return _flatten(Object.values(validationErrors));
    }

    handleChange = event => {
        const { value } = event.target;
        this.setState({ email: value }, 
          () => this.state.initialValidation ? this.validateForm() : null
        );
    }

    handleSubmit = async event => {
        event.preventDefault();
        const errors = await this.validateForm();
    
        if(errors.length){
            document.querySelector("[name=email]").focus();
            return this.props.Toast('Invalid Email');
        }

        this.props.onSendResetEmail(this.state.email);
    }

    render() {
        const {
            email,
            validationErrors
        } = this.state;

        return ( 
            <div className={cx('authScene forgotScene')}>
                <form className={cx('form')}
                    onSubmit={this.handleSubmit}>

                    <img src='logo.png' className={cx('logoImage')} alt="" />

                    <div className={cx('formTitle')}>
                        Forgot Password
                    </div>

                    <p style={{ color: '#9f9f9f', marginBottom: '2em'}}>
                        Enter your email below and we'll send you a reset password link.
                    </p>

                    <FlInput
                        type="email"
                        label="Email"
                        name="email"
                        placeholder="E.g. danny@gmail.com"
                        value={email}
                        onChange={this.handleChange}
                        errors={validationErrors.email} />

                    <FlButton type="submit"
                        block primary large
                        loading={this.props.saving}>
                        Submit
                    </FlButton>

                    <div className="siblingPageLink" style={{flexDirection: 'column'}}>
                        <span className="text-center" style={{marginBottom: '1.2em', lineHeight: '1.4em'}}>
                            Can't seem to remember your email?
                        </span>
                        <Link to={{ search: '' }} style={{textTransform: '', color: '#ddd'}}>
                            Use phone number
                        </Link>
                    </div>

                    <div className="siblingPageLink" style={ { marginTop: '3em' } }>
                        <Link to="/login">Back to Login</Link>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state){
    return {
        saving: state.app.formLoading
    }
}

function mapDispatchToProps(dispatch){
    return {
        onSendResetEmail: (email) => {
            dispatch( sendResetPasswordEmail(email) )
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(canNotify(ForgotPasswordEmail));