import React from 'react';
import * as Sentry from '@sentry/browser';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store, { history } from './store';
import AppWrapper from './AppWrapper';

import * as serviceWorker from './serviceWorker';

Sentry.init({dsn: "https://0f2bbc41df1d4d338f18e999cf8b1d8d@sentry.io/1735534"});

render(
  <Provider store={store}>
    <AppWrapper history={history} />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();