import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import * as reducers from './reducers';
import { AUTH_DESTROY } from './actions/auth';

export const history = createBrowserHistory();

const appReducer = combineReducers({
  ...reducers
});

const rootReducer = (state, action) => {
  if (action.type === AUTH_DESTROY) {
    const { router } = state
    state = { router } 
  }

  return appReducer(state, action)
}

const initialState = {}
const enhancers = []
const middleware = [thunk, routerMiddleware(history)]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

 export const storeCreator = (defaultState = initialState) => createStore(
  connectRouter(history)(rootReducer),
  defaultState,
  composedEnhancers
)

export default storeCreator();