/* eslint-disable no-console */
import { createSavingsAccount, editSavingsAccount, 
        fetchUserAccounts, getAccountTransactions, 
        makeTransfer, makeDeposit, makeTransferToInvestor, getAccountDetails} from "../../services/API";
import { formLoading } from "./app";
import { Toast, Alert } from "./notifications";
import { replace, goBack } from "react-router-redux";
import { resetDepositDetails, setDepositer } from "./deposit-modal";
import { EM, CACHE_RESPONSE_KEY } from "../../services/API/server_calls";
import { fetchUserTransactions } from "./user-transactions";

export const FETCHING_ACCOUNTS = 'FETCHING_ACCOUNTS';
export const ASSETS_CHANGED = 'ASSETS_CHANGED';
export const ASSETS_DEPLETED = 'ASSETS_DEPLETED';
export const ACCOUNTS_FETCHED = 'ACCOUNTS_FETCHED';
export const ACCOUNT_ADDED = 'ACCOUNT_ADDED';
export const ACCOUNT_EDITED = 'ACCOUNT_EDITED';
export const ACCOUNT_CREDITED = 'ACCOUNT_CREDITED';
export const SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT';
export const FETCHING_TRANSACTIONS = 'FETCHING_TRANSACTIONS';
export const RELOADING_DETAILS = 'RELOADING_DETAILS';
export const DETAILS_RELOADED = 'DETAILS_RELOADED';
export const TRANSACTIONS_FETCHED = 'TRANSACTIONS_FETCHED';
export const TRANSACTION_COMPLETE = 'TRANSACTION_COMPLETE';
export const TRANSFER_COMPLETE = 'TRANSFER_COMPLETE';

export const accountsFetched = (accounts) => ({
    type: ACCOUNTS_FETCHED,
    accounts
});

export const assetsChanged = (payload) => ({
    type: ASSETS_CHANGED,
    payload
});

export const assetsDepleted = (amount) => ({
    type: ASSETS_DEPLETED,
    amount
});

export const accountAdded = (account) => ({
    type: ACCOUNT_ADDED,
    account
});

export const accountEdited = (accountId, editedAccount) => ({
    type: ACCOUNT_EDITED,
    accountId,
    editedAccount
});

export const accountCredited = (accountId, amount, fromAccount) => ({
    type: ACCOUNT_CREDITED,
    accountId,
    fromAccount,
    amount
});

export const setCurrentAccount = (id) => ({
    type: SET_CURRENT_ACCOUNT,
    id
});

export const fetchingAccounts = (fetchingAccounts) => ({
    type: FETCHING_ACCOUNTS,
    fetchingAccounts
});

export const fetchingTransactions = (accountId, fetchingTransactions = true) => ({
    type: FETCHING_TRANSACTIONS,
    accountId,
    fetchingTransactions
});

export const transactionsFetched = (accountId, transactions) => ({
    type: TRANSACTIONS_FETCHED,
    accountId,
    transactions
});

export const reloadingDetails = (accountId, reloadingDetails = true) => ({
    type: RELOADING_DETAILS,
    accountId,
    reloadingDetails
});

export const detailsReloaded = (account, transactions) => ({
    type: DETAILS_RELOADED,
    account, 
    transactions
});

export const transferComplete = (accountId, transaction) => ({
    type: TRANSFER_COMPLETE,
    accountId,
    transaction
});

export const transactionComplete = (accountId, balance, transaction) => ({
    type: TRANSACTION_COMPLETE,
    accountId,
    balance,
    transaction
});

export const fetchAccounts = (currentId, reloading = false) => async dispatch => {
    dispatch(fetchingAccounts(true));
    let localDataCopyFound = false;
    try {
        // if(!reloading){
        //     EM.once(CACHE_RESPONSE_KEY + '/accounts', localDataCopy => {
        //         dispatch(accountsFetched(localDataCopy.data));
        //         dispatch(assetsChanged(localDataCopy.totalAmount));
        //         dispatch(fetchingAccounts(false));
        //         localDataCopyFound = true;
        //     });
        // }

        const response = await fetchUserAccounts(true);
        const { accounts, overview } = response.data;
        dispatch(accountsFetched(accounts));
        dispatch(assetsChanged(overview));
        dispatch(fetchingAccounts(false));

        if(currentId)
            dispatch(setCurrentAccount(currentId))
    } catch (error) {
        dispatch(fetchingAccounts(false));
        if(error.message !== "You're offline!"){
            if(!localDataCopyFound)
                dispatch(Alert('Failed to fetch your accounts', 'Make sure you have an active internet connection and try again.'));

            console.error('Fetch account Error', error);
        }
    }
};

export const createAccount = (data) => async dispatch => {
    dispatch(formLoading(true));

    try {
        let newAccount = await createSavingsAccount(data);
        newAccount = { ...newAccount,
            amount : 0,
            interest: '0%',
            fetchingTransactions: false,
            transactionsFetched: false,
            transactions: [],
            totalTransactionPages: 0,
            currentTransactionsPage: 0
        }

        dispatch(formLoading(false));
        dispatch(accountAdded(newAccount));
        dispatch(replace('/main'));
        dispatch(Toast('Account Created'));
    } catch (error) {
        dispatch(formLoading(false));
        if(error.message !== "You're offline!"){
            dispatch(Alert('Couldn\'t create account'));
            console.error('Create Account Error', error);
        }
    }
};

export const editAccount = (data, accountId) => async dispatch => {
    dispatch(formLoading(true));

    try {
        let editedAccount = await editSavingsAccount(data, accountId);
        if(editAccount.amount === undefined)
            editAccount.amount = 0;

        dispatch(formLoading(false));
        dispatch(accountEdited(accountId, editedAccount));
        dispatch(replace(`/main/account/${accountId}`));
        dispatch(Toast('Account Edited'));

    } catch (error) {
        dispatch(formLoading(false));
        if(error.message !== "You're offline!"){
            dispatch(Alert('Failed to edit account'));
            console.error('Edit Account Error', error);
        }
    }
};

export const fetchTransactions = (accountId) => async dispatch => {
    dispatch(fetchingTransactions(accountId));
    const cacheDataBroadcastKey = CACHE_RESPONSE_KEY + `/accounts/${accountId}/transactions`;
    let localDataCopyFound = false;

    try {
        EM.once(cacheDataBroadcastKey, localDataCopy => {
            if(localDataCopy)
                dispatch(transactionsFetched(accountId, localDataCopy));

            localDataCopyFound = true;
        });

        const transactions = await getAccountTransactions(accountId);
        dispatch(transactionsFetched(accountId, transactions));
    } catch (error) {
        dispatch(fetchingTransactions(accountId, false));
        if(error.message !== "You're offline!"){
            if(!localDataCopyFound)
                dispatch(Toast('Failed to fetch transactions'));
                
            console.error('Fetch transactions error', error);
        }
    }
};

export const reloadAccountDetails = (accountId) => async dispatch => {
    dispatch(reloadingDetails(accountId));

    try {
        const newAccountDetails = await Promise.all([
            getAccountDetails(accountId),
            getAccountTransactions(accountId)
        ]);
        const account = {_id: accountId, ...newAccountDetails[0].data};
        const transactions = newAccountDetails[1];
        dispatch(detailsReloaded(account, transactions));
        dispatch(Toast('Account details reloaded'));
    } catch (error) {
        dispatch(reloadingDetails(accountId, false));
        if(error.message !== "You're offline!"){
            dispatch(Toast('Failed to reload account details'));
            console.error('Fetch transactions error', error);
        }
    }
};

export const transferFunds = (data, fromAccountId) => async dispatch => {
    dispatch(formLoading(true));

    try {
        let response = await makeTransfer(data, fromAccountId);
        const responseData = response.data;
        const {name, balance} = responseData.fromAccount;
        
        dispatch(formLoading(false));
        
        let transferSuccessMessage;
        if(data.transfers.length > 1){
            transferSuccessMessage = `Transfer from <strong>${name}</strong> successfull<br/><span style="display: block; height: 12px"></span>`;
            
            data.transfers.forEach(({toAccountId, toAccountName, amount}) => {
                const transaction = {
                    type: "transfer", 
                    currency: "tzs", 
                    amount: amount * -1,
                    createdAt: new Date().toISOString(),
                    fromAccount: {_id: toAccountId, name: toAccountName}
                };
                dispatch(transactionComplete(fromAccountId, balance, transaction));
                dispatch(accountCredited(toAccountId, amount, name));
                transferSuccessMessage += `${amount.toLocaleString()} to <strong>${toAccountName}</strong><br/>`;
            });
        }
        else{
            const account = data.transfers[0];
            const id = account.toAccountId;
            const amount = account.amount;
            const toAccountName = account.toAccountName;
            transferSuccessMessage = `Transfer of <strong>${amount.toLocaleString()}</strong> from <strong>${name}</strong> to <strong>${toAccountName}</strong> successfull!`;
            dispatch(accountCredited(id, amount, name));

            const transaction = {
                type: "transfer", 
                currency: "tzs", 
                amount: amount * -1,
                createdAt: new Date().toISOString(),
                fromAccount: {_id: id, name: toAccountName}
            };
            dispatch(transactionComplete(fromAccountId, balance, transaction));
        }

        dispatch(replace('/'));
        dispatch(setCurrentAccount(-1));
        dispatch(Alert(null, transferSuccessMessage, () => {
            dispatch(fetchAccounts(null, true));
            dispatch(fetchUserTransactions());
        }));
    } catch (error) {
        dispatch(formLoading(false));
        if(error.message !== "You're offline!"){
            dispatch(Alert('Couldn\'t complete transfer'));
            console.error('Create Transaction Error', error);
        }
    }
};

export const transferFundsToInvestor = (fromAccountId, data) => async dispatch => {
    dispatch(formLoading(true));

    try {
        let response = await makeTransferToInvestor(data);
        const {myAccount, investor} = response.data;
        const name = investor.displayName;
        const {balance} = myAccount;

        dispatch(formLoading(false));
        dispatch(assetsDepleted(data.amount));

        const transaction = {
            type: "transfer", 
            currency: "tzs", 
            amount: data.amount * -1,
            createdAt: new Date().toISOString(),
            fromAccount: {name}
        };
        dispatch(transactionComplete(fromAccountId, balance, transaction));
        
        let transferSuccessMessage = `Transfer of <strong>${data.amount}</strong> to <strong>${name}</strong> successfull!</br><span style="display: block; height: 12px"></span>`;
        dispatch(replace('/'));
        dispatch(setCurrentAccount(-1));
        dispatch(Alert(null, transferSuccessMessage, () => {
            dispatch(fetchAccounts(null, true));
            dispatch(fetchUserTransactions());
        }));
    } catch (error) {
        dispatch(formLoading(false));
        console.log("Investor transfer error: ", Object.keys(error), Object.values(error));
        const responseData = error.response.data;
        if(error.message === "You're offline!")
            return;

        if(responseData.userMessage !== "Recipient account does not exist."){
            dispatch(Alert('Couldn\'t complete transfer'));
            console.error('Create Transaction Error', error);
        }
        else if(responseData.userMessage === "Recipient account does not exist."){
            dispatch(Alert('Investor, doesnt\'t exist!', 'Please make sure the investor\'s email is correct and try again.'));
            console.error('Create Transaction Error', error);
        }
    }
};

export const persistDeposit = (toAccount, amount, number, provider) => async dispatch => {
    dispatch(formLoading(true));
    try {
        await makeDeposit(toAccount, {provider, number, amount});
        
        dispatch(formLoading(false));
        dispatch(resetDepositDetails());
        dispatch(goBack());
        dispatch(setDepositer(null));
        
        const depositSuccessMessage = 'You\'ll receive a prompt to complete deposit. After completing the transaction, refresh this screen to see changes.';
        dispatch(Alert(
            null, 
            depositSuccessMessage, () => {
                dispatch(fetchAccounts(null, true));
                dispatch(fetchUserTransactions());
            })
        );
    } catch (error) {
        dispatch(formLoading(false));
        if(error.message !== "You're offline!"){
            dispatch(Alert('Failed to make deposit'));
            console.error(JSON.stringify(error));
        }
    }
};