import React from 'react';

import './navbar.css';
import FlButton from '../../../../components/FlButton';
import Spacer from '../../../../components/Spacer';

const NavBar = ( { noborder, center, className, title, notFixed, actions, children, onGoBack} ) => {
    className += ' ot-header layout center wrap';
    className += !notFixed ? ' fixed' : '';
    className += noborder ? ' no-border' : '';
    className += center ? ' center-justified' : '';
    className += typeof onGoBack === 'function' ? ' has-back' : '';

    return ( 
        <div className={ className }>
            { onGoBack && 
                <FlButton id="backButton" action onClick={onGoBack}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                </FlButton>
            }

            { children && (
                <div className="flex">
                    { children }
                </div>
            )}

            { (title || actions) && (
                <div className="top-bar layout center">
                    { title && (
                        <span className="ot-header-title">
                            { title }
                        </span>
                    )}
                    
                    { actions && (
                        <React.Fragment>
                            <Spacer />
                            { actions }
                        </React.Fragment>
                    )}
                </div>
            )}
        </div>
    );
}
 
export default NavBar;