import React from 'react';

import ListItem from '../../../../../components/ListItem';
import { OutlineButton } from '../../../../../components/FlButton';

const LoanGuarantors = ({guarantors, onAddGuarantor, onRemoveGuarantor}) => {
    function renderGuarantorList(){
        if(!guarantors)
            return null;
            
        return guarantors.map(guarantor => {
            const data = {
                ...guarantor,
                icon: <svg fill="#a2a2a2" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"/></svg>,
                label: guarantor.name
            };

            return (
                <ListItem 
                    key={guarantor._id}
                    data={data}
                    onRemove={onRemoveGuarantor}
                />
            );
        });
    }

    return (
        <div id="loanGuarantorsSection" className="RequestLoanSection">
            <h3 className="loan-request-section-title">
                Loan guarantor
                <small>
                    A guarantor is a trusted Finlink member willing to vouch for you.
                </small>
            </h3>
            
            { renderGuarantorList() }

            <OutlineButton block onClick={onAddGuarantor}>
                <svg fill="currentColor" height="24" width="24" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                &nbsp;&nbsp;
                Add Guarantor
            </OutlineButton>
        </div>
    );
}
 
export default LoanGuarantors;