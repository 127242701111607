import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push, replace } from 'connected-react-router';
import { Route, Redirect } from 'react-router';

import { getGuaranteeRequests, setCurrentGuaranteeRequest, guaranteeLoanRequest, rejectLoanRequest } from '../../../../store/actions/loan';

import NavBar from '../../components/NavBar';
import Loader from '../../../../components/Loader';
import Spacer from '../../../../components/Spacer';
import FlexBox from '../../../../components/FlexBox';

import EnterGuaranteeAmount from './EnterGuaranteeAmount';
import GuaranteeDetail from './GuaranteeDetail';
import './styles.scss';

const GuarantorPool = ({
    history, 
    fetchingGuaranteeRequests, 
    directGuaranteeRequests, 
    otherGuaranteeRequests, 
    accounts, 
    authUser,
    currentGuaranteeRequest,
    fetchGuaranteeRequests, 
    setCurrentRequest, 
    viewCurrentRequest,
    showNoBalanceError, 
    setGuaranteeAmount,
    guaranteeLoan,
    rejectLoanGuarantee
}) => {
    useEffect(() => {
        fetchGuaranteeRequests();
    }, []);

    const handleGuaranteeAmountSet = (amount) => {
        guaranteeLoan({
            type: currentGuaranteeRequest.type,
            loanId: currentGuaranteeRequest._id,
            amount: Number(amount)
        });
    }

    const handleRequestClicked = (request, type) => {
        setCurrentRequest({...request, type});
        viewCurrentRequest();
    }
    
    const handleGuaranteeClicked = () => {
        const balance = !mainAccount || mainAccount.availableBalance === undefined ? 0 : mainAccount.availableBalance;

        if(balance <= 1000)
            showNoBalanceError();
        else
            setGuaranteeAmount();
    }

    const mainAccount = accounts && accounts.length ? accounts[0] : null;
    let mainAccountBalance = "";
    if(mainAccount){
        if(mainAccount.availableBalance === undefined)
            mainAccount.availableBalance = 0;

        if(mainAccount.currency && mainAccount.currency === "tzs")
            mainAccount.currency = "TZS";
            
        mainAccountBalance = `${ mainAccount.currency } ${ mainAccount.availableBalance.toLocaleString() } /-`
    }
    else
        return <Redirect to="/main" />


    return (
        <div id="GuarantorPool" className={mainAccount ? 'balance-set' : ''}>
            <NavBar onGoBack={history.goBack}>
                <div className="top-bar layout center">
                    <div className="pos-r ot-header-title">
                        Guarantee Requests

                        {
                            mainAccount && (
                                <div className="pos-a" style={{textTransform: "none"}}>
                                    Avail. balance: &nbsp;
                                    { mainAccountBalance }
                                </div>
                            )
                        }

                    </div>
                </div>
            </NavBar>
            <div className="layout vertical mainFragment">
                <div id="GuarantorPoolContent" className="flex">
                    { fetchingGuaranteeRequests && (
                        <FlexBox vertical center>
                            <Spacer height="15" />
                            <Loader color="#aaa" />
                            <Spacer height="20" />
                            <p className="guaranteeRequestsScreenMessage">
                                Fetching guarantee requests
                            </p>
                        </FlexBox>
                    )}
                    
                    { !fetchingGuaranteeRequests && directGuaranteeRequests !== null && otherGuaranteeRequests !== null && (
                        <React.Fragment>
                            { !directGuaranteeRequests.length && !otherGuaranteeRequests.length && (
                                <p className="guaranteeRequestsScreenMessage">
                                    No guarantee requests found
                                </p>
                            )}
                            
                            { directGuaranteeRequests.length > 0 && <h5>Direct Requests</h5> }
                            
                            {
                                directGuaranteeRequests.map((request) => {
                                    const numberOfApprovedGuarantors = request.guarantors.filter(({status}) => status === "approved").length;
                                    return (
                                        <button key={request._id} className="loan-request-item layout vertical posr-r"
                                            onClick={() => handleRequestClicked(request, "direct")}>
                                            <h3 className="layout inline end">
                                                <small>TZS</small>{request.amount.toLocaleString()}
                                            </h3>
                                            <h5>
                                                Requestor: { request.applicant.displayName }
                                                { request.applicant._id === authUser._id && <small> (You)</small> }
                                            </h5>

                                            { numberOfApprovedGuarantors > 0 && 
                                                <span className="pos-a">{numberOfApprovedGuarantors} accepted</span>
                                            }
                                        </button>
                                    )
                                })
                            }
                            
                            { otherGuaranteeRequests.length > 0 && <h5>Other Requests</h5> }
                            {
                                otherGuaranteeRequests.map((request) => {
                                    const numberOfApprovedGuarantors = request.guarantors.filter(({status}) => status === "approved").length;
                                    return (
                                        <button key={request._id} className="loan-request-item layout vertical posr-r"
                                            onClick={() => handleRequestClicked(request, "other")}>
                                            <h3 className="layout inline end">
                                                <small>TZS</small>{request.amount.toLocaleString()}
                                            </h3>
                                            <h5>
                                                Requestor: { request.applicant.displayName }
                                                { request.applicant._id === authUser._id && <small> (You)</small> }
                                            </h5>

                                            { numberOfApprovedGuarantors > 0 && 
                                                <span className="pos-a">{numberOfApprovedGuarantors} accepted</span>
                                            }
                                        </button>
                                    )
                                })
                            }
                        </React.Fragment> 
                    )}
                </div>
            </div>

            <Route path='/main/guarantor-pool/request-detail' render={(routeParams) => (
                <GuaranteeDetail
                    {...routeParams}
                    onIgnoreClicked={() => history.goBack()}
                    onRejectClicked={() => rejectLoanGuarantee(currentGuaranteeRequest)}
                    onGuaranteeClicked={handleGuaranteeClicked} />
            )} />
            
            <Route path='/main/guarantor-pool/add-amount' render={(routeParams) => (
                <EnterGuaranteeAmount
                    {...routeParams}
                    mainAccountBalance={mainAccountBalance}
                    currentGuaranteeRequest={currentGuaranteeRequest}
                    onAmountSet={handleGuaranteeAmountSet} />
            )} />
        </div>
    );
}

function mapStateToProps(state){
    return {
        fetchingGuaranteeRequests: state.loan.fetchingGuaranteeRequests,
        directGuaranteeRequests: state.loan.directGuaranteeRequests,
        otherGuaranteeRequests: state.loan.otherGuaranteeRequests,
        currentGuaranteeRequest: state.loan.currentGuaranteeRequest,
        authUser: state.auth.user,
        accounts: state.account.accounts
    }
}

function mapDispatchToProps(dispatch){
    return {
        fetchGuaranteeRequests: () => {
            dispatch(getGuaranteeRequests())
        },
        setCurrentRequest: (request) => {
            dispatch(setCurrentGuaranteeRequest(request))
        },
        viewCurrentRequest: () => {
            dispatch(push('/main/guarantor-pool/request-detail'))
        },
        showNoBalanceError: () => {
            dispatch(push('/main/guarantor-pool/request-detail/no-guarantee-balance'))
        },
        setGuaranteeAmount: () => {
            dispatch(replace('/main/guarantor-pool/add-amount'))
        },
        guaranteeLoan: (payload) => {
            dispatch(guaranteeLoanRequest(payload))
        },
        rejectLoanGuarantee: (payload) => {
            dispatch(rejectLoanRequest(payload))
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(GuarantorPool);