import React, { useState } from 'react';
import classnames from 'classnames/bind';
import styles from './styles.scss';
import SelectExistingPaymentCards from '../../components/SelectExistingPaymentCards';
const cx = classnames.bind(styles);

const PickPaymentMethodType = ({paymentMethods, onAddPaymentMethod, onTypeSelected}) => {
    const [showPaymentCardPicker, setShowPaymentCardPicker] = useState(false);

    const handlePaymentMethodClicked = (paymentMethodType) => {
        const type = paymentMethodType.toLowerCase().replace(' ', '');
        const paymentMethodsOfType = paymentMethods && paymentMethods[type] ? paymentMethods[type] : [];

        if(!paymentMethods || !paymentMethodsOfType.length){
            onAddPaymentMethod(paymentMethodType);
            return;
        }

        onTypeSelected(paymentMethodType);
    }

    function handlePaymentCardSelected(card){
        setShowPaymentCardPicker(false);
        onTypeSelected('Card', card);
    }

    return ( 
        <div id="pickPaymentMethodType">
            <label>Deposit from</label>

            <button type="button" className={cx('accountChoiceItem')} 
                onClick={() => handlePaymentMethodClicked('Mobile Wallet')}>
                Mobile Wallet
            </button>

            <button type="button" className={cx('accountChoiceItem')} 
                onClick={() => setShowPaymentCardPicker(true)}>
                Payment Card
            </button>

            <button disabled type="button" className={cx('accountChoiceItem')} 
                onClick={() => handlePaymentMethodClicked('Bank Account')}>
                Bank Account (Coming Soon)
            </button>

            {
                showPaymentCardPicker && ( 
                    <SelectExistingPaymentCards 
                        onClose={() => setShowPaymentCardPicker(false)}
                        onSelect={handlePaymentCardSelected}
                    />
                )
            }
        </div>
    );
}
 
export default PickPaymentMethodType;