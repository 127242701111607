export const cardOptions = {
    'nationalId': { label: 'National ID', validator: 'min:20|max:20', mask: '########—#####—#####-##' },
    'passport': { label: 'Passport' , validator: 'min:8|max:8', mask: '' },
    'votersId': { label: 'Voters ID' , validator: 'min:13|max:13', mask: '' },
    'drivingLicense': { label: 'Driving License' , validator: 'min:10|max:10', mask: '' }
};

export const mobileProviderOptions = [
    { label: 'Mpesa' , value: 'mpesa' },
    // { label: 'Tigopesa', value: 'tigopesa' },
    // { label: 'Airtel Money' , value: 'airtel' }
];
export const mobileProviderLabelMap = {
    'mpesa': 'M-Pesa',
    'tigopesa': 'Tigo Pesa',
}
export const bankProviderOptions = [
    { label: 'CRDB' , value: 'crdb' },
    { label: 'NMB', value: 'nmb' },
    { label: 'TPB', value: 'tpb' }
];
export const bankProviderLabelMap = {
    'crdb': 'CRDB',
    'nmb': 'NMB',
    'tpb': 'TPB',
}