import React from 'react';
import { connect } from 'react-redux';
import { authCompleteRegister } from '../../store/actions/register';
import FormValidator from '../../services/FormValidator';

import _flatten from 'lodash/flatten';
import _values from 'lodash/values';
import _mapValues from 'lodash/mapValues';
import { canNotify } from '../../components/FlNotifications';

import classnames from 'classnames/bind';
import styles from './styles.scss';

import { FlRadioButtonGroup, FlMaskedInput, FlInput } from '../../components/FormControls';
import FlButton from '../../components/FlButton';
import { authLogout } from '../../store/actions/auth';
import { cardOptions } from '../../constants';

const cx = classnames.bind(styles);

class CompleteRegistration extends React.Component {
    constructor(props){
        super(props);
        this.idNumberInputRef = React.createRef();
    }

    state = {
        _id: "",
        idType: "",
        idNumber: "",
        initialValidation: false,
        validationErrors: {}
    };

    validator = new FormValidator({
        idType: 'required',
        idNumber: 'min:3'
    });

    UNSAFE_componentWillMount(){
        const { _id } = this.props.user;
        this.setState({_id});
    }

    validateForm = async () => {
        const validationErrors = await this.validator.validate(this.state);
        this.setState({validationErrors, initialValidation: true});
    
        return _flatten(Object.values(validationErrors));
    }

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({[name] : value }, () => {
            if(name === "idType"){
                const validator = cardOptions[value].validator;
                this.validator = new FormValidator({
                    idType: 'required',
                    idNumber: validator
                });
                this.setState({idNumber: ''});

                if(value && this.idNumberInputRef && this.idNumberInputRef.current)
                    this.idNumberInputRef.current.focus();
            }
            return this.state.initialValidation ? this.validateForm() : null;
        });
    }

    handleSubmit = async event => {
        event.preventDefault();
        const errors = await this.validateForm();
    
        if(errors.length)
            return this.props.Toast('Form has some errors');

        this.props.onCompleteRegister(this.state.idType, 
            this.state.idNumber);
    }

    formatCardOptions(){
        var formattedOptions = _mapValues(cardOptions, (option, key) => ({
            ...option, value: key
        }));
        return _values(formattedOptions);
    }

    render() {
        const {
            idType,
            idNumber,
            validationErrors
        } = this.state;

        const { user, onLogout } = this.props;
        const shortName = user ? user.firstName[0] + user.lastName[0] : '';
        const firstName = user ? user.firstName : 'there';

        const idTypeOptions  = this.formatCardOptions();
        const idTypeLabel = idType ? cardOptions[idType].label : "";
        const idTypeMask = idType ? cardOptions[idType].mask : "";
        const idTypeErrors = validationErrors.idType ? validationErrors.idType.map(error => error.replace("Id Type", "Card")) : [];
        const idNumberErrors = validationErrors.idNumber ? idType ? [`Invalid ${idTypeLabel} number`] : ["Invalid Card number"] : [];
        
        return (
            <div className={cx('authScene confirmScene')}>
                <FlButton className={cx('bailOutButton')} action onClick={onLogout}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                </FlButton>

                <form className={cx('form')}
                    onSubmit={this.handleSubmit}>

                    <div className={cx('logoImage circle')}>
                        { shortName }
                    </div>

                    <div className={cx('formTitle')}>
                        Confirm Identity
                    </div>

                    <p>
                        Hello {firstName}, thank you for joining Finlink. To get started, please verify your identity using
                        a valid id card.
                    </p>

                    <FlRadioButtonGroup
                        label="Choose Card"
                        name='idType'
                        value={idType}
                        options={idTypeOptions}
                        onChange={this.handleChange}
                        errors={idTypeErrors} />
                    
                    {/* <FlMaskedInput
                        type="text"
                        label={idType ? `${idTypeLabel} Number` : "Card Number"}
                        name='idNumber'
                        disabled={!idType.length}
                        value={idNumber} 
                        mask={idTypeMask}
                        inputRef={this.idNumberInputRef}
                        onChange={this.handleChange}
                        errors={idType.length ? idNumberErrors : []} 
                    />
                     */}
                    <FlInput
                        type="text"
                        label={idType ? `${idTypeLabel} Number` : "Card Number"}
                        name='idNumber'
                        disabled={!idType.length}
                        value={idNumber} 
                        mask={idTypeMask}
                        inputRef={this.idNumberInputRef}
                        onChange={this.handleChange}
                        errors={idType.length ? idNumberErrors : []} 
                    />

                    <FlButton type="submit" block primary large
                            loading={this.props.saving}>
                        SUBMIT
                    </FlButton>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state){
    return {
        saving: state.app.formLoading,
        user: state.auth.user
    }
}

function mapDispatchToProps(dispatch){
    return {
        onLogout: () => {
            dispatch( authLogout() )
        },
        onCompleteRegister: (idType, idNumber) => {
            dispatch( authCompleteRegister(idType, idNumber) )
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(canNotify(CompleteRegistration));