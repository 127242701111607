import React from 'react';
import uniqid from 'uniqid';
import './styles.scss'

class FlInput extends React.Component {
    constructor(props){
        super(props);
        this.state = { focused: false, filled: false };
        this.uniqid = uniqid();
    }

    componentDidMount(){
        if(this.props.value !== undefined){
            const val = this.props.value;
            const isNumber = typeof(this.props.value) === 'number';
            this.setState({filled: isNumber ? val !== undefined : val.length > 0});
        }
    }
    
    inputChanged = (e) => {
        const val = e.target.value;
        const isNumber = typeof(val) === 'number';

        this.setState({filled: isNumber ? val !== undefined : val.length > 0});

        this.props.onChange(e, e.nativeEvent);
    }
    
    inputFocusChanged = (focused) => {
        this.setState({focused});
    }
    
    getInputIdentifier = (id, name) => {
        let inputIdentifier = id;
        if((!id || !id.length) && name && name.length)
            inputIdentifier = name;
        else
            inputIdentifier = "input-" + this.uniqid;
        
        return inputIdentifier;
    }

    render(){
        const { label, disabled, errors, hint, textCenter, children, solo = false, id, style, value, inputRef, ...inputProps } = this.props;
        const hasErrors = errors && errors.length > 0;

        let className = 'finlink-form-control';
        className += children ? ' has-prepend' : '';
        className += this.state.focused ? ' focused' : '';
        className += this.state.filled ? ' filled' : '';
        className += disabled ? ' disabled' : '';
        className += hasErrors ? ' has-errors' : '';
        className += textCenter ? ' text-center' : '';

        let inputIdentifier = this.getInputIdentifier(id, inputProps.name);
    
        return ( 
            <div className={`finlink-form-control-wrapper ${solo && 'solo'}`} style={style}>
                <label htmlFor={inputIdentifier}>{ label }</label>
                
                <div className={className}>
                    { children && (
                        <span className="prepend">
                            { children }
                        </span>
                    )}
        
                    <input ref={inputRef} autoComplete="new-password" 
                        { ...inputProps }
                        id={inputIdentifier}
                        value={value}
                        onChange={this.inputChanged}
                        onFocus={() => this.inputFocusChanged(true) }
                        onBlur={() => this.inputFocusChanged(false) } 
                    />

                    <span className="highlight"></span>
                    <span className="bar"></span>
                    {/* <label htmlFor={inputIdentifier}>{ label }</label> */}
                </div>

                { hasErrors &&
                    <ul className="form-control-errors">
                        {
                            errors.map( (e, i) =>
                                <li key={i} className="form-control-error">
                                    { e }
                                </li>
                            )
                        }
                    </ul>
                }
                
                { !hasErrors && hint && hint.length &&
                    <small className="form-control-hint">
                        { hint }
                    </small>
                }
            </div>
        );
    }
}

const FlInputComponent = React.forwardRef((props, ref) => (
    <FlInput inputRef={ref} {...props} />
));

FlInputComponent.displayName = "FlInput";

export default FlInputComponent;