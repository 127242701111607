import React, { useState, useRef, useEffect } from 'react';
import { withRouter, Route } from 'react-router';

import FlButton from '../../../../../components/FlButton';
import { fetchGuarantor } from '../../../../../services/API';

import { FlInput } from '../../../../../components/FormControls';
import FlModal from '../../../../../components/FlModal';
import FlAlert from '../../../../../components/FlAlert';
import FormValidator from '../../../../../services/FormValidator';
import { makePhoneNumberValid } from '../../../../../utils';
import { canNotify } from '../../../../../components/FlNotifications';

const SetTransferInvestor = ({history, location, authUser, onInvestorFetched, ...props}) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [fetchInvestorState, setFetchInvestorState] = useState("idle");
    const [fetchInvestorError, setFetchInvestorError] = useState("");
    const [phoneNumberValidationErrors, setPhoneNumberValidationErrors] = useState([]);
    const [initialValidationTriggered, setInitialValidationTriggered] = useState(false);
    const phoneInputRef = useRef(null);
    const showBackButton = location.search && location.search.indexOf("withBack") !== -1;


    let addInvestorErrorMessage = fetchInvestorError || "";
    if(!addInvestorErrorMessage && phoneNumberValidationErrors && phoneNumberValidationErrors.length)
        addInvestorErrorMessage = phoneNumberValidationErrors[0];

    useEffect(() => {
        focusPhoneNumberInput();
    }, []);
    
    useEffect(() => {
        if(initialValidationTriggered)
            validatePhoneNumber(phoneNumber);
    }, [phoneNumber]);

    async function validatePhoneNumber(phone = phoneNumber){
        if(!initialValidationTriggered)
            setInitialValidationTriggered(true);

        const validator = new FormValidator({
            phoneNumber: 'phone'
        });
        const validationErrors = await validator.validate({phoneNumber: phone});

        if(Object.keys(validationErrors).length > 0){
            setPhoneNumberValidationErrors(Object.values(validationErrors)[0]);
            return false;
        }
        else{
            setPhoneNumberValidationErrors([]);

            if(authUser && authUser.phoneNumber === makePhoneNumberValid(phone)){
                setPhoneNumberValidationErrors(["You can't transfer to yourself!"]);
                return false;
            }
        }
            

        return true;
    }
    
    const focusPhoneNumberInput = () => {
        if(phoneInputRef && phoneInputRef.current)
            phoneInputRef.current.focus();
    }

    async function handleFetchInvestor(e){
        if(e) e.preventDefault();

        const investorPhoneNumberIsValid = await validatePhoneNumber();
        if(!investorPhoneNumberIsValid)
            return;

        try {
            setFetchInvestorState("fetching");
            const validPhone = makePhoneNumberValid(phoneNumber);
            let investor = await fetchGuarantor(validPhone.replace("+255", "0"));
            investor.phoneNumber = validPhone;
            setFetchInvestorState("idle");
            onInvestorFetched(investor);
        } catch (error) {
            if(error.message !== "You're offline!"){
                if(error.response && error.response.data && error.response.data.developerMessage === "Gurantor not found!")
                    props.Alert("The number you entered doesn't belong to an existing Finlink user.");
                else
                    props.Alert("Failed to fetch investor.");
            }

            setFetchInvestorState("idle");
        }
    }

    return (
        <div id="SetTransferInvestor">
            <FlModal pageTitle="Set Investor"
                onBack={!showBackButton ? null : () => history.goBack()}>
                <form className="RequestLoanSection" 
                    onSubmit={handleFetchInvestor} >
                    
                    <FlInput
                        required
                        type="tel"
                        label="Investor Phone"
                        placeholder="E.g. 719XXXXXX"
                        value={phoneNumber}
                        inputRef={phoneInputRef}
                        onChange={({target}) => setPhoneNumber(target.value)}
                        errors={phoneNumberValidationErrors}>

                        <span>+255</span>    
                    </FlInput>

                    <FlButton primary
                        type="submit"
                        block 
                        loading={fetchInvestorState === "fetching"}
                    >
                        Submit
                    </FlButton>
                </form>
            </FlModal>
        </div>
    );
}
 
export default withRouter(canNotify(SetTransferInvestor));