/*eslint no-unused-vars:'off' */

import React from 'react';
import { connect } from 'react-redux';
import _flatten from 'lodash/flatten';

import { canNotify } from '../../../../components/FlNotifications';
import { replace, goBack } from 'connected-react-router';

import FormValidator from '../../../../services/FormValidator';

import { FlInput } from '../../../../components/FormControls';
import FlButton from '../../../../components/FlButton';
import NavBar from '../../components/NavBar';
import { makePhoneNumberValid } from '../../../../utils';

import './styles.scss';
import { updateProfileDetails } from '../../../../store/actions/auth';

class EditProfile extends React.Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        validationErrors: {},
        initialValidation: false,
    };

    validator = new FormValidator({
        firstName: 'min:3',
        lastName: 'min:3',
        email: 'email',
        phoneNumber: 'min:9'
    });

    UNSAFE_componentWillMount(){
        if(!this.props.user)
            return this.props.redirectToHome();

        const {
            firstName,
            lastName,
            email,
            phoneNumber
        } = this.props.user;

        this.setState({
            firstName,
            lastName,
            email,
            phoneNumber
        });
    }

    validateForm = async () => {
        const validationErrors = await this.validator.validate(this.state);
        this.setState({validationErrors, initialValidation: true});
        const firstInvalidInput = Object.keys(validationErrors)[0];
        
        //get the first input that is not a radio or checkbox
        const exemptions = ':not([type="radio"]):not([type="checkbox"])';
        const inputNode = document.querySelector(`[name=${firstInvalidInput}]${exemptions}`);
    
        return [_flatten(Object.values(validationErrors)), inputNode];
    }

    handleChange = event => {
        const { name, type, checked, value } = event.target;
        this.setState({ [name]: type === 'checkbox' ? checked : value }, 
          () => this.state.initialValidation ? this.validateForm() : null
        );
    }
    
    handleSubmit = async (e) => {
        e.preventDefault();
        const [errors, inputNode] = await this.validateForm();
    
        if(errors.length){
            if(inputNode)
                inputNode.focus();

            return this.props.Toast('Form has some errors');
        }

        let userData = {...this.state};
        delete userData.validationErrors;
        delete userData.initialValidation;

        userData.phoneNumber = makePhoneNumberValid(userData.phoneNumber);
        this.props.updateUserDetails(userData);
    }

    render() {
        let {
            firstName,
            lastName,
            email,
            phoneNumber,
            validationErrors
        } = this.state;
        const { user } = this.props;
        const shortName = user ? user.firstName[0] + user.lastName[0] : 'WK';
        phoneNumber = phoneNumber ? makePhoneNumberValid(phoneNumber).substr(4) : '';

        return (
            <React.Fragment>
                <NavBar className="account-detail-nav" onGoBack={this.props.goBack}>
                    
                </NavBar>
                <div className="mainFragment editProfile" style={{paddingTop: '0'}}>

                <form className="form"
                    onSubmit={this.handleSubmit}>

                    <div className="logoImage circle">
                        { shortName }
                    </div>

                    <div className="formTitle">
                        Edit Profile
                    </div>

                    <FlInput
                        type="text"
                        label="First Name"
                        name='firstName'
                        value={firstName} 
                        onChange={this.handleChange}
                        errors={validationErrors.firstName} />
                    
                    <FlInput
                        type="text"
                        label="Last Name"
                        name='lastName'
                        value={lastName} 
                        onChange={this.handleChange}
                        errors={validationErrors.lastName} />
                    
                    <FlInput
                        type="tel"
                        label="Phone Number"
                        name='phoneNumber' 
                        value={phoneNumber} 
                        onChange={this.handleChange}
                        errors={validationErrors.phoneNumber}>

                        <span>+255</span>
                    </FlInput>
                    
                    <FlInput
                        type="email"
                        label="Email"
                        name='email'
                        value={email} 
                        onChange={this.handleChange}
                        errors={validationErrors.email} />

                    <FlButton type="submit" block primary 
                        loading={this.props.saving}>
                        Save Details
                    </FlButton>
                </form>
            </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state){
    return {
        user: state.auth.user,
        saving: state.app.formLoading
    }
}

function mapDispatchToProps(dispatch){
    return {
        goBack: () => {
            dispatch(goBack())
        },
        redirectToHome: () => {
            dispatch(replace('/'))
        },
        updateUserDetails: (user) => {
            dispatch(updateProfileDetails(user))
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(canNotify(EditProfile));