import React, { useEffect } from 'react';
import formatDate from 'date-fns/format';
import { connect } from 'react-redux';

import { getCurrentLoanRequest } from '../../../../store/selectors/loan';
import { setCurrentLoanRequest } from '../../../../store/actions/loan';

import Loader from '../../../../components/Loader';
import NavBar from '../../components/NavBar';

import './styles.scss';
import ListItem from '../../../../components/ListItem';

const loanSteps = [
    {
        label: "received",
        description: "Loan has been successfullly submitted to Finlink."
    },
    {
        label: "broadcasted",
        description: "Loan has been broadcasted to Finlink members."
    },
    {
        label: "guaranteed",
        description: "Loan amount you requested for has been approved."
    },
    {
        label: "Disbursement Initiated",
        shortName: "disbursementInitiated",
        description: "Finlink loan officer has verified all the necessary details."
    },
    {
        label: "rejected",
        description: "Loan was rejected due to some unmet requirement(s)."
    },
    {
        label: "approved",
        description: "The requested loan amount has been deposited to your account."
    },
    {
        label: "overdue",
        description: "Loan has gone past it's repayment date."
    },
    {
        label: "cleared",
        description: "Loan has been fully paid back to Finlink."
    }
];

function getLoanSteps(status){
    let steps = ["received"];
    let step = 0;
    switch (status) {
        case "rejected":
            steps = [...steps, "rejected"];
            step = 1;
            break;
        case "broadcasted":
            steps = [...steps, "broadcasted", "guaranteed", "disbursementInitiated", "approved"];
            step = 1;
            break;
        case "guaranteed":
            steps = [...steps, "guaranteed", "disbursementInitiated", "approved"];
            step = 1;
            break;
        case "disbursementInitiated":
            steps = [...steps, "disbursementInitiated", "approved"];
            step = 1;
            break;
        case "overdue":
            steps = [...steps, "disbursementInitiated", "approved", "overdue"];
            step = 3;
            break;
        case "cleared":
            steps = [...steps, "disbursementInitiated", "approved", "cleared"];
            step = 3;
            break;
        default:
            break;
    }

    steps = loanSteps.filter(({shortName, label}) => steps.includes(label) || steps.includes(shortName));
    return [steps, step];
}

const LoanDetail = ({history, match, currentLoanRequest, notificationLoanRequest, setCurrentLoanRequestId}) => {
    currentLoanRequest = notificationLoanRequest ? notificationLoanRequest : currentLoanRequest;

    const status = currentLoanRequest ? currentLoanRequest.status : "received";
    const [steps, loanStep] = getLoanSteps(status);
    useEffect(() => {
        if(match && match.params){
            const loanId = match.params.loanId;
            setCurrentLoanRequestId(loanId)
        }
    }, [match]);
    
    function loanTitle({reason, bankDetails}){
        if(reason === "personal")
            return "Personal Loan";
        else if(reason === "rent")
            return `Rent payment to ${bankDetails.bankAccountName}`;
        else if(reason === "fees")
            return `School fees for ${bankDetails.studentName} at ${bankDetails.bankAccountName}`;
    }

    return (
        <div id="LoanDetail" className="layout vertical mainFragment">
            <div>
                <NavBar 
                    title={!currentLoanRequest ? "Loan Request Details" : ""}
                    noborder
                    onGoBack={history.goBack}
                />
            </div>

            { !currentLoanRequest && (
                <div style={{height: 150}} className="layout center-center">
                    <Loader size={80} color="#888" />
                </div>
            )}

            {
                currentLoanRequest && (
                    <div id="LoanDetailContent" className="flex">
                        <div key={currentLoanRequest._id} className={`loan-request-details posr-r ${currentLoanRequest.status}`}>
                            <h3 className="layout inline end">
                                { loanTitle(currentLoanRequest) }
                            </h3>

                            <h5>
                                { currentLoanRequest.amount &&  
                                    <React.Fragment>
                                        <small>TZS</small>{currentLoanRequest.amount.toLocaleString()}
                                    </React.Fragment>
                                }
                            </h5>

                            <div className="detail-text">
                                <label>Sent On:</label>
                                { formatDate(new Date(currentLoanRequest.createdAt), 'MMMM Do, YYYY') }
                            </div>
                        </div>

                        <h5 id="LoanProgressTitle">Loan Progress</h5>
                        <div id="LoanTimeLine">
                            {
                                steps.map(({label, description}, index) => {
                                    let className = "loan-timeline-step";
                                    className += index <= loanStep ? ' complete' : '';
                                    className += label == 'rejected' || label == "overdue" ? ' error' : '';
                                    return (
                                        <div key={index} className={className}>
                                            <ListItem
                                                data={{label, description}} 
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
}
 
function mapStateToProps(state){
    return {
        currentLoanRequest: getCurrentLoanRequest(state)
    }
}

function mapDispatchToProps(dispatch){
    return {
        setCurrentLoanRequestId: (loanId) => dispatch(setCurrentLoanRequest(loanId))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(LoanDetail);