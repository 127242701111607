/* eslint-disable no-console */
import { replace } from 'react-router-redux';
import { registerUser, sendVerifyEmail, 
    verifyUserAccount as APIVerifyUserAccount, 
    verifyUserByOtp as APIVerifyUserByOtp,
    updateProfile, 
    resendOtp
} from '../../services/API';
import { USER_KEY, setPrefs, getPrefs } from '../../services/prefs';
import { formLoading } from './app'
import { Toast, Alert } from './notifications'
import { authSuccess } from './auth';
import { goBack } from 'connected-react-router';

export const AUTH_REGISTER = 'AUTH_REGISTER';

export const authRegister = (user) => async dispatch => {
    dispatch(formLoading(true));

    try {
        let newUser = await registerUser({ ...user, idType : 'NAN', idNumber: 'NAN' });
        newUser._temp = true;
        await setPrefs(USER_KEY, newUser);
        dispatch(authSuccess(newUser, false));
        dispatch(formLoading(false));
        dispatch(replace('/confirm-identity'));
    } catch (error) {
        dispatch(formLoading(false));
        if(error.message !== "You're offline!"){
            console.log(JSON.stringify(error));

            if(error && error.code === "ECONNABORTED")
                dispatch(Alert('Request took too long to respond, please check your internet connection and try again'));
            if(error && error.response && error.response.data && error.response.data.userMessage)
                dispatch(Alert(error.response.data.userMessage));
            else
                dispatch(Alert('Registration failed, please try again.'));
        }
    }
};

export const authCompleteRegister = (idType, idNumber) => async dispatch => {
    dispatch(formLoading(true));
    let cardDetailsSaved = false;

    try {
        await updateProfile({idType, idNumber});
        let authUser = await getPrefs(USER_KEY);
        delete authUser._temp;
        authUser = { ...authUser, idType, idNumber };
        await setPrefs(USER_KEY, authUser);
        cardDetailsSaved = true;

        await sendVerifyEmail();
        
        dispatch(formLoading(false));
        dispatch(replace('/not-verified'));
    } catch (error) {
        dispatch(formLoading(false));
        
        if(error.message !== "You're offline!"){
            if(cardDetailsSaved){
                dispatch(replace('/not-verified'));
            }
            else{
                dispatch(Toast('Error confirming id'));
            }
        }

        console.log(JSON.stringify(error));
    }
};

export const sendVerificationEmail = () => async dispatch => {
    dispatch(formLoading(true));

    try {
        await sendVerifyEmail();
        dispatch(formLoading(false));
    } catch (error) {
        dispatch(formLoading(false));
        if(error.message !== "You're offline!"){
            dispatch(Toast('Error sending verification email'))
            console.log(JSON.stringify(error));
        }
    }
};

export const verifyUserAccount = (verificationToken) => async dispatch => {
    dispatch(formLoading(true));

    try {
        const verifyResult = await APIVerifyUserAccount(verificationToken);
        let authUser = await getPrefs(USER_KEY);
        authUser.verifiedAt = verifyResult.verifiedAt;
        await setPrefs(USER_KEY, authUser);

        dispatch(formLoading(false));
        dispatch(authSuccess(authUser, true, true));
        dispatch(replace('/'));
    } catch (error) {
        dispatch(formLoading(false));
        if(error.message !== "You're offline!"){
            dispatch(Toast('Error verifying account'));
        }
    }
};

export const verifyUserByOtp = (otp, phoneNumber) => async dispatch => {
    dispatch(formLoading(true));

    try {
        const verifyResult = await APIVerifyUserByOtp(otp);
        let authUser = await getPrefs(USER_KEY);
        authUser.verifiedAt = verifyResult.verifiedAt;
        await setPrefs(USER_KEY, authUser);

        dispatch(formLoading(false));
        dispatch(authSuccess(authUser, true, true));
        dispatch(replace('/'));
    } catch (error) {
        if(error.message !== "You're offline!"){
            if(error && error.response && error.response.data && error.response.data.userMessage){
                if(error.response.data.userMessage.indexOf("Unknown OTP") !== -1){
                    dispatch(Alert('Invalid verification code', 'Enter the code sent to your phone and if that deosn\'t work, click \'Resend Code\' to get a new one.'));
                    return dispatch(formLoading(false));
                }
                else if(error.response.data.userMessage === "OTP expired, generate new OTP."){
                    const notificationPayload = {
                        title:"Verification token expired",
                        message:"The verification token you used has expired, resend token?",
                        cancelText:"Cancel",
                        okText:"Resend Token",
                        onCancel: () => dispatch(formLoading(false)),
                        onOkay: () => dispatch(sendVerificationText(phoneNumber))
                    };
        
                    return dispatch(Alert(notificationPayload));
                }
            }

            dispatch(formLoading(false));
            dispatch(Alert('Error verifying account, please try again!'));
        }
        else{
            dispatch(formLoading(false));
        }
    }
};

export const sendVerificationText = (phoneNumber) => async dispatch => {
    dispatch(formLoading(true));

    try {
        await resendOtp(phoneNumber);
        dispatch(formLoading(false));
    } catch (error) {
        dispatch(formLoading(false));
        if(error.message !== "You're offline!"){
            dispatch(Toast('Error sending verification email'))
            console.log(JSON.stringify(error));
        }
    }
};
