import React from 'react';
import classnames from 'classnames/bind';

import FlToast from './FlToast';
import FlAlert from '../../FlAlert';

import styles from './styles.scss';
import { TYPE_ALERT, TYPE_TOAST } from '../../../store/actions/notifications';

const cx = classnames.bind(styles);

const Notifications = ({ notifications, onCloseAlert }) => {
    function handleAlertOkayClicked({id, callback, onOkay}){
        onCloseAlert(id);
        if(callback)
            callback();

        if(onOkay)
            onOkay();
    }
    
    function handleAlertCloseClicked({id, onCancel}){
        onCloseAlert(id);
        if(onCancel)
            onCancel();
    }

    return ( 
        <div className={cx('finlink-notifications')}>
            { 
                notifications.map(notification => {
                    if(notification.type === TYPE_ALERT)
                        return <FlAlert 
                            key={notification.id} 
                            alert={notification}
                            title={notification.title}
                            message={notification.message}
                            cancelText={notification.cancelText}
                            okText={notification.okText}
                            onCancel={typeof notification.onCancel === 'function' ? () => handleAlertCloseClicked(notification) : null }
                            onOkay={() => handleAlertOkayClicked(notification) } />

                    if(notification.type === TYPE_TOAST)
                        return <FlToast 
                            key={notification.id} 
                            message={notification.message} />
                            
                    else return null;
                })
            }

        </div>
    );
}

export default Notifications;