import React, { useState, useEffect, useRef } from 'react';
import {
    SliderInput,
    SliderTrack,
    SliderHandle,
} from "@reach/slider";
import "@reach/slider/styles.css";

import "./styles.scss";

const RangeInput = ({min = 1, max = 12, value, onChange}) => {
    const timeOutRef = useRef(null);
    const [shouldAnimate, setShouldAnimate] = useState(false);

    useEffect(() => {
        return () => {
            if(timeOutRef && timeOutRef.current){
                clearTimeout(timeOutRef.current);
            }
        }
    }, []);
    
    const setValue = (value) => {
        onChange(value);
        setShouldAnimate(false);
        setTimeout(() => {
            setShouldAnimate(true);
        }, 2);
    }

    return (
        <div className={`RangeInput ${shouldAnimate ? 'animate-handle' : ''}`}>
            <SliderInput min={min} max={max} value={value} onChange={setValue}>
                <SliderTrack>
                    {/* <SliderTrackHighlight /> */}
                    <SliderHandle />
                </SliderTrack>
            </SliderInput>
        </div>
    );
}

export default RangeInput;
