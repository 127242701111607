import React from 'react';
import './styles.scss'

const FlCheckbox = (props) => {
    const { required, name, checked, children, onChange} = props;
    let className = 'finlink-checkbox';
    className += checked ? ' checked' : '';
    const randomId = `flCheckbox${Math.random().toString(36).substr(2, 5)}`;

    return ( 
        <div className={className}>
            <input type='checkbox'
                id={randomId}
                name={name}
                checked={checked} 
                required={required}
                onChange={onChange}
            />

            <label htmlFor={randomId} name={name}>
                { !checked && <svg fill="#aaa" width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg> }
                { checked && <svg fill="#aaa" width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg> }
            </label>

            <label htmlFor={randomId}>{ children }</label>
        </div>
    );
}
 
export default FlCheckbox;