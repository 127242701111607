import React from 'react';
import { Redirect } from 'react-router';

import LoanDetail from "../../LoanDetail";

function NotificationDetail(props){
    const { location, accounts } = props;

    const mainAccount = accounts && accounts.length ? accounts[0] : null;
    let mainAccountBalance = "";

    if(mainAccount){
        if(mainAccount.availableBalance === undefined)
            mainAccount.availableBalance = 0;

        if(mainAccount.currency && mainAccount.currency === "tzs")
            mainAccount.currency = "TZS";
            
        mainAccountBalance = `${ mainAccount.currency } ${ mainAccount.availableBalance.toLocaleString() } /-`
    }

    if(!location || !location.state)
        return null;

    const notification = location.state;

    if(notification.topic.indexOf('loan') !== -1){
        return (
            <LoanDetail {...props} notificationLoanRequest={notification.resource} />
        );
    }
    else if(notification.topic === "guarantee-request")
        return <Redirect to="/main/guarantor-pool/" />;

    return null;
}

export default NotificationDetail;