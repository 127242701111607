import React from 'react';
import './bottom-nav.css';

const BottomNav = ( props ) => {
    const { visible, expanded, page, onNavigate } = props;
    let className = 'bottom-nav-wrapper ';
    className += visible ? 'visible ': '';
    className += expanded ? 'expanded': '';

    return ( 
        <div className={className}>
            <div className={'ot-mobile-nav layout center around-justified'}>
                <button onClick={ () => onNavigate('TransferMoney') }>
                    <span className="icon">
                        <svg stroke="#4cf3a8" viewBox="0 0 24 24"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
                    </span>
                    Transfer
                </button>

                {/* {
                    process.env.REACT_APP_ENV === 'production' && ( */}
                        <button onClick={ () => onNavigate('DepositMoney') }>
                            <span className="icon">
                                <svg stroke="#12efef" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeLinejoin="round"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                            </span>
                            Deposit
                        </button>
                    {/* )
                } */}

                <button onClick={ () => onNavigate('activity') }>
                    <span className="icon">
                        <svg stroke="#ffb73d" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline></svg>
                    </span>
                    Activity
                </button>
            </div>
        </div>
    );
}
 
export default BottomNav;