import React from 'react';
import { withRouter } from 'react-router-dom';
import ForgotPasswordEmail from './forgot-password-email';
import ForgotPasswordPhone from './forgot-password-phone';
import EnterOtp from './enter-otp';

const ForgotPassword = (props) => {
    const getParams = props.location.search;

    if(getParams.indexOf("?enterOtpFor") !== -1)
        return <EnterOtp />;
    else if(getParams.indexOf("?withEmail") !== -1)
        return <ForgotPasswordEmail />;

    return <ForgotPasswordPhone />;
}
 
export default withRouter(ForgotPassword);