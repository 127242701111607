export const TYPE_TOAST = 'toast';
export const TYPE_ALERT = 'alert';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const addNotification = (notification) => ({
    type: ADD_NOTIFICATION,
    notification
});

export const removeNotification = (notificationId) => ({
    type: REMOVE_NOTIFICATION,
    notificationId
});

const getId = () => 'fl-notif-' + Math.random().toString(36).substr(2, 5);

export const Toast = (message) => dispatch => {
    const id = getId();
    const notification = {
        id,
        type: TYPE_TOAST,
        message
    };

    dispatch(addNotification(notification));

    setTimeout(() => {
        dispatch(removeNotification(id));
    }, 1700);
}

export const Alert = (title, message, callback = null) => dispatch => {
    let notification = {
        id : getId(),
        type: TYPE_ALERT,
    };

    if(title !== null && typeof title === "object")
        notification = {...notification, ...title};
    else
        notification = {
            ...notification, 
            ...{
                title: message && message.length ? title : null, 
                message: message && message.length ? message : title,
                callback
            }
        };

    dispatch(addNotification(notification));
}