import * as API from './server_calls';
import { getToken } from "../prefs";

export const fetchLoanInterestByPurpose = async (purpose) => {
    const token = await getToken();
    return API.get(`/config/getConfigValue?name=${purpose}LoanInterest`, token);
};

export const fetchLoanMinimumAmount = async () => {
    const token = await getToken();
    return API.get('/config/getConfigValue?name=loanMinimumAmount', token);
};

export const fetchSupportedBanks = async (page = 1) => {
    const token = await getToken();
    return API.get('/banks?currentPage='+page, token);
};

export const fetchGuarantor = async (phoneNumber) => {
    const token = await getToken();
    return API.get(`/loan/checkGurantor?phoneNumber=${phoneNumber}`, token);
};

export const requestLoan = async (data) => {
    const token = await getToken();
    return API.post(`/loan`, data, token);
};

export const fetchLoanRequests = async () => {
    const token = await getToken();
    return API.get(`/loan`, token);
};

export const fetchGuaranteeRequests = async () => {
    const token = await getToken();
    return API.get(`/loan/guaranteeRequests`, token);
};

export const submitGuaranteeForLoanRequest = async (loanId, payload) => {
    const token = await getToken();
    return API.post(`/loan/${loanId}/guarantee`, payload, token);
};

export const rejectLoanGuaranteeRequest = async (loanId) => {
    const token = await getToken();
    return API.patch(`/loan/${loanId}/rejectGuaranteeRequest`, null, token);
};