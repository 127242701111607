import React from 'react';
import { connect } from 'react-redux';
import { sendResetPasswordEmail } from '../../store/actions/auth';

import classnames from 'classnames/bind';
import styles from './styles.scss';

import FlButton from '../../components/FlButton';
import { replace } from 'connected-react-router';

const cx = classnames.bind(styles);

class ResetPasswordSent extends React.Component {
    handleSubmit = event => {
        event.preventDefault();
        const emailParam = this.props.location.search;
        if(emailParam && emailParam.length){
            const email = emailParam.replace('?email=', '');
            this.props.onSendResetEmail(email);
        }else{
            this.props.onRedirectToForgotPassword();
        }
    }

    render() {
        return (
            <div className={cx('authScene confirmScene layout center')}>
                <form className={cx('form')}
                    onSubmit={this.handleSubmit}>

                    <div className={cx('logoImage circle success')}>
                        <svg viewBox="0 0 24 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
                    </div>

                    <div className={cx('formTitle')}>
                        Reset Link Sent
                    </div>

                    <p>
                        Click link that was sent to your e-mail to reset your password.
                    </p>

                    <hr/><br/>

                    <p style={{marginBottom: '1.8em', marginTop: '0.5em'}}>
                        Didn't receive the email?
                    </p>

                    <div className="layout center-justified">
                        <FlButton type="submit" inverse
                                loading={this.props.saving}>
                            Resend E-mail
                        </FlButton>
                    </div>

                    <div className="siblingPageLink layout center" style={{marginTop: '2.5em'}}>
                        Remembered password? &nbsp;
                        <FlButton type="button" flat accent onClick={this.props.onRedirectToLogin}>Login</FlButton>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state){
    return {
        saving: state.app.formLoading,
    }
}

function mapDispatchToProps(dispatch){
    return {
        onRedirectToLogin: (email) => {
            dispatch(replace('/login'))
        },
        onRedirectToForgotPassword: (email) => {
            dispatch(replace('/forgot-password'))
        },
        onSendResetEmail: (email) => {
            dispatch( sendResetPasswordEmail(email) )
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordSent);