import React from 'react';
import { connect } from "react-redux";
import { replace, push } from 'react-router-redux';
import _filter from 'lodash/filter';
import _find from 'lodash/find';

import classnames from 'classnames/bind';

import { resetDepositDetails, setDepositer } from '../../../../store/actions/deposit-modal';
import { canNotify } from '../../../../components/FlNotifications';
import NavBar from '../../components/NavBar';
import AccountSwitcher from './AccountSwitcher';
import Transactions from '../../components/Transactions';
import TransactionsFilters from '../../components/TransactionsFilters';

import { setCurrentAccount, fetchTransactions, reloadAccountDetails } from '../../../../store/actions/account';
import { setRecepientAccounts } from '../../../../store/actions/transfer-modal';

import FlButton from '../../../../components/FlButton';
import Spacer from '../../../../components/Spacer';

import HashRoute from '../../../../Router/HashRoute';
import SearchRoute from '../../../../Router/SearchRoute';

import AddAccount from '../AddAccount';
import DepositModal from '../DepositModal';
import TransferModal from '../TransferModal';

import styles from './styles.scss';
const cx = classnames.bind(styles);

class AccountDetail extends React.Component {
    state = {
        fetchingTriggered: false, 
        animateAccountCards: true,
        transactionMonthsFilter: []
    };

    componentWillMount(){
        const { accounts, match } = this.props;

        if(!accounts || !accounts.length)
            return this.props.onRedirectToHome();

        const { params } = match;
        this.setCurrentAccount(params.id);
    }
    
    componentWillUnmount(){
        this.setCurrentAccount(-1);
    }

    setCurrentAccountWithIndex = (index, animateAccountCards = true) => {
        this.setState({animateAccountCards, transactionMonthsFilter: []}, () => {
            this.setCurrentAccount(this.props.accounts[index]._id);
        });
    }
    
    setCurrentAccount = (id) => {
        const { accounts, onSetCurrentAccount } = this.props;
        onSetCurrentAccount(id);

        if(accounts && accounts.length){
            const currentAccount = _find(accounts, ['_id', id]);
            if(currentAccount){
                this.props.onFetchTransactions(id);
            }
        }
    }

    handleRequestLoan = () => {
        const currentAccount = this.props.currentAccount;
        const loanBalance = currentAccount.loanBalance ? parseInt(currentAccount.loanBalance) : 0;
        if(!loanBalance || loanBalance > 0)
            this.props.requestLoan(currentAccount._id);
        else
            return this.props.Alert('Account has uncleared loan', 'You can\'t apply for a loan from an account uncleared loan.');
    }
    
    handleTransferClicked = () => {
        if(this.props.currentAccount.balance === 0)
            return this.props.Alert('Account has no balance', 'You can\'t make a transfer from an account without balance.');

        this.setRecipientAccounts(this.props.currentAccount._id);
        this.props.onStartTransfer(this.props.currentAccount.type.toLowerCase() === "main");
    }

    setRecipientAccounts = ( accountId ) => {
        const { accounts, onSetRecepientAccounts } = this.props;
        let recepientAccounts = _filter(accounts, account => account._id !== accountId)
        recepientAccounts.map(account => {
            account.transferAmount = 0;
            return account;
        });
    
        onSetRecepientAccounts(recepientAccounts);
    }
    
    handleTransactionFilterSet = (filters) => {
        this.props.history.goBack();
        this.setState({transactionMonthsFilter: filters.months});
    }
    
    handleReloadAccountData = () => {
        const { currentAccount, onReloadAccountDetails } = this.props;
        onReloadAccountDetails(currentAccount._id);
    }

    render() {
        let { accounts, currentAccount, currentAccountIndex, onEditAccount } = this.props;
        const isMainAccount = currentAccount && currentAccount.type.toLowerCase() === 'main';
        const noTransactions = currentAccount && (currentAccount.fetchingTransactions || currentAccount.transactions.length < 1);
        let currentAccountClass = "";
        if(currentAccount && currentAccount.type && currentAccount.type.toLowerCase() === 'main')
            currentAccountClass = process.env.REACT_APP_ENV === 'production' ? "main" : "";

        return ( 
            <React.Fragment>
                <NavBar className="account-detail-nav" noborder onGoBack={this.props.history.goBack}>
                    <div className="top-bar layout center">
                        <span className="ot-header-title">
                            {/* { title } */}
                        </span>

                        <FlButton action 
                            disabled={!currentAccount || currentAccount.fetchingTransactions || currentAccount.reloadingDetails}
                            onClick={this.handleReloadAccountData}>
                            <svg viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M17.65 6.35c-1.63-1.63-3.94-2.57-6.48-2.31-3.67.37-6.69 3.35-7.1 7.02C3.52 15.91 7.27 20 12 20c3.19 0 5.93-1.87 7.21-4.56.32-.67-.16-1.44-.9-1.44-.37 0-.72.2-.88.53-1.13 2.43-3.84 3.97-6.8 3.31-2.22-.49-4.01-2.3-4.48-4.52C5.31 9.44 8.26 6 12 6c1.66 0 3.14.69 4.22 1.78l-1.51 1.51c-.63.63-.19 1.71.7 1.71H19c.55 0 1-.45 1-1V6.41c0-.89-1.08-1.34-1.71-.71l-.64.65z"/></svg>
                        </FlButton>
                        
                        <Spacer width="33" />
                        
                        { !isMainAccount && 
                            <FlButton flat onClick={onEditAccount}>
                                EDIT DETAILS
                            </FlButton>
                        }
                    </div>
                </NavBar>

                <div className={cx('mainFragment accountDetailFragment')}>
                    <div className="stickyHeader">
                        <AccountSwitcher 
                            onChangeAccount={ this.setCurrentAccountWithIndex } 
                            accounts={accounts}
                            animateSwitch={this.state.animateAccountCards}
                            currentAccountIndex={currentAccountIndex} />

                        <div className={`accountActions layout center-center ${currentAccountClass}`}>
                            { currentAccount && currentAccount.type && currentAccount.type.toLowerCase() === 'main' && (
                                <React.Fragment>
                                    {
                                        process.env.REACT_APP_ENV === 'production' && (
                                            <button onClick={this.props.onStartDepoit} className="action-item deposit">
                                                Deposit
                                            </button>
                                        )
                                    }
                                    
                                    <button onClick={this.props.onStartWithdraw} className="action-item withdraw">
                                        Withdraw
                                    </button>
                                </React.Fragment>
                            )}
                            
                            <button onClick={this.handleTransferClicked} className="action-item">
                                Transfer
                            </button>

                            { currentAccount && currentAccount.type && currentAccount.type.toLowerCase() !== 'main' && (
                                <button onClick={() => this.handleRequestLoan()} className="action-item deposit">
                                    Request Loan
                                </button>
                            )}
                        </div>
                    </div>
                    { currentAccount &&
                        (
                            <div className="accountDetailSection">
                                <span className="sectionTitle">
                                    Transactions

                                    {/* <FlButton 
                                        className={ noTransactions ? 'op-0' : '' }
                                        disabled={noTransactions}
                                        flat onClick={this.props.onFilterTransactions}>
                                        FILTER
                                    </FlButton> */}
                                </span>
                                
                                <Transactions accountId={currentAccount._id}
                                    filters={{ months: this.state.transactionMonthsFilter }}
                                    fetchingTransactions={currentAccount.fetchingTransactions}
                                    reloading={currentAccount.reloadingDetails}
                                    transactionsFetched={currentAccount.transactionsFetched}
                                    transactions={currentAccount.transactions} />
                            </div>
                        )
                    }

                    <HashRoute exact path="#finlinkModal/EditAccount" component={AddAccount} />
                    <HashRoute path="#finlinkModal/TransferMoney" component={TransferModal} />
                    <HashRoute path="#finlinkModal/DepositMoney" component={DepositModal}
                        onBeforeClose={this.props.onUnsetDepositor} />
                    <SearchRoute path="showFilter" 
                        component={ () =>
                            <TransactionsFilters
                                fromAccountDetailScreen
                                filters={{ months: this.state.transactionMonthsFilter }}
                                onCancel={ this.props.history.goBack }
                                onComplete={ this.handleTransactionFilterSet } 
                            />
                        } 
                    />
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state){
    return {
        accounts: state.account.accounts,
        currentAccount: state.account.currentAccount,
        currentAccountIndex: state.account.currentAccountIndex,
    }
}

function mapDispatchToProps(dispatch){
    return {
        onSetCurrentAccount: (id) => {
            dispatch(setCurrentAccount(id))
        },
        onSetRecepientAccounts: (recepientAccounts) => {
            dispatch(setRecepientAccounts(recepientAccounts))
        },
        onReloadAccountDetails: (id) => {
            dispatch(reloadAccountDetails(id))
        },
        onFetchTransactions: (id) => {
            dispatch(fetchTransactions(id))
        },
        onUnsetDepositor: () => {
            dispatch(setDepositer(null))
        },
        onRedirectToHome: () => {
            dispatch(replace('/'));
        },
        onFilterTransactions: () => {
            dispatch(push({
                search: '?showFilter'
            }))
        },
        onStartTransfer: (onMainScreen) => {
            dispatch(resetDepositDetails())
            dispatch(push({
                hash: '#finlinkModal/TransferMoney',
                search: !onMainScreen ? '?toAccountsOnly' : ''
            }))
        },
        onStartDepoit: () => {
            dispatch(push({
                hash: '#finlinkModal/DepositMoney'
            }))
        },
        onStartWithdraw: () => {
            dispatch(push({
                hash: '#finlinkModal/WithdrawMoney'
            }))
        },
        requestLoan: (accountId) => {
            dispatch(push('/main/requestLoan?accountId=' + accountId))
        },
        onEditAccount: () => {
            dispatch(push({
                hash: '#finlinkModal/EditAccount'
            }))
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(canNotify(AccountDetail));