import React from 'react';
import { connect } from "react-redux";
import _flatten from 'lodash/flatten';

import FormValidator from '../../../../services/FormValidator';
import { FlInput, FlRadioButtonGroup } from '../../../../components/FormControls';
import FlModal from '../../../../components/FlModal';
import FlButton from '../../../../components/FlButton';
import Spacer from '../../../../components/Spacer';
import { persistPaymentMethod } from '../../../../store/actions/deposit-modal';
import { mobileProviderOptions, bankProviderOptions, mobileProviderLabelMap, bankProviderLabelMap } from '../../../../constants';
import { makePhoneNumberValid } from '../../../../utils';

class AddPaymentMethod extends React.Component {
    state = {
        type: '',
        name: '',
        provider: '',
        number: '',
        validationErrors: {},
        initialValidation: false,
    };

    constructor(props) {
        super(props);
        this.numberInputRef = React.createRef();
    }

    validator = new FormValidator({
        name: 'min:3',
        provider: 'required',
        number: 'min:4'
    });

    componentDidMount(){
        if(this.props.type === "Mobile Wallet"){
            this.setState({provider: 'mpesa'});
            this.validator = new FormValidator({
                name: 'min:3',
                provider: 'required',
                number: 'phone'
            });
        }
        else if(this.props.type === "Card"){
            this.setState({provider: 'card'});
            this.validator = new FormValidator({
                name: 'min:3',
                number: 'min:3'
            });
        }
    }

    validateForm = async () => {
        const validationErrors = await this.validator.validate(this.state);
        this.setState({validationErrors, initialValidation: true});
        const firstInvalidInput = Object.keys(validationErrors)[0];
        const inputNode = document.querySelector(`[name=${firstInvalidInput}]`);
    
        return [_flatten(Object.values(validationErrors)), inputNode];
    }

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({[name]: value }, () => {
            if(name === 'provider' && !this.state.number.length){
                if(this.numberInputRef.current)
                    this.numberInputRef.current.focus();
            }
            if(this.state.initialValidation)
                this.validateForm();
        });
    }
    
    handleSubmit = async (e) => {
        e.preventDefault();
        const [errors, inputNode] = await this.validateForm();
    
        if(errors.length){
            if(inputNode)
                inputNode.focus();
                
            return;
        }
    
        let paymentMethod = {...this.state};
        delete paymentMethod.initialValidation;
        delete paymentMethod.validationErrors;

        const type = this.props.type;
        paymentMethod.type = type;
        const isMobileWallet = type === 'Mobile Wallet';
        
        if(isMobileWallet)
            paymentMethod.number = makePhoneNumberValid(paymentMethod.number);

        this.props.onPersistPaymentMethod(paymentMethod);
    }

    render() { 
        const { 
            name, 
            provider, 
            number, 
            validationErrors} = this.state;

        const pageTitle= 'Add ' + this.props.type;
        const { type } = this.props;
        const isMobileWallet = type === 'Mobile Wallet';
        const isCard = type === 'Card';
        const providerOptions = isMobileWallet ? mobileProviderOptions : bankProviderOptions;
        const providerLabelMap = isMobileWallet ? mobileProviderLabelMap : bankProviderLabelMap;
        
        let providerLabel = "Account";
        if(isMobileWallet)
            providerLabel = provider ? providerLabelMap[provider] : "Mobile Wallet";
        else if(isCard) providerLabel = "Card";
        
        providerLabel += " Number";
        return ( 
            <FlModal pageTitle={pageTitle}>
                <form id="addPaymentMethod" 
                    className={isMobileWallet ? 'for-mobile' : ''} 
                    onSubmit={this.handleSubmit}>
                    
                    { !isCard && (
                        <>
                            <FlRadioButtonGroup
                                label={ isMobileWallet ? 'Mobile Wallet' : 'Bank'}
                                name='provider'
                                value={provider}
                                options={providerOptions}
                                onChange={this.handleChange}
                                errors={validationErrors.provider} />

                            <Spacer height="20" />
                        </>
                    ) }
                    
                    { isCard && <Spacer height="32" /> }

                    <FlInput
                        inputRef={this.numberInputRef}
                        label={providerLabel}
                        name='number'
                        type={ isMobileWallet ? 'number': 'text' }
                        value={number} 
                        onChange={this.handleChange}
                        errors={validationErrors.number} >
                        { isMobileWallet && <span>+255</span> }
                    </FlInput>

                    <FlInput
                        label="Payment Method Name"
                        name='name'
                        value={name}
                        placeholder="E.g. Mpesa, or Mastercard, or CRDB"
                        onChange={this.handleChange}
                        errors={validationErrors.name}
                        hint="Simple name to identify this payment method." />

                    <FlButton type="submit" block primary rounded>
                        { pageTitle }
                    </FlButton>
                </form>
            </FlModal>
        );
    }
}

function mapDispatchToProps(dispatch){
    return {
        onPersistPaymentMethod: (paymentMethod) => {
            dispatch(persistPaymentMethod(paymentMethod))
        }
    }
}

function mapStateToProps(state){
    return {
        saving: state.app.formLoading
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(AddPaymentMethod);