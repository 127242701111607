import React from 'react';
import './styles.css';

const RecepientTypePicker = ({selectedType, onTypeSelected}) => {
    const recepientTypes = {
        account: 'Account(s)',
        investor: 'Investor'
    };

    return ( 
        <div className="recepient-type-picker">
            <div className="recepient-types">
                { 
                    Object.keys(recepientTypes).map(type => {
                        let className = 'recepient-type-choice';
                        className += type === selectedType ? ' selected' : '';

                        return (
                            <button key={type} className={className} 
                                type="button" onClick={() => onTypeSelected(type)}>
                                {recepientTypes[type]}
                            </button>
                        );
                    
                    })
                }
            </div>
        </div>
    );
}
 
export default RecepientTypePicker;
