/*eslint no-unused-vars:'off' */

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import _flatten from 'lodash/flatten';
import classnames from 'classnames/bind';

import FormValidator from '../../../services/FormValidator';
import { canNotify } from '../../../components/FlNotifications';
import { makePhoneNumberValid } from '../../../utils';

import { authRegister } from '../../../store/actions/register';
import { FlInput,FlCheckbox } from '../../../components/FormControls';
import FlButton from '../../../components/FlButton';
import Spacer from '../../../components/Spacer';
import HashRoute from '../../../Router/HashRoute';

import styles from '../styles.scss';
import Terms from './Terms';
import Privacy from './Privacy';

const cx = classnames.bind(styles);

class Register extends React.Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
        acceptedTerms: false,
        validationErrors: {},
        initialValidation: false,
    };

    validator = new FormValidator({
        firstName: 'min:3',
        lastName: 'min:3',
        email: 'email',
        phoneNumber: 'min:9',
        password: 'min:6',
        confirmPassword: 'min:6|asField:password'
    });

    validateForm = async () => {
        if(this.state.email.length > 0){
            this.validator = new FormValidator({
                firstName: 'min:3',
                lastName: 'min:3',
                email: 'email',
                phoneNumber: 'min:9',
                password: 'min:6',
                confirmPassword: 'min:6|asField:password'
            });
        }
        else{
            this.validator = new FormValidator({
                firstName: 'min:3',
                lastName: 'min:3',
                phoneNumber: 'min:9',
                password: 'min:6',
                confirmPassword: 'min:6|asField:password'
            });
        }

        const validationErrors = await this.validator.validate(this.state);
        this.setState({validationErrors, initialValidation: true});
        const firstInvalidInput = Object.keys(validationErrors)[0];
        
        //get the first input that is not a radio or checkbox
        const exemptions = ':not([type="radio"]):not([type="checkbox"])';
        const inputNode = document.querySelector(`[name=${firstInvalidInput}]${exemptions}`);
    
        return [_flatten(Object.values(validationErrors)), inputNode];
    }

    handleChange = event => {
        const { name, type, checked, value } = event.target;
        this.setState({ [name]: type === 'checkbox' ? checked : value }, 
          () => this.state.initialValidation ? this.validateForm() : null
        );
    }
    
    handleSubmit = async (e) => {
        e.preventDefault();
        const [errors, inputNode] = await this.validateForm();
    
        if(errors.length){
            if(inputNode)
                inputNode.focus();

            return this.props.Toast('Form has some errors');
        }
    
        if(!this.state.acceptedTerms)
          return this.props.Alert(null, 'Please accept terms and conditions.');
    
        let userData = {...this.state};
        delete userData.acceptedTerms;
        delete userData.confirmPassword;
        delete userData.validationErrors;
        delete userData.initialValidation;

        userData.phoneNumber = makePhoneNumberValid(userData.phoneNumber);

        this.props.onRegister(userData);
    }

    render() {
        const {
            firstName,
            lastName,
            email,
            phoneNumber,
            password,
            confirmPassword,
            acceptedTerms,
            validationErrors
        } = this.state;

        return (
            <React.Fragment>
                <div className={cx('authScene')} style={{paddingTop: '0.2em'}}>
                    <FlButton className={cx('bailOutButton', 'z-1')} action onClick={this.props.onGoBackToLogin}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                    </FlButton>

                    <form className={cx('form')}
                        onSubmit={this.handleSubmit}>
    
                        <img src='logo.png' className={cx('logoImage')} alt="" />
    
                        <div className={cx('formTitle')}>
                            Create Account
                        </div>
    
                        <FlInput
                            autoFocus
                            type="text"
                            label="First Name"
                            name='firstName'
                            value={firstName} 
                            onChange={this.handleChange}
                            errors={validationErrors.firstName} />
                        
                        <FlInput
                            type="text"
                            label="Last Name"
                            name='lastName'
                            value={lastName} 
                            onChange={this.handleChange}
                            errors={validationErrors.lastName} />


                        <Spacer height="20"/>
                        
                        <FlInput
                            type="number"
                            label="Phone Numer"
                            name='phoneNumber' 
                            value={phoneNumber} 
                            onChange={this.handleChange}
                            errors={validationErrors.phoneNumber}
                            placeholder="E.g. 0781900211"
                            hint="Phone number should be existing and active, we'll send you a text to confirm">
    
                            <span>+255</span>
                        </FlInput>
                        
                        <FlInput
                            type="email"
                            label="Email"
                            name='email'
                            value={email} 
                            onChange={this.handleChange}
                            errors={validationErrors.email} />
                        
                        <FlInput
                            type="password"
                            label="Password"
                            name='password'
                            value={password} 
                            onChange={this.handleChange}
                            errors={validationErrors.password} />
                        
                        <FlInput
                            type="password"
                            label="Confirm Password"
                            name='confirmPassword'
                            value={confirmPassword} 
                            onChange={this.handleChange}
                            errors={validationErrors.confirmPassword} />
                        
                        <FlCheckbox
                            name='acceptedTerms'
                            checked={acceptedTerms} 
                            onChange={this.handleChange}>
    
                            By signing up you agree to our&nbsp;
                            <a href="#finlinkModal/PrivacyPolicy">privacy policy</a> &amp;&nbsp;
                            <a href="#finlinkModal/TermsOfService">terms of service</a>
    
                        </FlCheckbox>

                        <FlButton type="submit" block primary 
                            loading={this.props.registering}>
                            Register
                        </FlButton>
    
                        <div className="siblingPageLink" style={{marginTop: '2.5em'}}>
                            Already a member? &nbsp;<Link to="/login">Login</Link>
                        </div>
                    </form>
                </div>

                <React.Fragment>
                    <HashRoute exact path="#finlinkModal/TermsOfService" component={Terms} />
                    <HashRoute exact path="#finlinkModal/PrivacyPolicy" component={Privacy} />
                </React.Fragment>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state){
    return {
        registering: state.app.formLoading,
    }
}

function mapDispatchToProps(dispatch){
    return {
        onGoBackToLogin: () => {
            dispatch( replace('/login') )
        },
        onRegister: (user) => {
            dispatch( authRegister(user) )
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(canNotify(Register));