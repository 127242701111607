import React from 'react';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { verifyUserByOtp, verifyUserAccount, sendVerificationText } from '../../../store/actions/register';
import _flatten from 'lodash/flatten';

import classnames from 'classnames/bind';
import styles from '../styles.scss';

import FlButton from '../../../components/FlButton';
import { FlInput } from '../../../components/FormControls';
import FormValidator from '../../../services/FormValidator';
import { canNotify } from '../../../components/FlNotifications';

const cx = classnames.bind(styles);

class PhoneNotVerified extends React.Component {
    state = {
        otpCode: '',
        sendingOtp: false,
        reSendingOtp: false,
        initialValidation: false,
        validationErrors: {}
    }

    validator = new FormValidator({
        otpCode: 'min:4|max:4'
    });

    componentWillMount(){
        const { location } = this.props.history;
        const queryString = location.search;

        if(queryString && queryString.length){
            const verificationToken = queryString.replace('?token=', '');
            this.props.onVerifyUserAccount(verificationToken);
        }

        if(!this.props.user)
            this.props.onRedirectToLogin();
        else{
            const { _id } = this.props.user;
            this.setState({_id});
        }
    }

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value },
            () => this.state.initialValidation ? this.validateForm() : null
        );
    }

    validateForm = async () => {
        const validationErrors = await this.validator.validate(this.state);
        this.setState({validationErrors, initialValidation: true});
        return _flatten(Object.values(validationErrors));
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const errors = await this.validateForm();

        if(errors.length){
            document.querySelector('[name=otpCode').focus();
            return this.props.Toast('Invalid verification code');
        }

        this.setState({sendingOtp: true, reSendingOtp: false}, () => {
            this.props.onVerifyWithOtp(this.state.otpCode, this.props.user.phoneNumber);
        });
    }
    
    handleResendOTP = event => {
        event.preventDefault();
        this.setState({reSendingOtp: true, sendingOtp: false}, () => {
            this.props.onSendVerificationText(this.props.user.phoneNumber);
        });
    }

    render() {
        const { user } = this.props;
        const shortName = user ? user.firstName[0] + user.lastName[0] : '';
        let firstName = user ? user.firstName : 'there';
        firstName = firstName.charAt(0).toUpperCase() + firstName.substr(1);

        const { location } = this.props.history;
        const queryString = location.search;
        let token = '';

        if(queryString && queryString.length){
            token = queryString.replace('?token=', '');
        }
        
        return (
            <div className={cx('authScene confirmScene layout center')}>
                <form className={cx('form')}
                    onSubmit={this.handleSubmit}>

                    <div className={cx('logoImage circle')}>
                        { shortName }
                    </div>

                    <div className={cx('formTitle')} style={{marginBottom: '1em'}}>
                        { !token.length ? 'Verify Phone Number' : 'Verifying Account'}
                    </div>


                    { token.length > 0 && 
                        <p>
                            Hey { firstName }, please wait while we verify your account.
                        </p>
                    }

                    { !token.length && 
                        <React.Fragment>
                            <p style={{marginBottom: '2.5em'}}>
                                {/* Click verification link that was sent to your e-mail to verify account. */}
                                Enter the code that was sent to your phone number (<strong>{user.phoneNumber}</strong>)
                            </p>

                            <div style={{marginBottom: '3em'}}>
                                <FlInput
                                    type="number"
                                    label="Verification Code"
                                    name='otpCode'
                                    required
                                    minLength="4"
                                    maxLength="4"
                                    textCenter
                                    style={{width: '250px', margin: '0 auto 2.2em auto'}}
                                    onChange={this.handleChange}
                                    errors={this.state.validationErrors.otpCode} />
                            
                                <div style={{width: '180px', margin: 'auto'}}>
                                    <FlButton type="submit" primary
                                        block
                                        loading={this.props.saving && !this.state.reSendingOtp}
                                        disabled={this.props.saving && this.state.reSendingOtp}>
                                        Submit
                                    </FlButton>
                                </div>
                            </div>


                            <p style={{marginBottom: '1.4em'}}>
                                Didn't receive verification code?
                            </p>
                        </React.Fragment>
                    }

                    <div className="layout center-justified">
                        <FlButton style={{background: '#3d3d3d'}} type="button" inverse
                            disabled={this.props.saving && this.state.sendingOtp}
                            loading={this.props.saving && !this.state.sendingOtp}
                            onClick={this.handleResendOTP}>
                            Resend Code
                        </FlButton>
                    </div>

                    { !token.length && 
                        <div className="siblingPageLink layout center" style={{marginTop: '2.5em'}}>
                            Already verified? &nbsp;
                            <FlButton flat accent onClick={this.props.onRedirectToLogin}>Login</FlButton>
                        </div>
                    }
                </form>
            </div>
        );
    }
}

function mapStateToProps(state){
    return {
        saving: state.app.formLoading,
        user: state.auth.user
    }
}

function mapDispatchToProps(dispatch){
    return {
        onVerifyWithOtp: (otp, phoneNumber) => {
            dispatch(verifyUserByOtp(otp, phoneNumber))
        },
        onSendVerificationText: (phoneNumber) => {
            dispatch(sendVerificationText(phoneNumber))
        },
        onVerifyUserAccount: (verificationToken) => {
            dispatch(verifyUserAccount(verificationToken))
        },
        onRedirectToLogin: () => {
            dispatch(replace('/login'));
        },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(canNotify(PhoneNotVerified));