import React from 'react';

import FlButton from '../';

import './styles.scss';

const FAB = ({mini, raised, className, ariaLabel="Unknown Action", ...props}) => {
    className += " FAB";
    className += `${raised ? ' raised' : ''}`;
    className += `${mini ? ' mini' : ''}`;

    return <FlButton ariaLabel={ariaLabel} {...props} className={className} />
}
 
export default FAB;