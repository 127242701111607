import { push } from 'connected-react-router';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import FlButton from '../../../../../components/FlButton';
import FlexBox from '../../../../../components/FlexBox';
import Loader from '../../../../../components/Loader';
import Spacer from '../../../../../components/Spacer';
import { fetchAccounts } from '../../../../../store/actions/account';
import { setDepositer } from '../../../../../store/actions/deposit-modal';

function PaymentCards({ history, accounts, fetchingAccounts, onFetchAccounts, onAddPaymentCard, onSetDepositor }) {
    const [paymentCards, setPaymentCards] = useState([]);

    useEffect(() => {
        if(accounts && accounts[0] && accounts[0].paymentCards && accounts[0].paymentCards.length){
            const cards = accounts[0].paymentCards.filter(({name}) => name && name.length);
            setPaymentCards(cards);
        }
    }, [accounts]);

    useEffect(() => {
        if(!accounts || !accounts.length) onFetchAccounts();
    }, []);

    function handleDepositFromCard(card){
        onSetDepositor({...card, tempId: "card-114141", provider: "card"});
        history.push("#finlinkModal/DepositMoney");
    }

    return (
        <div className="profile-section">
            <h1 className="profile-section-title">Payment Cards</h1>

            {
                fetchingAccounts && (
                    <FlexBox align="center">
                        <Loader size={40} /> &emsp; 
                        <span style={{color: "#ccc"}}>Fetching your payment cards...</span>
                    </FlexBox>
                )
            }

            {paymentCards.map((card) => {
                return (
                <button
                    key={card._id}
                    role="listitem"
                    className="profile-detail layout center text-white"
                    style={{ border: "1px solid #888", borderRadius: "6px", padding: "0.5rem 1rem", marginBottom: "0.65rem", width: "100%", background:"transparent" }}
                    onClick={() => handleDepositFromCard(card)}
                >
                    <svg width="26" height="26" viewBox="0 0 24 24" fill="#999" style={{ marginBottom: "0.25rem" }}><path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"/></svg>
                    <Spacer width="15" />
                    <div className="layout vertical start">
                        <small>{card.name}</small>
                        <h2>{card.number}</h2>
                    </div>
                </button>
                )
            })}

            <FlButton flat onClick={onAddPaymentCard}>ADD PAYMENT CARD</FlButton>
        </div>
    );
}

function mapStateToProps(state){
    return {
        accounts: state.account.accounts || [],
        fetchingAccounts: state.account.fetchingAccounts || false
    }
}

function mapDispatchToProps(dispatch){
    return {
        onSetDepositor: (account) => {
            dispatch(setDepositer(account))
        },
        onFetchAccounts: (reloading = false) => {
            dispatch( fetchAccounts(null, reloading) )
        },
        onAddPaymentCard: () => {
            dispatch( push('#finlinkModal/AddPaymentCard') )
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PaymentCards));