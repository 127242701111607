import React from 'react';
import _reduce from 'lodash/reduce';
import { connect } from 'react-redux';
import { push } from "react-router-redux";
import { Link } from "react-router-dom";

import { canNotify } from '../../../../components/FlNotifications';
import HashRoute from '../../../../Router/HashRoute';
import AddAccount from '../AddAccount';

import { fetchAccounts } from '../../../../store/actions/account';

import FlButton from '../../../../components/FlButton';
import Loader from '../../../../components/Loader';
import Spacer from '../../../../components/Spacer';
import NavBar from '../../components/NavBar';

import logo from './img/logo.png';

import classnames from 'classnames/bind';
import styles from './styles.scss';
import FlexBox from '../../../../components/FlexBox';
import ViewUpdate from '../ViewUpdate';
import Carousel from './Carousel';
import { getNotifications } from '../../../../store/actions/app';
import { getUnreadNotifications } from '../../../../store/selectors/app';

const cx = classnames.bind(styles);

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carouselStep: 0
        };
    }

    componentDidMount(){
        if(!this.props.accounts.length)
            this.props.onFetchAccounts();

        if(!this.props.fetchingNotifications)
            this.props.fetchUserNotifications();
    }

    openAddAccount = () => {
        const balanceSum = _reduce(this.props.accounts, (sum, account) => {
            return sum + account.balance;
        }, 0);
        
        if(balanceSum < 1)
            return this.props.Alert('No balance in main account', 'You can\'t create an account without balance in main account.');

        this.props.onOpenAddAccount();
    }

    handleReloadAccounts = () => {
        this.props.onFetchAccounts(true);
    }

    render() {
        const { 
            fetchingAccounts, 
            newReleaseDetails, 
            accounts, 
            assets, 
            liabilities, 
            balance,
            onViewUpdate, 
            viewProfile, 
            unreadNotificationCount, 
            viewNotifications
        } = this.props;
        return ( 
            <React.Fragment>
                <NavBar noborder>
                    <div className="top-bar layout center">
                        <span className="ot-header-title layout center" style={{paddingLeft:'0.5em'}}>
                            <img className="ot-logo" src={logo} alt="" />
                            FINLINK
                        </span>

                        <span className="flex"></span>

                        <FlButton disabled={fetchingAccounts} action onClick={this.handleReloadAccounts}>
                            <svg viewBox="0 0 24 24"><path d="M17.65 6.35c-1.63-1.63-3.94-2.57-6.48-2.31-3.67.37-6.69 3.35-7.1 7.02C3.52 15.91 7.27 20 12 20c3.19 0 5.93-1.87 7.21-4.56.32-.67-.16-1.44-.9-1.44-.37 0-.72.2-.88.53-1.13 2.43-3.84 3.97-6.8 3.31-2.22-.49-4.01-2.3-4.48-4.52C5.31 9.44 8.26 6 12 6c1.66 0 3.14.69 4.22 1.78l-1.51 1.51c-.63.63-.19 1.71.7 1.71H19c.55 0 1-.45 1-1V6.41c0-.89-1.08-1.34-1.71-.71l-.64.65z"/></svg>
                        </FlButton>

                        <Spacer width="50" />

                        <FlButton id="unreadNotificationButton" className="pos-r" action onClick={viewNotifications}>
                            <svg viewBox="0 0 24 24"><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"/></svg>
                            { unreadNotificationCount &&
                                <span className="pos-a">{unreadNotificationCount}</span>
                            }
                        </FlButton>

                        <Spacer width="50" />

                        <FlButton action onClick={viewProfile}>
                            <svg viewBox="0 0 24 24" style={{fill:'none !important'}} stroke="currentColor"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                        </FlButton>

                        <Spacer width="25" />
                    </div>
                </NavBar>

                { newReleaseDetails && 
                    <div id="newVersionBanner" className="layout center">
                        <div id="updateIcon">
                            <svg width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="23 4 23 10 17 10"></polyline><polyline points="1 20 1 14 7 14"></polyline><path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path></svg>
                        </div>
                        <div className="flex">
                            <strong>Update Available{newReleaseDetails ? `(${newReleaseDetails.name})` : ''}</strong>  
                            Click the button to see details
                        </div>

                        <FlButton flat onClick={onViewUpdate} style={{fontSize: '18px'}}>
                            View
                        </FlButton>
                    </div>
                }

                <div className={cx('mainFragment homeScene')}>
                    <div className="savingsSummaryPlaceholder">
                        <Carousel />

                        {/* { 
                            !this.props.fetchingAccounts && this.props.accounts && this.props.accounts.length < 2 && 

                            <div className="cta">
                                <FlButton onClick={this.openAddAccount} primary rounded small>Start Saving Now</FlButton>
                            </div>
                        } */}
                    </div>

                    <div className="textualSummary">
                        <span className="sectionTitle">
                            FULL SUMMARY
                        </span>

                        <div className="textualSummaryItem pos-r">
                            <span>ASSETS:</span>

                            <strong className={ fetchingAccounts ? 'op-0' : '' }>TZS <span>{ parseInt(assets).toLocaleString() }</span></strong>

                            { fetchingAccounts && 
                                <Loader className="pos-a fill-y my-auto ml-auto r-3" size="35" color="#aaa" />
                            }
                        </div>

                        <div className="textualSummaryItem negative pos-r">
                            <span>LIABILITIES:</span>

                            <strong className={ fetchingAccounts ? 'op-0' : '' }>TZS <span>{ parseInt(liabilities).toLocaleString() }</span></strong>

                            { fetchingAccounts && 
                                <Loader className="pos-a fill-y my-auto ml-auto r-3" size="35" color="#aaa" />
                            }
                        </div>

                        <div className="textualSummaryItem">
                            <span>MY NET WORTH:</span>
                            <strong className={ fetchingAccounts ? 'op-0' : '' }>TZS <span> { parseInt(balance).toLocaleString() } </span></strong>
                            { fetchingAccounts && 
                                <Loader className="pos-a fill-y my-auto ml-auto r-3" size="35" color="#aaa" />
                            }
                        </div>
                    </div>
                    
                    <div className="accountsSummary">
                        <span className="sectionTitle">
                            MY ACCOUNTS

                            <FlButton onClick={this.openAddAccount} flat>Add Account</FlButton>
                        </span>

                        { fetchingAccounts && 
                            <FlexBox vertical center>
                                <Spacer height="15" />
                                <Loader color="#aaa" />
                                <Spacer height="20" />
                                <span className="accountFetchLoading">Fetching your accounts.</span>
                            </FlexBox>
                        }

                        { !fetchingAccounts &&
                           accounts.map((account, index) => (
                                <Link to={`/main/account/${account._id}`} 
                                    key={index} className="accountSummaryItem">
                                    
                                    <svg width="28" height="28" viewBox="0 0 24 24"><path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"/></svg>
                                    <div>
                                        <span>{account.name}</span>
                                        <small>{account.type}</small>
                                    </div>
                                    <strong>{ account.currency } <span> { parseInt(account.balance).toLocaleString() } </span></strong>
                                </Link>
                            ))
                        }
                    </div>
                </div>

                <HashRoute exact path="#finlinkModal/AddAccount" component={AddAccount} />

                { newReleaseDetails &&
                    <HashRoute exact 
                        path="#finlinkModal/finlinkUpdate" 
                        component={ViewUpdate} />
                }
            </React.Fragment>
        );
    }
}

function mapStateToProps(state){
    return {
        newReleaseDetails: state.app.newReleaseDetails,
        fetchingNotifications: state.app.notifications.fetching,
        unreadNotificationCount: getUnreadNotifications(state).length,
        fetchingAccounts: state.account.fetchingAccounts,
        accounts: state.account.accounts || [],
        assets: state.account.assets || 0,
        liabilities: state.account.liabilities || 0,
        balance: state.account.balance || 0
    }
}

function mapDispatchToProps(dispatch){
    return {
        onFetchAccounts: (reloading = false) => {
            dispatch( fetchAccounts(null, reloading) )
        },
        onOpenAddAccount: () => {
            dispatch( push('/main#finlinkModal/AddAccount') )
        },
        onViewUpdate: () => {
            dispatch(push('/main#finlinkModal/finlinkUpdate'))
        },
        viewProfile: () => {
            dispatch(push('/main/profile'))
        },
        fetchUserNotifications: () => {
            dispatch(getNotifications())
        },
        viewNotifications: () => {
            dispatch(push('/main/notifications'))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(canNotify(Home));