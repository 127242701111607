import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../actions/notifications';
import _filter from 'lodash/filter';

const initialState = [];

const auth = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
        return [
            ...state,
            action.notification
        ];
    case REMOVE_NOTIFICATION:
        return _filter(state.notifications, (n) => n.id !== action.notificationId);
    default:
      return state;
  }
};

export default auth;
