import React from 'react';
import { canNotify } from '../../../../../components/FlNotifications';
import FlButton from '../../../../../components/FlButton';
import { FlInput } from '../../../../../components/FormControls';
import Spacer from '../../../../../components/Spacer';
import SearchRoute from '../../../../../Router/SearchRoute';
import FlDialpad from '../../../components/FlDialpad';
import { makePhoneNumberValid } from '../../../../../utils';

import SetTransferInvestor from './SetTransferInvestor';

import './styles.scss';

const TransferToInvestor = (props) => {
    const {
        investorName,
        investorPhoneNumber,
        transferAmount,
        currentAccount,
        saving, 
        history, 
        onAmountChanged,
        onMakeTransaction,
        onSetInvestor,
        authUser
    } = props;
    
    const setAmount = (amount) => {
        onAmountChanged(amount);
        closeNumberPad();
    }
    
    const closeNumberPad = () => {
        history.goBack();
    }
    
    const showSetInvestor = () => {
        history.push({
            hash: '#finlinkModal/TransferMoney',
            search: '?to=investor&setInvestor'
        });
    }
    
    const handleInvestorFetched = (investor) => {
        onSetInvestor(investor);

        history.push({
            hash: '#finlinkModal/TransferMoney',
            search: '?to=investor'
        });
    }
    
    const showNumberPad = () => {
        history.push({
            hash: '#finlinkModal/TransferMoney',
            search: '?to=investor&enterTransferAmount'
        });
    }

    const balanceIsEnough = () => {
        const accountBalance = currentAccount.availableBalance;
        if(Number(accountBalance) < Number(transferAmount)){
            let overBalanceMessage = `Transfer amount (${transferAmount.toLocaleString()}) exceeds account balance (${accountBalance.toLocaleString()}).`;
            return props.Alert(null, overBalanceMessage);
        }

        return true;
    }

    const handleSubmit = event => {
        event.preventDefault();
        
        if(balanceIsEnough()){
            let transferConfirmMessage = `Are you sure you want to transfer <strong>TZS ${Number(transferAmount).toLocaleString()}</strong> to <strong>${investorName}</strong>?</span>`;
            props.Alert({
                message: transferConfirmMessage,
                cancelText: "No, Cancel",
                okText: "Yes, Transafer",
                onCancel: () => {},
                onOkay: () => {
                    onMakeTransaction(currentAccount._id, {investorUsername: makePhoneNumberValid(investorPhoneNumber), amount: transferAmount});
                }
            });

        }
    }

    
    return ( 
        <React.Fragment>
            <form id="transferToInvestorForm" onSubmit={handleSubmit}>
                <span id="mainAccountAvailableBalance" className="layout center-justified text-center">
                    your balance:&nbsp;
                    <strong>{currentAccount.availableBalance.toLocaleString()}/-</strong>
                </span>
                
                <Spacer height="36" />

                <div className="pos-r">
                    <FlInput
                        type="text"
                        label="Investor"
                        name='investorUsername'
                        placeholder="( tap to set investor )"
                        value={investorName}
                    />

                    <button className="pos-a fill z-10" type="button" 
                        style={{width: '100%', opacity: 0}}
                        onClick={showSetInvestor}>
                    </button>
                </div>

                <div className="pos-r">
                    <FlInput
                        type="text"
                        label="Amount"
                        placeholder="enter transfer amount"
                        value={transferAmount == 0 ? "" : transferAmount.toLocaleString()}
                    >
                        <span>TZS</span>
                    </FlInput>


                    <button className="pos-a fill z-10" type="button" 
                        style={{width: '100%', opacity: 0}}
                        onClick={showNumberPad}>
                    </button>
                </div>


                <FlButton type="submit" 
                    block primary large rounded
                    disabled={!investorPhoneNumber || !investorPhoneNumber.length || transferAmount === 0}
                    loading={saving}>
                    Transfer
                </FlButton>
            </form>
    
            <SearchRoute path="setInvestor"
                component={() => 
                    <SetTransferInvestor
                        authUser={authUser}
                        onInvestorFetched={handleInvestorFetched}
                    />
                }
            />
            
            <SearchRoute path="enterTransferAmount"
                component={() => 
                    <FlDialpad 
                        amount={transferAmount}
                        onComplete={setAmount}
                        onCancel={closeNumberPad} />
                }
            />
        </React.Fragment>
    );
}
 
export default canNotify(TransferToInvestor);