import React, { useState, useRef, useEffect } from 'react';
import { withRouter, Route, Redirect } from 'react-router';

import { FlMaskedInput } from '../../../../components/FormControls';
import FlButton from '../../../../components/FlButton';
import FlModal from '../../../../components/FlModal';
import FlAlert from '../../../../components/FlAlert';
import FormValidator from '../../../../services/FormValidator';

const EnterGuaranteeAmount = ({history, mainAccountBalance, currentGuaranteeRequest, onAmountSet}) => {
    if(!currentGuaranteeRequest || !currentGuaranteeRequest.applicant)
        return <Redirect to="/main/guarantor-pool/" />;

    const [amount, setAmount] = useState("");
    const [amountValidationErrors, setAmountValidationErrors] = useState([]);
    const [initialValidationTriggered, setInitialValidationTriggered] = useState(false);
    const amountInputRef = useRef(null);

    const validGuarantors = currentGuaranteeRequest.guarantors.filter(({status}) => status === "guaranteed");
    const guaranteedAmount = validGuarantors.reduce((sum, {amount}) => sum + amount, 0);
    const maxGuaranteedAmount = currentGuaranteeRequest.maxGuaranteedAmount;
    let maxAmount = currentGuaranteeRequest.amount - guaranteedAmount;
    maxAmount = maxGuaranteedAmount && maxGuaranteedAmount < maxAmount ? maxGuaranteedAmount : maxAmount;

    useEffect(() => {
        focusAmountInput();
    }, []);
    
    useEffect(() => {
        if(initialValidationTriggered)
            validateamount(amount);
    }, [amount]);

    async function validateamount(){
        if(!initialValidationTriggered)
            setInitialValidationTriggered(true);

        const min = maxAmount < 5000 ? maxAmount : 5000;
        const validator = new FormValidator({
            amount: `number|min:${min}|max:${maxAmount}`
        });
        const validationErrors = await validator.validate({amount});

        if(Object.keys(validationErrors).length > 0){
            setAmountValidationErrors(Object.values(validationErrors)[0]);
            return false;
        }
        else
            setAmountValidationErrors([]);
            

        return true;
    }
    
    const closeErrorAlert = () => {
        history.goBack();
        focusAmountInput();
    }
    
    const focusAmountInput = () => {
        if(amountInputRef && amountInputRef.current)
            amountInputRef.current.focus();
    }

    async function handleSubmit(e){
        if(e) e.preventDefault();

        const guarantoramountIsValid = await validateamount();
        if(!guarantoramountIsValid){
            history.push('/main/guarantor-pool/add-amount/error');
            return;
        }

        onAmountSet(amount);
    }

    return (
        <div id="EnterGuaranteeAmount">
            <FlModal pageTitle="Guarantee Loan"
                onBack={() => history.goBack()}>
                <form className="RequestLoanSection" 
                    onSubmit={handleSubmit} >
                    
                    { mainAccountBalance && mainAccountBalance.length && (
                        <p id="availableBalance" className="text-center">
                            balance: {mainAccountBalance}
                        </p>
                    )}

                    <h3 className="loan-request-section-title">
                        How much would you like to guarantee?
                    </h3>
                        
                    <FlMaskedInput
                        textCenter
                        type="text"
                        name='amount'
                        mask="currency"
                        placeholder="enter amount here"
                        value={amount}
                        inputRef={amountInputRef}
                        onChange={({target}) => setAmount(target.value)}
                        errors={amountValidationErrors} />

                    <p className="text-center">
                        Max amount is {maxAmount.toLocaleString()}
                    </p>

                    <div className="pos-f fill" style={{top: "auto"}}>
                        <FlButton
                            primary
                            type="submit"
                            block
                            loading={currentGuaranteeRequest.saving}
                        >
                            Submit
                        </FlButton>
                    </div>
                </form>
            </FlModal>

            <Route path='/main/guarantor-pool/add-amount/error' render={() => (
                <FlAlert
                    message={amountValidationErrors[0]}
                    onOkay={() => closeErrorAlert()}
                />
            )} />
        </div>
    );
}
 
export default withRouter(EnterGuaranteeAmount);