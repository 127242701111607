import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { getLoanRequests } from '../../../../store/actions/loan';

import NavBar from '../../components/NavBar';
import Loader from '../../../../components/Loader';

import LoanDetail from '../LoanDetail';
import { Route } from 'react-router';

import './styles.scss';

function loanTitle({reason, bankDetails}){
    if(reason === "personal")
        return "Personal Loan";
    else if(reason === "rent")
        return `Rent payment to ${bankDetails.bankAccountName}`;
    else if(reason === "fees")
        return `School fees for ${bankDetails.studentName} at ${bankDetails.bankAccountName}`;
}

const LoanRequests = ({history, loanRequests, fetchingLoanRequests, viewLoan, ...props}) => {
    useEffect(() => {
        if(!loanRequests && !fetchingLoanRequests)
            props.fetchLoanRequests();
    }, [loanRequests, fetchingLoanRequests]);

    return (
        <React.Fragment>
            <NavBar 
                title="Loan Requests"
                onGoBack={history.goBack}
            />
            <div id="LoanRequests" className="layout vertical mainFragment">
                <div id="LoanRequestContent" className="flex">
                    { fetchingLoanRequests && (
                        <div style={{height: 150}} className="layout center-center">
                            <Loader size={80} color="#888" />
                        </div>
                    )}
                    
                    { !fetchingLoanRequests && loanRequests && !loanRequests.length && (
                        <p className="loanRequestsScreenMessage">
                            You have no pending loan requests.
                        </p>
                    )}

                    { !fetchingLoanRequests && loanRequests &&
                        loanRequests.map(request => {
                            let status = request.status;
                            if(status === "disbursementApproved")
                                status = "approved";
                            // else if(status !== "rejected" || status != "cleared")
                                // status = "pending";

                            return (
                                <div key={request._id} className={`loan-request-item posr-r ${status}`}
                                    onClick={() => viewLoan(request._id)}>
                                    <h3 className="layout inline end">
                                        <small>TZS</small>{request.amount.toLocaleString()}
                                    </h3>
                                    <h5>
                                        { loanTitle(request) }
                                    </h5>

                                    <span className="pos-a layout inline center">
                                        <small>&middot;</small>{status}
                                    </span>
                                </div>
                            );
                        })
                    }
                </div>

                <Route path='/main/loanRequests/:loanId' component={LoanDetail} />
            </div>
        </React.Fragment>
    );
}

function mapStateToProps(state){
    return {
        fetchingLoanRequests: state.loan.fetching,
        loanRequests: state.loan.requests
    }
}

function mapDispatchToProps(dispatch){
    return {
        viewLoan: (loanId) => {
            dispatch(push(`/main/loanRequests/${loanId}`))
        },
        fetchLoanRequests: () => {
            dispatch(getLoanRequests())
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(LoanRequests);