import React from 'react';

import "./styles.scss";
import FlButton from '../FlButton';

const ListItem = ({className, data, selectable, selected, onClick, onRemove}) => {
    const {icon, image, label, description} = data;
    const removable = onRemove && typeof onRemove === 'function';
    const hasDescription = description && description.length;

    className = className || '';
    className += ` ListItem ${hasDescription ? 'two-line' : ''} ${selected ? 'selected' : ''}`;

    function renderContent(){
        return <React.Fragment>
            { icon &&
                <div className="icon">{icon}</div>
            }
            
            { image &&
                <div className="image">
                    <img src={image} alt=""/>
                </div>
            }

            <div className="text">
                <h5>{label}</h5>
                { hasDescription && <p>{description}</p> }
            </div>

            { selectable &&
                <div className="choice-indicator">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
                </div>
            }
            
            { removable &&
                <FlButton action type="button"
                    ariaLabel="Remove Item"
                    onClick={() => onRemove(data)}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
                </FlButton>
            }
        </React.Fragment>
    }

    if(removable){
        return (
            <div className={className}>
                { renderContent() }
            </div>
        );
    }

    return (
        <button type="button" className={className}
            onClick={() => onClick(data)}>
            { renderContent() }
        </button>
    );
}
 
export default ListItem;

ListItem.defaultProps = {
    data: {}, 
    onClick: () => {}
}