import React from 'react';
import FlButton from '../../../../components/FlButton';
import FlexBox from '../../../../components/FlexBox';
import FlInput from '../../../../components/FormControls/FlInput';
import './styles.css';

class FlDialpad extends React.Component{
    state = {digits: [], open: false}

    componentDidMount(){
        if(this.props.amount !== 0)
            this.setState({value: Number(this.props.amount.toString().replaceAll(',', '')).toLocaleString()});
            // this.setState({digits: this.props.amount.toString().split('')});

        this.openTimer = setTimeout(() => {
            this.setState({open: true});
        }, 50);
    }

    componentWillUnmount(){
        clearTimeout(this.zeroButtonPressTimer);
        clearTimeout(this.deleteButtonPressTimer);
        clearTimeout(this.openTimer);
    }

    zeroButtonPress = (event) => {
        event.preventDefault();
        this.zeroButtonPressTimer = setTimeout(() => {
            this.appendDigit(0,0,0);
        }, 500);
    }

    zeroButtonRelease = (event) => {
        event.preventDefault();
        clearTimeout(this.zeroButtonPressTimer);
        this.appendDigit(0)
    }
    
    deleteButtonPress = (event) => {
        event.preventDefault();
        this.deleteButtonPressTimer = setTimeout(() => {
            this.emptyDigits();
        }, 500);
    }

    deleteButtonRelease = (event) => {
        event.preventDefault();
        clearTimeout(this.deleteButtonPressTimer);
        this.popDigit()
    }

    appendDigit = (...digits) => {
        this.setState({digits: [...this.state.digits, ...digits]})
    }
    
    popDigit = () => {
        let digits = this.state.digits;
        digits = digits.slice(0, digits.length - 1);
        this.setState({digits})
    }
    
    emptyDigits = () => {
        this.setState({digits : []})
    }

    handleSubmitAmount = (e) => {
        e.preventDefault();
        this.onComplete(this.state.value.replaceAll(',', ''));
    }

    handleValueChanged = (e, evt) => {
        const isNumber = [1,2,3,4,5,6,7,8,9,0].includes(Number(evt.data));
        
        if(evt.data && evt.data.toString() != '.' && !isNumber) return false;

        this.setState({value: Number(e.target.value.replaceAll(',', '')).toLocaleString()});
    }

    closeThen = (callback) => {
        this.setState({open: false}, () => {
            this.openTimer = setTimeout(callback, 200);
        });
    }

    onCancel = () => {
        this.closeThen(() => this.props.onCancel());
    }
    
    onComplete = (amount) => {
        this.closeThen(() => this.props.onComplete(amount));
    }

    render(){
        const { digits, open, value } = this.state;
        const amount = digits.length ? parseInt(digits.join('')) : 0;

        return ( 
            <div className={'finlink-dialpad ' + (open ? 'visible' : '')}>
                <div className="finlink-dialpad-backdrop"
                     onClick={this.onCancel}></div>

                <form className="finlink-dialpad-content"
                    onSubmit={this.handleSubmitAmount}>
                    <div id="title">
                        Enter Amount
                    </div>

                    <FlInput 
                        solo
                        autoFocus
                        value={value || ""}
                        onChange={this.handleValueChanged}
                    />

                    <FlexBox justify="end">
                        <FlButton type="submit" primary 
                            loading={this.props.registering}>
                            Submit
                        </FlButton>
                    </FlexBox>
                    {/* 
                    <div id="input">
                        { amount.toLocaleString() }

                        <button id="deleteBtn"
                            onTouchStart={this.deleteButtonPress} 
                            onTouchEnd={this.deleteButtonRelease}
                            >
                            <svg viewBox="0 0 24 24" fill="none" stroke="#757575" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path><line x1="18" y1="9" x2="12" y2="15" /><line x1="12" y1="9" x2="18" y2="15" /></svg>
                        </button>
                    </div>
    
                    <div id="numbers">
                        <button type="button" onClick={() => this.appendDigit(1)}>1</button>
                        <button type="button" onClick={() => this.appendDigit(2)}>2</button>
                        <button type="button" onClick={() => this.appendDigit(3)}>3</button>
                        <button type="button" onClick={() => this.appendDigit(4)}>4</button>
                        <button type="button" onClick={() => this.appendDigit(5)}>5</button>
                        <button type="button" onClick={() => this.appendDigit(6)}>6</button>
                        <button type="button" onClick={() => this.appendDigit(7)}>7</button>
                        <button type="button" onClick={() => this.appendDigit(8)}>8</button>
                        <button type="button" onClick={() => this.appendDigit(9)}>9</button>
                        <button type="button" onClick={this.onCancel}>Cancel</button>

                        <button type="button" 
                            onTouchStart={this.zeroButtonPress} 
                            onTouchEnd={this.zeroButtonRelease}
                            >0</button>
                        
                        <button type="subimt">Ok</button>
                    </div> */}
                </form>
            </div>
        );
    }
}
 
export default FlDialpad;