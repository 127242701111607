import { SET_DEPOSIT_AMOUNT, SELECT_DEPOSITER_ACCOUNT, SET_DEPOSITER, RESET_DEPOSIT_DETAILS
} from '../actions/deposit-modal';

const initialState = { depositerAccountId: null, depositer: null, amount: 1000 };
const depositModal = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_DEPOSITER_ACCOUNT:
            return {
                ...state,
                depositerAccountId: action.depositerAccountId
            };
        case SET_DEPOSITER:
            if(action.depositer === null)
                return initialState;

            return {
                ...state,
                amount: 0,
                depositer: action.depositer
            };
        case SET_DEPOSIT_AMOUNT:
            return {
                ...state,
                amount: action.amount
            };
        case RESET_DEPOSIT_DETAILS:
            return initialState;
        default:
            return state;
    }
};

export default depositModal;
