import React from 'react';
import { withRouter } from 'react-router-dom';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { sendVerificationText } from '../../../store/actions/register';
import { getResetTokenFromOTP } from '../../../store/actions/auth';
import _flatten from 'lodash/flatten';

import FlButton from '../../../components/FlButton';
import { FlInput } from '../../../components/FormControls';
import FormValidator from '../../../services/FormValidator';
import { canNotify } from '../../../components/FlNotifications';

import classnames from 'classnames/bind';
import styles from '../styles.scss';


const cx = classnames.bind(styles);

class EnterOtp extends React.Component {
    state = {
        otpCode: '',
        sendingOtp: false,
        reSendingOtp: false,
        initialValidation: false,
        validationErrors: {}
    }

    validator = new FormValidator({
        otpCode: 'min:4|max:4'
    });

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value },
            () => this.state.initialValidation ? this.validateForm() : null
        );
    }

    validateForm = async () => {
        const validationErrors = await this.validator.validate(this.state);
        this.setState({validationErrors, initialValidation: true});
        return _flatten(Object.values(validationErrors));
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const errors = await this.validateForm();

        if(errors.length){
            document.querySelector('[name=otpCode').focus();
            return this.props.Toast('Invalid verification code');
        }

        const phoneNumber = this.props.location.search.replace("?enterOtpFor=", "");
        this.setState({sendingOtp: true, reSendingOtp: false}, () => {
            this.props.onGetVerificationTokenFromOtp(this.state.otpCode, phoneNumber);
        });
    }
    
    handleResendOTP = event => {
        const phoneNumber = this.props.location.search.replace("?enterOtpFor=", "");
        event.preventDefault();
        this.setState({reSendingOtp: true, sendingOtp: false}, () => {
            this.props.onSendVerificationText(phoneNumber);
        });
    }

    render() {
        const phoneNumber = this.props.location.search.replace("?enterOtpFor=", "");
        return (
            <div className={cx('authScene confirmScene layout center')}>
                <form className={cx('form')}
                    onSubmit={this.handleSubmit}>
                        
                    <img style={{background: 'transparent'}} src='logo.png' className={cx('logoImage')} alt="" />

                    <div className={cx('formTitle')} style={{marginBottom: '1em', textTransform: 'none', letterSpacing: '0em'}}>
                        Enter Verification Code
                    </div>

                    <p style={{marginBottom: '2.5em'}}>
                        Enter the code that was sent to your phone number (<strong>{phoneNumber}</strong>)
                    </p>

                    <div style={{marginBottom: '3em'}}>
                        <FlInput
                            type="number"
                            label="Verification Code"
                            name='otpCode'
                            required
                            minLength="4"
                            maxLength="4"
                            textCenter
                            style={{width: '250px', margin: '0 auto 2.2em auto'}}
                            onChange={this.handleChange}
                            errors={this.state.validationErrors.otpCode} />
                    
                        <div style={{width: '180px', margin: 'auto'}}>
                            <FlButton type="submit" primary
                                block
                                loading={this.props.saving && !this.state.reSendingOtp}
                                disabled={this.props.saving && this.state.reSendingOtp}>
                                Submit
                            </FlButton>
                        </div>
                    </div>


                    <p style={{marginBottom: '1.4em'}}>
                        Didn't receive verification code?
                    </p>

                    <div className="layout center-justified">
                        <FlButton style={{background: '#3d3d3d'}} type="button" inverse
                            disabled={this.props.saving && this.state.sendingOtp}
                            loading={this.props.saving && !this.state.sendingOtp}
                            onClick={this.handleResendOTP}>
                            Resend Code
                        </FlButton>
                    </div>

                    <div className="siblingPageLink layout center" style={{marginTop: '2.5em'}}>
                        <FlButton flat accent onClick={this.props.onRedirectToLogin}>Back To Login</FlButton>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state){
    return {
        saving: state.app.formLoading,
        user: state.auth.user
    }
}

function mapDispatchToProps(dispatch){
    return {
        onGetVerificationTokenFromOtp: (otp, phoneNumber) => {
            dispatch(getResetTokenFromOTP(otp, phoneNumber))
        },
        onSendVerificationText: (phoneNumber) => {
            dispatch(sendVerificationText(phoneNumber))
        },
        onRedirectToLogin: () => {
            dispatch(replace('/login'));
        },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(canNotify(EnterOtp)));