import React from 'react';
import _flatten from 'lodash/flatten';
import { canNotify } from '../../../../components/FlNotifications';
import FormValidator from '../../../../services/FormValidator';

import './styles.scss';
import { FlRadioButtonGroup, FlInput, FlCheckbox } from '../../../../components/FormControls';
import FlButton from '../../../../components/FlButton';
import { connect } from 'react-redux';
import { createAccount, editAccount } from '../../../../store/actions/account';

class AddAccount extends React.Component {
    state = {
        name: '',
        type: 'savings',
        currency: 'TZS',
        amount: '',
        interval: 'monthly',
        day: '',
        acceptedTerms: false,
        infoIsReal: false,
        validationErrors: {},
        initialValidation: false,
    };

    validator = new FormValidator({
        name: 'min:4',
        amount: 'required',
        day: 'required'
    });

    componentDidMount(){
        const { accountToEdit } = this.props;

        if(accountToEdit){
            let { _id, name, amount, day } = accountToEdit;
            if(!amount)
                amount = 0;

            this.setState({...this.state, _id, name, amount, day});
        }
    }

    validateForm = async () => {
        const validationErrors = await this.validator.validate(this.state);
        this.setState({validationErrors, initialValidation: true});
        const firstInvalidInput = Object.keys(validationErrors)[0];
        
        //get the first input that is not a radio or checkbox
        const exemptions = ':not([type="radio"]):not([type="checkbox"])';
        const inputNode = document.querySelector(`[name=${firstInvalidInput}]${exemptions}`);

        return [_flatten(Object.values(validationErrors)), inputNode];
    }

    handleChange = event => {
        const { name, type, checked, value } = event.target;
        this.setState({ [name]: type === 'checkbox' ? checked : value }, 
            () => this.state.initialValidation ? this.validateForm() : null
        );
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const [errors, inputNode] = await this.validateForm();
    
        if(errors.length){
            if(inputNode)
                inputNode.focus();

            return this.props.Toast('Form has some errors');
        }

        const editting = this.props.accountToEdit !== null;
        if(!editting && !this.state.acceptedTerms)
          return this.props.Alert(null, 'Please accept terms and conditions.');
        
        if(!editting && !this.state.infoIsReal)
          return this.props.Alert(null, 'Please confirm that you provided real information.');

        let accountData = {...this.state};
        delete accountData.acceptedTerms;
        delete accountData.infoIsReal;
        delete accountData.validationErrors;
        delete accountData.initialValidation;

        const { onCreateAccount, onEditAccount, accountToEdit } = this.props;
        if(accountToEdit){
            onEditAccount(accountData, accountToEdit._id);
        }else{
            onCreateAccount(accountData);
        }
    }

    render() { 
        const { 
            name,
            amount,
            interval,
            day,
            acceptedTerms,
            infoIsReal,
            validationErrors
        } = this.state;

        const intervalOptions = [
            { label: 'Monthly', value: 'monthly' }
        ];
        
        const dayOptions = [
            { label: 'First Day', value: 'firstday' },
            { label: 'Last Day', value: 'lastday' }
        ];
        
        const editting = this.props.accountToEdit !== null;

        return ( 
            <form className={'addAcountForm'} onSubmit={this.handleSubmit}>
                <FlInput
                    autoFocus
                    type="text"
                    label="Account Name"
                    hint="purpose for account"
                    name='name'
                    value={name} 
                    onChange={this.handleChange}
                    errors={validationErrors.name} />
                
                <FlInput
                    type="number"
                    label="Deposit Amount"
                    name='amount'
                    value={amount} 
                    onChange={this.handleChange}
                    errors={validationErrors.amount}>

                    <span>TZS</span>
                </FlInput>

                <FlRadioButtonGroup
                    label="Deposit Schedule"
                    name='interval'
                    value={interval}
                    options={intervalOptions}
                    onChange={this.handleChange} />
                
                <FlRadioButtonGroup
                    label="Deposit Day"
                    name='day'
                    value={day}
                    options={dayOptions}
                    onChange={this.handleChange}
                    errors={validationErrors.day} />

                { !editting && 
                    <React.Fragment>
                        <FlCheckbox
                            name='acceptedTerms'
                            checked={acceptedTerms} 
                            onChange={this.handleChange}>

                            I authorize Finlink and it's partners to access my credit bureau records
                            and any other information available publicly onto which access has been
                            provided by me.

                        </FlCheckbox>
                        
                        <FlCheckbox
                            name='infoIsReal'
                            checked={infoIsReal} 
                            onChange={this.handleChange}>

                            I certify that I am a resident of Tanzania and all the
                            information pertaining to this application is true to the best
                            of my knowledge.

                        </FlCheckbox>
                    </React.Fragment>
                }

                <FlButton type="submit" block primary
                        loading={this.props.saving}>
                    { editting ? 'SAVE CHANGES' : 'CREATE ACCOUNT' }
                </FlButton>
            </form>
        );
    }
}

function mapStateToProps(state){
    return {
        accountToEdit: state.account.currentAccount,
        saving: state.app.formLoading
    }
}

function mapDispatchToProps(dispatch){
    return {
        onCreateAccount: (account) => {
            dispatch( createAccount(account) )
        },
        onEditAccount: (account, accountId) => {
            dispatch( editAccount(account, accountId) )
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(canNotify(AddAccount));