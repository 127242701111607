import React, { useState, useRef, useEffect } from 'react';
import { withRouter, Route } from 'react-router';

import FlButton from '../../../../../components/FlButton';
import { fetchGuarantor } from '../../../../../services/API';

import { FlInput } from '../../../../../components/FormControls';
import FlModal from '../../../../../components/FlModal';
import FlAlert from '../../../../../components/FlAlert';
import FormValidator from '../../../../../services/FormValidator';
import { makePhoneNumberValid } from '../../../../../utils';

const AddLoanGuarantor = ({history, location, addedGuarantors, onGuarantorFetched}) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [fetchGuarantorState, setFetchGuarantorState] = useState("idle");
    const [fetchGuarantorError, setFetchGuarantorError] = useState("");
    const [phoneNumberValidationErrors, setPhoneNumberValidationErrors] = useState([]);
    const [initialValidationTriggered, setInitialValidationTriggered] = useState(false);
    const phoneInputRef = useRef(null);
    const showBackButton = location.search && location.search.indexOf("withBack") !== -1;


    let addGuarantorErrorMessage = fetchGuarantorError || "";
    if(!addGuarantorErrorMessage && phoneNumberValidationErrors && phoneNumberValidationErrors.length)
        addGuarantorErrorMessage = phoneNumberValidationErrors[0];

    useEffect(() => {
        focusPhoneNumberInput();
    }, []);
    
    useEffect(() => {
        if(initialValidationTriggered)
            validatePhoneNumber(phoneNumber);
    }, [phoneNumber]);

    async function validatePhoneNumber(phone = phoneNumber){
        if(!initialValidationTriggered)
            setInitialValidationTriggered(true);

        const validator = new FormValidator({
            phoneNumber: 'phone'
        });
        const validationErrors = await validator.validate({phoneNumber: phone});
        const addedGuarantorPhones = addedGuarantors.map(({phoneNumber}) => phoneNumber);

        if(Object.keys(validationErrors).length > 0){
            setPhoneNumberValidationErrors(Object.values(validationErrors)[0]);
            return false;
        }
        else{
            setPhoneNumberValidationErrors([]);
            if(addedGuarantorPhones.includes(makePhoneNumberValid(phoneNumber))){
                setFetchGuarantorError(`Guarantor with phone number <strong>${makePhoneNumberValid(phoneNumber)}</strong> already added.`);
                return false;
            }
        }
            

        return true;
    }
    
    const closeErrorAlert = () => {
        history.goBack();
        focusPhoneNumberInput();
    }
    
    const focusPhoneNumberInput = () => {
        if(phoneInputRef && phoneInputRef.current)
            phoneInputRef.current.focus();
    }

    async function handleFetchGuarantor(e){
        if(e) e.preventDefault();

        const guarantorPhoneNumberIsValid = await validatePhoneNumber();
        if(!guarantorPhoneNumberIsValid){
            history.push('/main/requestLoan/addGuarantor/error');
            return;
        }

        try {
            setFetchGuarantorState("fetching");
            const validPhone = makePhoneNumberValid(phoneNumber);
            let guarantor = await fetchGuarantor(validPhone.replace("+255", "0"));
            guarantor.phoneNumber = validPhone;
            onGuarantorFetched(guarantor);
            setFetchGuarantorState("idle");
            history.replace('/main/requestLoan/');
        } catch (error) {
            if(error.message !== "You're offline!"){
                if(error.response && error.response.data && error.response.data.developerMessage === "Gurantor not found!")
                    setFetchGuarantorError("The number you entered doesn't belong an existing Finlink user.");
                else
                    setFetchGuarantorError("Failed to fetch guarantor.");

                history.push('/main/requestLoan/addGuarantor/error');
            }

            setFetchGuarantorState("idle");
        }
    }

    return (
        <div id="AddLoanGuarantor">
            <FlModal pageTitle="Add Loan Guarantor"
                onBack={!showBackButton ? null : () => history.goBack()}>
                <form className="RequestLoanSection" 
                    onSubmit={handleFetchGuarantor} >
                    <h3 className="loan-request-section-title">
                        What's your guarantor's <br/>phone number?
                    </h3>
                    <FlInput
                        required
                        type="tel"
                        placeholder="E.g. 719XXXXXX"
                        value={phoneNumber}
                        inputRef={phoneInputRef}
                        onChange={({target}) => setPhoneNumber(target.value)}
                        errors={phoneNumberValidationErrors}>

                        <span>+255</span>    
                    </FlInput>

                    <FlButton primary
                        type="submit"
                        block 
                        loading={fetchGuarantorState === "fetching"}
                    >
                        Continue
                    </FlButton>
                </form>
            </FlModal>

            <Route path='/main/requestLoan/addGuarantor/error' render={() => (
                <FlAlert
                    message={addGuarantorErrorMessage}
                    onOkay={() => closeErrorAlert()}
                />
            )} />
        </div>
    );
}
 
export default withRouter(AddLoanGuarantor);