import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import _find from 'lodash/find';
import _filter from 'lodash/filter';

import HashRoute from '../../Router/HashRoute';
import { setCurrentAccount } from '../../store/actions/account';

// import Settings from "./Scenes/Settings";
import Home from "./Scenes/Home";
import AccountDetail from "./Scenes/AccountDetail";
import Notifications from "./Scenes/Notifications";
import Profile from "./Scenes/Profile";
import EditProfile from './Scenes/EditProfile';
import Activity from './Scenes/Activity';
import BottomNav from "./components/BottomNav";
import DepositModal from './Scenes/DepositModal';
import WithdrawModal from './Scenes/WithdrawModal';
import TransferModal from './Scenes/TransferModal';
import RequestLoan from './Scenes/RequestLoan';
import LoanRequests from './Scenes/LoanRequests';
// import LoanDetail from './Scenes/LoanDetail';
import GuarantorPool from './Scenes/GuarantorPool';

import classnames from 'classnames/bind';
import styles from './styles.scss';
import { setRecepientAccounts } from '../../store/actions/transfer-modal';
import { setDepositer } from '../../store/actions/deposit-modal';

const cx = classnames.bind(styles);

const Main = (props) => {
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        props.history.listen((location, action) => {
            const landedOnMain = location.pathname === '/main' && !location.search.length && !location.hash.length;
            if(landedOnMain && action === 'POP')
                handleModalClosing();
        });
    }, [])

    async function changePage(page){
        const { accounts, currentAccount, onSetCurrentAccount } = props;
        
        if(!accounts || !accounts.length)
            return;
        
        setExpanded(true);
        await new Promise(resolve => setTimeout(resolve, 100));

        const mainAccount = _find(accounts, (a) => a.type.toLowerCase() === 'main');
        
        if(!currentAccount || (currentAccount && currentAccount._id !== mainAccount._id))
            onSetCurrentAccount(mainAccount._id);

        if(page === "TransferMoney"){
            setRecipientAccounts(mainAccount._id);
        }
        
        if(page === "accountDetail" || page === "activity"){
            props.history.push('/main/' + page);
            return;
        }

        props.history.push({hash: '#finlinkModal/' + page});
    }

    function setRecipientAccounts(accountId){
        const { accounts, onSetRecepientAccounts } = props;
        let recepientAccounts = _filter(accounts, account => account._id !== accountId)
        recepientAccounts.map(account => {
            account.transferAmount = 0;
            return account;
        });
    
        onSetRecepientAccounts(recepientAccounts);
    }

    async function handleModalClosing(){
        await new Promise(resolve => setTimeout(resolve, 80));
        setExpanded(false);

        props.onSetCurrentAccount(-1);
        props.onSetRecepientAccounts([]);
    }

    const { accounts, fetchingAccounts, location } = props;
    
    let subpage = location.pathname.replace('/main', '').replace('/', '');
    subpage = subpage.length ? subpage : 'home';
    
    return ( 
        <React.Fragment>
            <div className={cx('mainScene', {'at-home': subpage === 'home'})}>
                <Switch>
                    <Route exact path='/main' component={Home} />
                    <Route path='/main/account/:id' component={AccountDetail} />
                    <Route path='/main/activity' component={Activity} />
                    <Route path='/main/notifications' component={Notifications} />
                    <Route exact path='/main/profile' component={Profile} />
                    <Route exact path='/main/editProfile' component={EditProfile} />
                    <Route path='/main/requestLoan' component={RequestLoan} />
                    <Route path='/main/loanRequests' component={LoanRequests} />
                    {/* <Route path='/main/loan/:loanId' component={LoanDetail} /> */}
                    <Route path='/main/guarantor-pool' component={GuarantorPool} />
                </Switch>

                {/* <Switch>
                    <Route exact path='/main/settings' component={Settings} />
                </Switch>
                */}

                <HashRoute path="#finlinkModal/YourActivity" component={Activity} />
                <HashRoute path="#finlinkModal/TransferMoney" component={TransferModal} />
                <HashRoute path="#finlinkModal/DepositMoney" component={DepositModal}
                        onBeforeClose={props.onUnsetDepositor} />
                <HashRoute path="#finlinkModal/WithdrawMoney" component={WithdrawModal}
                        onBeforeClose={props.onUnsetDepositor} />

                <BottomNav visible={!fetchingAccounts && accounts && accounts.length} expanded={expanded} page={subpage} onNavigate={changePage} />
            </div>
        </React.Fragment>
    );
}
 
function mapStateToProps(state){
    return {
        fetchingAccounts: state.account.fetchingAccounts,
        accounts: state.account.accounts,
        currentAccount: state.account.currentAccount,
    }
}

function mapDispatchToProps(dispatch){
    return {
        onSetCurrentAccount: (id) => {
            dispatch(setCurrentAccount(id))
        },
        onSetRecepientAccounts: (recepientAccounts) => {
            dispatch(setRecepientAccounts(recepientAccounts))
        },
        onUnsetDepositor: () => {
            dispatch(setDepositer(null))
        },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Main);