import React from 'react';
import classnames from 'classnames/bind';
import { canNotify } from '../../../../../components/FlNotifications';
import FlButton from '../../../../../components/FlButton';

import styles from './styles.scss';
import SearchRoute from '../../../../../Router/SearchRoute';
import FlDialpad from '../../../components/FlDialpad';
import AccountPicker from '../AccountPicker';
import AccountChoiceItem from './AccountChoiceItem';
const cx = classnames.bind(styles);

const TransferToAcccounts = (props) => {
    const {
        accounts, 
        recepientAccounts, 
        currentAccount, 
        saving, 
        history, 
        onAmountChanged, 
        onRecepientSelected, 
        onSetRecepientAccounts, 
        onSetCurrentAccount
    } = props;
    
    let validRecepients = [];
    if(recepientAccounts && recepientAccounts.length)
        validRecepients = recepientAccounts.filter(a => a.transferAmount > 0);

    const handleRecepientSelected = (selectedAccountIndex) => {
        onRecepientSelected(selectedAccountIndex);
        showNumberPad(selectedAccountIndex);
    }
    
    const handleRecepientRemoved = (selectedAccountIndex) => {
        onRecepientSelected(selectedAccountIndex);
        onAmountChanged(0);
    }
    
    const setAmount = (amount) => {
        onAmountChanged(amount);
        closeNumberPad();
    }
    
    const closeNumberPad = () => {
        history.goBack();
    }
    
    const showNumberPad = (selectedAccountIndex) => {
        history.push({
            hash: '#finlinkModal/TransferMoney',
            search: '?enteringAmountFor=' + selectedAccountIndex
        });
    }

    const handlePickSender = () => {
        history.push({
            hash: '#finlinkModal/TransferMoney',
            search: '?pickSender'
        })
    }

    const handleChangeSender = accountId => {
        onSetCurrentAccount(accountId);
        const newRecepients = accounts.filter(({_id}) => _id !== accountId)
                            .map(account => {
                                account.transferAmount = 0;
                                return account;
                            });
    
        onSetRecepientAccounts(newRecepients);
        closeNumberPad();
    }

    const balanceIsEnough = (transferAmount) => {
        const accountBalance = currentAccount.availableBalance;
        if(Number(accountBalance) < Number(transferAmount)){
            let overBalanceMessage = validRecepients.length > 1 ? 'Total transfer ' : 'Transfer ';
            overBalanceMessage += `amount (${transferAmount.toLocaleString()}) exceeds account balance (${accountBalance.toLocaleString()}).`;

            return props.Alert(null, overBalanceMessage);
        }

        return true;
    }

    const handleSubmit = event => {
        event.preventDefault();

        const { recepientAccounts, currentAccount, onMakeTransaction } = props;
        const recepients = recepientAccounts.filter(account => 
            account.transferAmount !== 0
        );
        if(!recepients.length)
            return props.Alert(null, "Please choose at least one recepient.");
        
        const transferAmount = recepients.reduce((total, account) => 
            total + account.transferAmount, 
            0
        );

        if(balanceIsEnough(transferAmount)){
            const transfers = recepients.map(a => ({
                toAccountName: a.name, 
                toAccountId: a._id, 
                amount: a.transferAmount
            }));
            
            onMakeTransaction({ transfers }, currentAccount._id);
        }
    }

    
    return ( 
        <React.Fragment>
            <form className={cx('transferToAccounts')} onSubmit={handleSubmit}>
                <label className="text-center">Transfer From: (tap to change)</label>
                <button type="button" className={cx('accountChoiceItem')}
                    onClick={handlePickSender}>
                    <span style={{textAlign: 'left'}} className="layout vertical flex">
                        <span>
                            {currentAccount.name}

                            { currentAccount.type && currentAccount.type.toLowerCase() === 'main' ? 
                                '(Main)' : ''
                            }
                        </span>
                        <span style={{ color: "#ababab" }}>
                            balance:&nbsp;
                            <small className="amount">
                                TZS { currentAccount.availableBalance && currentAccount.availableBalance.toLocaleString() }
                            </small>
                        </span>
                    </span>
                    
                    <svg className="circle" fill="none" stroke="currentColor" viewBox="0 0 24 24"><polyline points="6 9 12 15 18 9"></polyline></svg>
                </button>

                <label className="text-center">Transfer To:</label>

                { 
                    recepientAccounts.map((account, index) =>
                        <AccountChoiceItem 
                            key={index}
                            account={account}
                            onUnSelect={() => handleRecepientRemoved(index)}
                            onSelect={() => handleRecepientSelected(index)}
                         />
                    )
                }

                <FlButton type="submit" 
                    block primary large rounded
                    disabled={validRecepients.length < 1}
                    loading={saving}>
                    Transfer
                </FlButton>
            </form>
    
            <SearchRoute path="enteringAmountFor"
                component={({ value }) => 
                    !recepientAccounts[value] ? null : 
                    <FlDialpad 
                        amount={recepientAccounts[value].transferAmount}
                        onComplete={setAmount}
                        onCancel={closeNumberPad} />
                }
            />
            
            <SearchRoute path="pickSender" 
                component={() => 
                    <AccountPicker
                        accounts={ accounts }
                        currentAccount={ currentAccount }
                        onCancel={ closeNumberPad }
                        onComplete={ handleChangeSender } />
                } 
            />
        </React.Fragment>
    );
}
 
export default canNotify(TransferToAcccounts);