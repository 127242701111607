import React from 'react';
import './styles.scss'
import FlRadioButton from '../FlRadioButton';

const FlRadioButtonGroup = (props) => {
    const { label, name, required, errors, type = 'text', value, options, alignCenter, onChange } = props;
    const hasErrors = errors && errors.length > 0;

    let className = 'finlink-radio-button-group';
    className += hasErrors ? ' has-errors' : '';
    className += alignCenter ? ' align-center' : '';

    return ( 
        <div className="finlink-radio-button-group-wrapper">
            <div className={className}>
                <label>{ label }</label>

                <div>
                    { 
                        options.map( (option, index) => {
                            let optionLabel = option, optionValue = option;
                            if(typeof option === 'object'){
                                optionLabel = option.label;
                                optionValue = option.value;
                            }

                            return <FlRadioButton
                                key={index}
                                label={optionLabel}
                                required={required}
                                name={name}
                                checked={optionValue === value} 
                                onChange={() => onChange({ target: { name, type, value: optionValue } }) }
                            />
                        }) 
                    }
                </div>
            </div>
            
            { hasErrors &&
                <ul className="form-control-errors">
                    {
                        errors.map( (e, i) =>
                            <li key={i} className="form-control-error">
                                { e }
                            </li>
                        )
                    }
                </ul>
            }
        </div>
    );
}
 
export default FlRadioButtonGroup;