import { USER_KEY, getPrefs } from '../../services/prefs';
import { initApp } from './app';
import { authSuccess, authFails } from './auth';
import { checkUserVerification } from '../../utils';

export const bootstrapApp = () => async dispatch => {
    try {
        const user = await getPrefs(USER_KEY);
        const cardTypeSet = user.idType && user.idType !== 'NAN';
        const cardNumberSet = user.idNumber && user.idNumber !== 'NAN';
        const cardSet = cardTypeSet && cardNumberSet;

        if(!cardSet)
            user._temp = true;
            
        if (user)
            dispatch(authSuccess(user, !user._temp, checkUserVerification(user)));
        else
            dispatch(authFails());
            
        dispatch(initApp());

        return true;
    } catch (error) {
        dispatch(authFails());
        dispatch(initApp());

        return true;
    }
};