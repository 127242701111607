import React, { useEffect, useState } from 'react';
import FlAlert from '../../../../components/FlAlert';
import Loader from '../../../../components/Loader';
import FlexBox from '../../../../components/FlexBox';
import { fetchUserAccounts } from '../../../../services/API';

export default function SelectExistingPaymentCards({
    onSelect,
    onClose
}) {
    const [selectedCard, setSelectedCard] = useState(null);
    const [cardChoices, setCardChoices] = useState([]);
    const [fetchingPaymentCards, setFetchingPaymentCards] = useState(false);

    useEffect(() => {
        fetchPaymentCards();
    }, []);

    async function fetchPaymentCards(){
        setFetchingPaymentCards(true);
        const res = await fetchUserAccounts();
        try {
            const userAccounts = res.data.accounts;
            setFetchingPaymentCards(false);
            if(userAccounts.length && userAccounts[0].paymentCards && userAccounts[0].paymentCards.length){
                const cards = userAccounts[0].paymentCards.filter(({name}) => name && name.length);
                setCardChoices(cards);
                // console.log("PaymentCards ", cards[0]);

                if(cards.length == 1)
                    setSelectedCard(cards[0]);
            }
            else{
                console.log("No payment cards found.");
            }
        } catch (error) {
            setFetchingPaymentCards(false);
            console.log("Error fetching user accounts: ", error);
        }
    }

    function handleOkayClicked() {
        if(fetchingPaymentCards) 
            onClose(); 
        else
            onSelect({...selectedCard});
    }

    function onAddClicked() { }

    return (
        <FlAlert 
            onCancel={fetchingPaymentCards ? null : onClose} 
            okText={fetchingPaymentCards ? "Cancel" : "Okay"} 
            onOkay={handleOkayClicked}
        >

            {!fetchingPaymentCards &&
                <>
                    <p className="accountChoiceTitle">Pick Card</p>

                    <button type="button" className="accountChoice"
                        onClick={() => onSelect(null)}>
                        <i className="icon">
                            <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                        </i>
                        Add Payment Card
                    </button>
                </>
            }

            {
                fetchingPaymentCards && (
                    <FlexBox align="center">
                        <Loader size={40} /> &emsp; Fetching your accounts...
                    </FlexBox>
                )
            }

            {
                cardChoices && cardChoices.map(card => {
                    const selected = selectedCard && card._id === selectedCard._id;
                    return (
                        <button key={card._id} type="button"
                            className={'accountChoice ' + (selected ? 'selected' : '')}
                            onClick={() => setSelectedCard(card)}>
                            <i className="icon">
                                {selected &&
                                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                }

                                {/* {!selected &&
                                    <span>{card.name.charAt(0).toUpperCase()}</span>
                                } */}
                            </i>
                            <div>
                                {card.name}
                                <small>
                                    {card.number}
                                </small>
                            </div>
                        </button>
                    )
                })
            }

            <div style={{ marginBottom: '0.5em' }}></div>
        </FlAlert>
    );
}