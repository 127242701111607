import { SET_RECEPIENT_TYPE, SET_RECEPIENT_ACCOUNTS, SET_SELECTED_RECEPIENT, 
    SET_SELECTED_RECEPIENT_AMOUNT,
    SET_INVESTOR_TRANSFER_AMOUNT,
    SET_INVESTOR
} from '../actions/transfer-modal';

const initialState = { recepientType: 'account', recepientAccounts: [], selectedRecepient: -1, investorTransferAmount: 0, investorName: "", investorPhoneNumber: "" };
const auth = (state = initialState, action) => {
    switch (action.type) {
        case SET_RECEPIENT_TYPE:
            return {
                ...state,
                recepientType: action.recepientType
            };
        case SET_RECEPIENT_ACCOUNTS:
            return {
                ...initialState,
                recepientAccounts: action.recepientAccounts
            };
        case SET_SELECTED_RECEPIENT:
            return {
                ...state,
                selectedRecepient: action.selectedRecepient
            };
        case SET_SELECTED_RECEPIENT_AMOUNT:{
            const recepientAccounts = state.recepientAccounts.map((account, index) => {
                if(index === state.selectedRecepient)
                    account.transferAmount = action.selectedRecepientAmount;
                    
                return account;
            });

            return {
                ...state,
                recepientAccounts,
                selectedRecepient: -1
            };
        }
        case SET_INVESTOR:{
            return {
                ...state,
                investorName: action.investor.name,
                investorPhoneNumber: action.investor.phoneNumber
            };
        }
        case SET_INVESTOR_TRANSFER_AMOUNT:{
            return {
                ...state,
                investorTransferAmount: action.amount
            };
        }
        default:
            return state;
    }
};

export default auth;
