import React from 'react';
import FlexBox from '../../../../components/FlexBox';
import Spacer from '../../../../components/Spacer';

const AccountTransaction = ({ transaction }) => {
    transaction.currency = transaction.currency || "TZS";
    transaction.to = transaction.toAccount ? transaction.toAccount.name : "";
    transaction.from = transaction.fromExternalAccount || "";
    if(transaction.fromAccount)
        transaction.from = transaction.fromAccount.name;

    let otherAccount = transaction.from.toLowerCase();

    if(transaction.type === "commission")
        otherAccount = "Credit Interests";
    else if(transaction.type === "interest")
        otherAccount = "Debit Interest";

    return (
        <div className={'transactionItem for-account ' + transaction.type + ( transaction.amount  < 0 ? ' transfer-out' : '')}>
            
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline></svg>
                
            <div>
                <h3>{ transaction.currency.toUpperCase() } { transaction.amount.toLocaleString() }</h3>
                <span>
                    { otherAccount }
                    {/* <FlexBox align="center">
                        <FlexBox align="center">
                            {transaction.type} 
                            <Spacer width="8" />
                            { transaction.amount > 0 || transaction.type.toLowerCase() === 'investment' ? 'From' : 'To' }
                        </FlexBox>
                        <Spacer width="8" />
                        { otherAccount }
                    </FlexBox> */}
                </span>
            </div>

            <small>{ transaction.date }</small>
        </div>
    );
}
 
export default AccountTransaction;