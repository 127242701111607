import React, { useEffect } from 'react';
import queryString from 'query-string';
import { withRouter, Redirect } from 'react-router';
import { connect } from "react-redux";
import { replace } from 'react-router-redux';
import { transferFunds, setCurrentAccount, transferFundsToInvestor } from '../../../../store/actions/account';
import { setSelectedRecepient, setSelectedRecepientAmount, setRecepientAccounts, setRecepientType, setInvestorTransferAmount, setInvestor } from '../../../../store/actions/transfer-modal';

import RecepientTypePicker from './RecepientTypePicker';
import TransferToAcccounts from './TransferToAcccounts';
import TransferToInvestor from './TransferToInvestor';
import TransferTo from './TransferTo';

import classnames from 'classnames/bind';
import styles from './styles.scss';

const cx = classnames.bind(styles);

const TransferModal = (props) => {
    let searchQuery = queryString.parse(props.location.search);

    function handleTransferTo(transferTo){
        props.history.replace({
            pathname: '/main',
            hash: '#finlinkModal/TransferMoney',
            search: '?to='+transferTo
        });
    }

    // useEffect(() => {
    //     const { accounts, currentAccount, onRedirectToHome } = props;
    //     if(!currentAccount || !currentAccount._id)
    //         onRedirectToHome();
    //     else if(searchQuery.to === "accounts" && (!accounts || !accounts.length))
    //         onRedirectToHome();
    // });

    const search = props.history.location.search;
    const toAccountsOnly = search && search.indexOf("toAccountsOnly") !== -1;

    const { accounts, currentAccount } = props;
    
    if(!currentAccount || !currentAccount._id)
        return <Redirect to="/" />
    else if(props.recepientType === 'account' && (!accounts || !accounts.length))
        return <Redirect to="/" />
    
    return ( 
        <div className={cx('TransferModal')}>
            <div className={cx('form')}>
                <div className={cx('logoImage circle')}>
                    <svg style={{fill: 'transparent'}} viewBox="0 0 24 24" fill="transparent" stroke="#777" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>
                </div>
                
                <div className={cx('formTitle')}>
                    Transfer Money To 
                    {/* <br/> { searchQuery.to === "accounts" ? 'Your Accounts' : 'Finlink Investor' } */}
                </div>

                { !toAccountsOnly  &&
                    <RecepientTypePicker
                        selectedType={props.recepientType}
                        onTypeSelected={props.onSetRecepientType}
                    />
                }

                { (toAccountsOnly || props.recepientType === 'account') &&
                    <TransferToAcccounts 
                        {...props}
                        onAmountChanged={props.onTransferAmountChanged}
                        onRecepientSelected={props.onRecepientAccountSelected}
                        onSetRecepientAccounts={props.onSetRecepientAccounts}
                        onSetCurrentAccount={props.onSetCurrentAccount}
                        onMakeTransaction={props.transferToAccounts}
                    />
                }

                { !toAccountsOnly && props.recepientType == 'investor' &&
                    <TransferToInvestor
                        {...props}
                        transferAmount={props.investorTransferAmount}
                        investorPhoneNumber={props.investorPhoneNumber}
                        onAmountChanged={props.setInvestorAmount}
                        onSetInvestor={props.onSetInvestor}
                        onMakeTransaction={props.transferToInvestor}
                    />
                }
            </div>
        </div>
    );
}


function mapDispatchToProps(dispatch){
    return {
        onRedirectToHome: () => {
            dispatch(replace('/'));
        },
        onSetCurrentAccount: (id) => {
            dispatch(setCurrentAccount(id))
        },
        onSetRecepientType: (recepientType) => {
            dispatch(setRecepientType(recepientType))
        },
        onSetRecepientAccounts: (recepientAccounts) => {
            dispatch(setRecepientAccounts(recepientAccounts))
        },
        onRecepientAccountSelected: (selectedAccountIndex) => {
            dispatch(setSelectedRecepient(selectedAccountIndex));
        },
        onTransferAmountChanged: (amount) => {
            dispatch(setSelectedRecepientAmount(amount));
        },
        transferToAccounts: (data, accountId) => {
            dispatch(transferFunds(data, accountId));
        },
        transferToInvestor: (accountId, data) => {
            dispatch(transferFundsToInvestor(accountId, data));
        },
        onSetInvestor(investor){
            dispatch(setInvestor(investor));
        },
        setInvestorAmount(amount){
            dispatch(setInvestorTransferAmount(amount));
        }
    }
}

function mapStateToProps(state){
    return {
        recepientType : state.transferModal.recepientType,
        recepientAccounts : state.transferModal.recepientAccounts,
        selectedAccountIndex : state.transferModal.selectedRecepient,
        investorName: state.transferModal.investorName,
        investorPhoneNumber: state.transferModal.investorPhoneNumber,
        investorTransferAmount: state.transferModal.investorTransferAmount,
        accounts: state.account.accounts,
        currentAccount: state.account.currentAccount || {},
        saving: state.app.formLoading,
        authUser: state.auth.user
    }
}

 
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TransferModal));