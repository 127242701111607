import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Redirect } from 'react-router-dom';

class PrivateRoute extends React.Component {
  render() {
    const { user, idCardIsSet, verified, component: Component, ...props } = this.props;

    if (user && idCardIsSet && verified) {
      return (
        <Route {...props}
          render={ props => <Component {...props} /> }
        />
      );
    } 
    else {
      // store the destination route on localstorage
      localStorage.setItem('finlink-from', JSON.stringify(props.location));

      let pathname = '/login';
      if(user)
        pathname = !idCardIsSet ? '/confirm-identity' : '/not-verified';

      const to = { pathname }

      if(props.path !== pathname){
        return (
          <Route
            {...props}
            render={() => (
              <Redirect to={to} />
            )}
          />
        );
      }else{
        return (
          <Route {...props}
            render={ props => <Component {...props} /> }
          />
        )
      }
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    idCardIsSet: state.auth.idCardIsSet,
    verified: state.auth.verified,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
