import React from 'react';
import { formatCurrency } from '../../utils';
import FlInput from './FlInput';

function maskFormatter(value, mask, showDashes){
    if(!value || !value.length)
        return value;
        
    if(!mask || !mask.length)
        return value;

    if(mask === "currency" && value && value.length){
        // return Number(value).toLocaleString();
        return formatCurrency(value);
    }

    let i = 0;
    let lastReplacedIndex = -1;
    const maskedValue = mask.replace(/#/g, (_, replacedIndex) => {
        if(i >= value.length)
            return "_";
        
        lastReplacedIndex = replacedIndex;
        return value[i++];
    });

    if(!showDashes)
        return maskedValue.substring(0, lastReplacedIndex + 1);
    else
        return value.length > 0 ? maskedValue : '';
}

const FlMaskedInput = ({mask, value, onChange, ...props}) => {
    function handleOnChange(e){
        let val = e.target.value;
        if(mask && mask.length){
            val = val.replace(/,/g, '');
            val = val.replace(/#/g, '');
            val = val.replace(/_/g, '');

            e.target.value = val;
        }
        
        // if(mask && mask.length)
        //     e.target.value = val.replace(/[^\d]/g, '');
        onChange(e);
    }

    return (
        <FlInput
            {...props}
            value={maskFormatter(value, mask)}
            onChange={handleOnChange}
        />
    );
};
 
export default FlMaskedInput;