import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import _flatten from 'lodash/flatten';

import { canNotify } from '../../../components/FlNotifications';
import FormValidator from '../../../services/FormValidator';

import classnames from 'classnames/bind';
import styles from '../styles.scss';

import { FlInput } from '../../../components/FormControls';
import FlButton from '../../../components/FlButton';
import { sendResetPasswordOTP } from '../../../store/actions/auth';
import { makePhoneNumberValid } from '../../../utils';

const cx = classnames.bind(styles);

class ForgotPasswordEmail extends React.Component {
    state = {
        phoneNumber: '',
        validationErrors: {},
        initialValidation: false
    }

    validator = new FormValidator({
        phoneNumber: 'min:9',
    });

    validateForm = async () => {
        const validationErrors = await this.validator.validate(this.state);
        this.setState({validationErrors, initialValidation: true});

        return _flatten(Object.values(validationErrors));
    }

    handleChange = event => {
        const { value } = event.target;
        this.setState({ phoneNumber: value }, 
          () => this.state.initialValidation ? this.validateForm() : null
        );
    }

    handleSubmit = async event => {
        event.preventDefault();
        const errors = await this.validateForm();
    
        if(errors.length){
            document.querySelector("[name=phoneNumber]").focus();
            return this.props.Toast('Invalid Phone Number');
        }

        this.props.onSendResetOTP(makePhoneNumberValid(this.state.phoneNumber));
    }

    render() {
        const {
            phoneNumber,
            validationErrors
        } = this.state;

        return ( 
            <div className={cx('authScene forgotScene')}>
                <form className={cx('form')}
                    onSubmit={this.handleSubmit}>

                    <img src='logo.png' className={cx('logoImage')} alt="" />

                    <div className={cx('formTitle')}>
                        Forgot Password
                    </div>

                    <p style={{ color: '#9f9f9f', marginBottom: '2.8em'}}>
                        Enter your phone number below and you'll receive a verification token to reset your password.
                    </p>

                    <FlInput
                        type="number"
                        label="Phone Numer"
                        name='phoneNumber' 
                        value={phoneNumber} 
                        onChange={this.handleChange}
                        errors={validationErrors.phoneNumber}
                        placeholder="E.g. 0781900211">

                        <span>+255</span>
                    </FlInput>

                    <FlButton type="submit"
                        block primary large
                        loading={this.props.saving}>
                        SUBMIT
                    </FlButton>

                    <div className="siblingPageLink" style={{flexDirection: 'column'}}>
                        <span className="text-center" style={{marginBottom: '1.2em', lineHeight: '1.4em'}}>
                            Can't remember your phone number?
                        </span>
                        <Link to={{ search: 'withEmail=true' }} style={{textTransform: '', color: '#ddd'}}>
                            Use email instead
                        </Link>
                    </div>

                    <div className="siblingPageLink" style={ { marginTop: '3em' } }>
                        <Link to="/login">Back to Login</Link>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state){
    return {
        saving: state.app.formLoading
    }
}

function mapDispatchToProps(dispatch){
    return {
        onSendResetOTP: (phoneNumber) => {
            dispatch(sendResetPasswordOTP(phoneNumber))
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(canNotify(ForgotPasswordEmail));