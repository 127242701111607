import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Redirect } from 'react-router';

import FlAlert from '../../../../../components/FlAlert';
import Loader from '../../../../../components/Loader';
import NavBar from '../../../components/NavBar';
import FlButton from '../../../../../components/FlButton';

import './styles.scss';

const GuaranteeDetail = ({history, location, currentGuaranteeRequest, onRejectClicked, onIgnoreClicked, onGuaranteeClicked}) => {
    if(!currentGuaranteeRequest || !currentGuaranteeRequest.applicant)
        return <Redirect to="/main/guarantor-pool/" />;

    const validGuarantors = currentGuaranteeRequest.guarantors.filter(({status}) => status === "guaranteed" || status === "guranteed");
    const guaranteedAmount = validGuarantors.reduce((sum, {amount}) => sum + amount, 0);
    const pendingAmount = currentGuaranteeRequest.amount - guaranteedAmount;

    return (
        <React.Fragment>
            <div id="GuaranteeDetail" className="layout vertical">
                <div>
                    <NavBar 
                        title={!currentGuaranteeRequest ? "Loan Request Details" : ""}
                        noborder
                        notFixed
                        onGoBack={history.goBack}
                    />
                </div>

                { !currentGuaranteeRequest && (
                    <div style={{height: 150}} className="layout center-center">
                        <Loader size={80} color="#888" />
                    </div>
                )}

                {
                    currentGuaranteeRequest && (
                        <div id="GuaranteeDetailContent" className="flex">
                            <div key={currentGuaranteeRequest._id} className={`guarantee-request-details posr-r ${currentGuaranteeRequest.status}`}>
                                <div className="detail-text layout center justified"
                                    style={{marginBottom: 0}}>
                                    <label style={{marginBottom: "-0.33rem"}}>Requestor:</label>
                                    <strong>
                                        { currentGuaranteeRequest.applicant.displayName }
                                    </strong>
                                </div>
                                <div id="guaranteeSummary">

                                    <div className="detail-text">
                                        <label>Total Requested</label>
                                        <strong>
                                            <small>TZS</small> { currentGuaranteeRequest.amount.toLocaleString() }
                                        </strong>
                                    </div>
                                    
                                    <div className="detail-text">
                                        <label>Pending Amount</label>

                                        <strong className="pending-amount">
                                            <small>TZS</small>
                                            { pendingAmount.toLocaleString() }
                                        </strong>
                                    </div>
                                </div>

                                <div className="detail-text">
                                    <label>Confirmed Guarantors</label>
                                    { (!validGuarantors || !validGuarantors.length) && (
                                        <p id="noGuarantorsMessage">Be the first to guarantee this loan request.</p>
                                    )}

                                    {
                                        validGuarantors.map(({user, amount}, index) => (
                                            <div key={index} className="guarantor-item">
                                                <div className="ListItem">
                                                    <div className="icon">
                                                        <svg fill="#a2a2a2" viewBox="0 0 24 24">
                                                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="text">
                                                        <h5 className="layout">
                                                            {user.firstName} {user.lastName} 
                                                            <span>TZS {amount.toLocaleString()}</span>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }
                
                <div id="loanGuaranteeActions" className="layout center justified">
                    { currentGuaranteeRequest.type === "direct" &&
                        <FlButton className="reject" flat rounded
                            disabled={!pendingAmount}
                            loading={currentGuaranteeRequest.saving}
                            onClick={onRejectClicked}>
                            Reject
                        </FlButton>
                    }
                    
                    { currentGuaranteeRequest.type === "other" &&
                        <FlButton flat rounded
                            disabled={!pendingAmount}
                            onClick={onIgnoreClicked}>
                            Ignore
                        </FlButton>
                    }
                    
                    <FlButton rounded primary
                        disabled={!pendingAmount || currentGuaranteeRequest.saving}
                        onClick={onGuaranteeClicked}>
                        Guarantee
                    </FlButton>
                </div>
            </div>

            { location.pathname.indexOf("no-guarantee-balance") !== -1 &&
                <FlAlert
                    message="You don't have enough balance to guarantee this loan."
                    onOkay={history.goBack}
                />
            }
        </React.Fragment>
    );
}
 
function mapStateToProps(state){
    return {
        currentGuaranteeRequest: state.loan.currentGuaranteeRequest
    }
}

function mapDispatchToProps(dispatch){
    return {
        setGuaranteeAmount: () => {
            dispatch(push('/main/guarantor-pool/add-amount'))
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(GuaranteeDetail);