import React from 'react';
import { withRouter } from "react-router";
import FlButton from '../FlButton';

import classnames from 'classnames/bind';
import styles from './styles.scss';

const cx = classnames.bind(styles);


class FlModal extends React.Component {
    state = { opened: false }
    
    componentDidMount(){
        this.setState({opened: true});
    }

    handleClose = () => {
        if(this.props.onBeforeClose){
            this.props.onBeforeClose();
        }
        
        if(this.props.onGoback)
            this.props.onGoback();
        else
            this.props.history.goBack()
    }
    
    render() { 
        const { opened } = this.state;
        const { pageTitle, noClose, saving, onSave, onBack } = this.props;
        return ( 
            <div id={pageTitle.replace(/\s/g, '')} className={cx('finlink-modal-wrapper', { 'opened': opened })}>
                <div className="finlink-modal">
                    <div className="finlink-modal-header">
                        <div className="layout center">
                            { onSave &&
                                <React.Fragment>
                                    <FlButton id="closeBtn" action onClick={this.handleClose}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                                    </FlButton>
                                </React.Fragment>
                            }
                            
                            { typeof onBack === 'function' &&
                                <React.Fragment>
                                    <FlButton id="closeBtn" action onClick={this.handleClose}>
                                        <svg className="stroked" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                                    </FlButton>
                                </React.Fragment>
                            }

                            <span className="finlink-modal-title">{ pageTitle }</span>
                        </div>

                        { !onSave && !noClose && typeof onBack !== 'function' &&
                            <FlButton action onClick={this.handleClose}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                            </FlButton>
                        }
                        
                        { onSave &&
                            <FlButton id="saveBtn" rounded primary loading={saving} onClick={onSave}>
                                Save
                            </FlButton>
                        }
                    </div>
                    <div className="finlink-modal-content">
                        { this.props.children }
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(FlModal)