import axios from 'axios';
import EventEmitter from 'event-emitter';
import store from '../../store';
import { Alert } from '../../store/actions/notifications';
import { setPrefs, getPrefs } from '../prefs';

const BASE_URL = process.env.REACT_APP_API_URL;

export const EM = new EventEmitter();
export const CACHE_RESPONSE_KEY = "FINLINK_CACHE_RESPONSE";

export const get = async (endpoint, token = 'null', cacheResponse = false, ignoreOffline = false) => {
  return await request('get', endpoint, token, null, cacheResponse, ignoreOffline);
};

export const patch = async (endpoint, data, token = 'null', ignoreOffline = false) => {
  return await request('patch', endpoint, token, data, false, ignoreOffline);
};

export const post = async (endpoint, data, token = 'null') => {
  return await request('post', endpoint, token, data);
};

const _emitStaleDataCopy = async (endpoint) => {
  const localDataCopy = await getPrefs(endpoint);

  if(localDataCopy)
    EM.emit(CACHE_RESPONSE_KEY + endpoint, localDataCopy);

  return localDataCopy;
}

export const request = async (type, endpoint, token, data, cacheResponse, ignoreOffline) => {
  let localDataCopyFound = false;
  if(cacheResponse)
    localDataCopyFound = await _emitStaleDataCopy(endpoint);

  if(!ignoreOffline && !localDataCopyFound && !navigator.onLine){
    const offlineTitle = "No internet connection!";
    const offlineMessage = "You seem to have lost your internet connection, please make sure your WiFi or mobile data is in order.";
    store.dispatch(Alert(offlineTitle, offlineMessage));
    throw(new Error("You're offline!"))
  }

  const response = await axios({
    method: type,
    url: BASE_URL + endpoint,
    headers: {
      "Content-Type": "application/json",
      'Authorization': token
    },
    data
  });

  const responseData = response.data;
  if(cacheResponse)
    await setPrefs(endpoint, responseData);
  
  return responseData;
};