import React from 'react';
import { replace } from 'react-router-redux';
import { connect } from 'react-redux';
import { sendVerificationEmail, verifyUserAccount } from '../../../store/actions/register';

import classnames from 'classnames/bind';
import styles from '../styles.scss';

import FlButton from '../../../components/FlButton';

const cx = classnames.bind(styles);

function EmailNotVerified(props){
    React.useEffect(() => {
        const { location } = props.history;
        const queryString = location.search;
    
        if(queryString && queryString.length){
            const verificationToken = queryString.replace('?token=', '');
            props.onVerifyUserAccount(verificationToken);
        }

        props.onSendVerificationEmail();
    }, []);

    const { user } = props;
    const shortName = user ? user.firstName[0] + user.lastName[0] : '';
    let firstName = user ? user.firstName : 'there';
    firstName = firstName.charAt(0).toUpperCase() + firstName.substr(1);

    const { location } = props.history;
    const queryString = location.search;
    let token = '';

    if(queryString && queryString.length){
        token = queryString.replace('?token=', '');
    }
    
    return (
        <div className={cx('authScene confirmScene layout center')}>
            <form className={cx('form')}>

                <div className={cx('logoImage circle')}>
                    { shortName }
                </div>

                <div className={cx('formTitle')}>
                    { !token.length ? 'Email Not Verified' : 'Verifying Account'}
                </div>


                { token.length > 0 && 
                    <p>
                        Hey { firstName }, please wait while we verify your account.
                    </p>
                }

                { !token.length && 
                    <React.Fragment>
                        <p style={{marginBottom: '2em'}}>
                            Click verification link that was sent to your e-mail (<strong>{user.email}</strong>) to verify account.
                        </p>

                        <hr/>

                        <p style={{marginTop: '1.6em', marginBottom: '1.7em'}}>
                            Didn't receive verification email?
                        </p>
                    </React.Fragment>
                }

                <div className="layout center-justified">
                    <FlButton type="submit" inverse
                        loading={props.saving}
                        onClick={props.onSendVerificationEmail}>
                        Resend E-mail
                    </FlButton>
                </div>

                { !token.length && 
                    <div className="siblingPageLink layout center" style={{marginTop: '2.5em'}}>
                        Already verified? &nbsp;
                        <FlButton flat accent onClick={props.onRedirectToLogin}>Login</FlButton>
                    </div>
                }
            </form>
        </div>
    );
}

function mapStateToProps(state){
    return {
        saving: state.app.formLoading,
        user: state.auth.user
    }
}

function mapDispatchToProps(dispatch){
    return {
        onSendVerificationEmail: () => {
            dispatch( sendVerificationEmail() )
        },
        onVerifyUserAccount: (verificationToken) => {
            dispatch( verifyUserAccount(verificationToken) )
        },
        onRedirectToLogin: () => {
            dispatch(replace('/login'));
        },
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(EmailNotVerified);