import React, { useState } from 'react';
import { withRouter } from 'react-router';
import FlButton from '../../../../components/FlButton';
import { canNotify } from '../../../../components/FlNotifications';
import { makePhoneNumberValid } from '../../../../utils';
import { makeWithdrawal } from '../../../../services/API';

const DepositForm = ({depositer, toAccount, depositAmount, onChangeAmount, onChangeDepositer, ...props}) => {
    const [saving, setSaving] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let { _id, number, provider } = depositer;
        const isMobileWallet = _id.indexOf('mobilewallet') !== -1;
        
        if(isMobileWallet){
            number = makePhoneNumberValid(number);
            number = number.substring(1);
        }

        const data = {
            "amount": depositAmount,
            "fromAccount": toAccount._id,
            "toExternalAccount": number,
            "toExternalAccountType": isMobileWallet ? provider : 'bank',
            "type":"withdraw"
        };

        if(depositAmount === 0){
            props.Alert('Please add amount you want to withdraw.');
            return;
        }

        try {
            setSaving(true);
            await makeWithdrawal(data);
            setSaving(false);
            props.Alert(
                null,
                `Withdraw request for <strong>${data.amount.toLocaleString()}</strong> submitted.`, 
                () => {
                    props.history.replace("/");
                    props.refreshAccounts();
                }
            );
        } catch (error) {
            setSaving(false);

            if(error.message !== "You're offline!"){
                if(error.response && error.response.data){
                    let devmessage = error.response.data.developerMessage;
                    if(!devmessage || !devmessage.length)
                        devmessage = "Unknown error prevented withdraw request. Please contact admin.";

                    props.Alert(devmessage);
                }
                else
                    props.Alert('Withdraw request failed, please try again');
    
                console.error('Loan request error', error);
            }
        }
    }

    return (  
        <form onSubmit={handleSubmit}>
            <label>Withdraw To</label>
            <div className="accountChoice selected">
                <div className="flex">
                    { depositer.name }
                    <small>
                        { depositer.number }
                    </small>
                </div>
                
                <button type="button" className="accountChoiceChanger"
                    onClick={onChangeDepositer}>
                    Change
                </button>
            </div>
            
            <label>Amount</label>
            <div className="accountChoice selected">
                <div className="flex">
                    <span>{ depositAmount.toLocaleString() }</span>
                </div>
                
                <button type="button" className="accountChoiceChanger"
                    onClick={onChangeAmount}>
                    Change
                </button>
            </div>

            <FlButton type="submit" rounded primary block loading={saving}>
                Submit Withdraw Request
            </FlButton>
        </form>
    );
}
 
export default withRouter(canNotify(DepositForm));