/* eslint-disable no-console */
import { fetchAuthUserTransactions } from "../../services/API";

import _filter from 'lodash/filter';
import { Alert } from "./notifications";
import { EM, CACHE_RESPONSE_KEY } from "../../services/API/server_calls";

export const FETCHING_USER_TRANSACTIONS = 'FETCHING_USER_TRANSACTIONS';
export const USER_TRANSACTIONS_FETCHED = 'USER_TRANSACTIONS_FETCHED';
export const SET_USER_TRANSACTION_FILTERS = 'SET_USER_TRANSACTION_FILTERS';

export const userTransactionsFetched = (transactions) => ({
    type: USER_TRANSACTIONS_FETCHED,
    transactions: transactions
});

export const fetchingUserTransactions = (status) => ({
    type: FETCHING_USER_TRANSACTIONS,
    status
});

export const setUserTransactionFilters = (filters) => ({
    type: SET_USER_TRANSACTION_FILTERS,
    filters
});

export const fetchUserTransactions = () => async dispatch => {
    dispatch(userTransactionsFetched({
        pagination: {
            currentPage: 1, totalPages: 1
        }, 
        data: []
    }));
    dispatch(fetchingUserTransactions(true));
    const cacheDataBroadcastKey = CACHE_RESPONSE_KEY + '/transactions';
    let localDataCopyFound = false;

    try {
        EM.once(cacheDataBroadcastKey, localDataCopy => {
            const transactions = _filter(localDataCopy.data, transaction => transaction.paidAt !== undefined);
            dispatch(userTransactionsFetched(transactions));
            dispatch(fetchingUserTransactions(false));            

            localDataCopyFound = true;
        });

        const transactions = await fetchAuthUserTransactions();
        dispatch(userTransactionsFetched(transactions));
    } catch (error) {
        dispatch(fetchingUserTransactions(false));
        if(error.message !== "You're offline!"){
            if(!localDataCopyFound)
                dispatch(Alert('Failed to fetch activities', 'Make sure you have an active internet connection and try again.'));
            console.error('Fetch account Error', error);
        }
    }
};