import * as API from './server_calls';
import { getToken } from "../prefs";

export * from './auth';
export * from './register';
export * from './account';
export * from './user-transactions';
export * from './loan';

export const fetchUserNotifications = async () => {
    const token = await getToken();
    return API.get('/notifications', token, false, true);
};

export const setNotificationReadStatus = async (notificationId) => {
    const token = await getToken();
    return API.patch(`/notifications/${notificationId}/markAsRead`, {}, token);
};