import React from 'react';
import formatDate from 'date-fns/format'
import { connect } from 'react-redux';

import './styles.css';
import { replace } from 'connected-react-router';
import FlButton from '../../../../components/FlButton';
import { updateApp } from '../../../../store/actions/app';

class ViewUpdate extends React.Component {
    componentDidMount(){
        if(!this.props.newReleaseDetails){
            this.props.goBackHome();
        }
    }

    render(){
        const newReleaseDetails = this.props.newReleaseDetails;
        if(!newReleaseDetails){
            return <div></div>;
        }
        const release_notes = {
            __html: newReleaseDetails.description
        }
        return ( 
            <div id="viewUpdate">
                <h1>Version</h1>
                <h2>{ newReleaseDetails.name }</h2>
                
                <h1>Released Date</h1>
                <h2>{ formatDate(newReleaseDetails.createdAt, 'Do MMM YYYY') }</h2>

                <div id="releaseNotes">
                    <h1>Release Notes</h1>
                    <div dangerouslySetInnerHTML={release_notes}></div>
                </div>

                <FlButton primary onClick={this.props.updateApp}>
                    UPDATE FINLINK
                </FlButton>
            </div>
        );
    }
}

function mapStateToProps(state){
    return {
        newReleaseDetails: state.app.newReleaseDetails
    }
}

function mapDispatchToProps(dispatch){
    return {
        goBackHome: () => {
            dispatch(replace('/main'));
        },
        updateApp: () => {
            dispatch(replace('/main'));
            dispatch(updateApp());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewUpdate);