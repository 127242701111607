import React from 'react';
import { FlRadioButtonGroup, FlInput } from '../../../../../components/FormControls';

const BankInformation = ({paymentFor, supportedBanks, state, onValueChange}) => {
    let bankAccountNamePlaceholder = "School bank account name";
    let bankAccountNumberPlaceholder = "School bank account number";
    let bankOptions;

    if(supportedBanks)
        bankOptions = supportedBanks.map(({code}) => code);

    if(paymentFor.toLowerCase() === "rent"){
        bankAccountNamePlaceholder = "Rent bank account name";
        bankAccountNumberPlaceholder = "Rent bank account number";
    }

    return (
        <div className="RequestLoanSection">  
            <h3 className="loan-request-section-title">
                Enter { paymentFor.toLowerCase() } payment details
            </h3>

            { bankOptions && (
                <FlRadioButtonGroup
                    label="Bank"
                    name='bank'
                    value={state.bank}
                    options={bankOptions}
                    onChange={onValueChange}
                    errors={state.validationErrors.bank} />
            ) }

            <FlInput
                type="text"
                label="Bank Account Name"
                name='bankAccountName'
                placeholder={bankAccountNamePlaceholder}
                value={state.bankAccountName}
                onChange={onValueChange}
                errors={state.validationErrors.bankAccountName} />
                
            <FlInput
                type="text"
                label="Bank Account Number"
                name='bankAccountNumber'
                placeholder={bankAccountNumberPlaceholder}
                value={state.bankAccountNumber}
                onChange={onValueChange}
                errors={state.validationErrors.bankAccountNumber} />

            
            { 
                state.loanPurpose === 'school' && (
                    <FlInput
                        type="text"
                        label="Student Name"
                        name='studentName'
                        value={state.studentName}
                        onChange={onValueChange}
                        errors={state.validationErrors.studentName} />
                )
            }
                
        </div>
    );
}
 
export default BankInformation;