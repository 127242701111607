import * as API from './server_calls';
import { getToken } from "../prefs";

export const fetchUserAccounts = async () => {
    const token = await getToken();
    return API.get('/accounts', token, true);
};

export const createSavingsAccount = async (data) => {
    const token = await getToken();
    return API.post('/accounts', data, token);
};

export const editSavingsAccount = async (data, accountId) => {
    const token = await getToken();
    return API.patch(`/accounts/${accountId}`, data, token);
};

export const getAccountDetails = async (accountId) => {
    const token = await getToken();
    return API.get(`/accounts/${accountId}`, token, true);
};

export const getAccountTransactions = async (accountId, page = 1) => {
    const token = await getToken();
    return API.get(`/accounts/${accountId}/transactions?currentPage=${page}`, token, true);
};

export const makeTransfer = async (data, fromAccount) => {
    const token = await getToken();
    return API.post(`/accounts/${fromAccount}/transfers`, data, token);
};

export const makeTransferToInvestor = async (data) => {
    const token = await getToken();
    return API.post(`/accounts/transferToInvestor`, data, token);
};

export const makeDeposit = async (toAccount, data) => {
    const token = await getToken();
    return API.post(`/accounts/${toAccount}/deposits`, data, token);
};

export const makeWithdrawal = async (data) => {
    const token = await getToken();
    return API.post("/transactions/withdraw", data, token);
};