import React, { useState, useEffect } from 'react';
import _findIndex from 'lodash/findIndex';
import FlButton from '../../../../../components/FlButton';

import './styles.css';
let openTimeout = null;
const AccountPicker = ({ accounts, currentAccount,  ...props }) => {
    const [open, setOpen] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(-1);

    useEffect(() => {
        setSelectedAccount(_findIndex(accounts, ['_id', currentAccount._id]));

        openTimeout = setTimeout(() => {
            setOpen(true);
        }, 50);

        return () => clearTimeout(openTimeout);
    }, []);

    function closeThen(callback){
        setOpen(false);
        openTimeout = setTimeout(callback, 200);
    }
    
    function onCancel(){
        closeThen(props.onCancel);
    }
    
    function handleSubmit(e){
        e.preventDefault();
        const account = selectedAccount === -1 ? {} : accounts[selectedAccount];
        closeThen(() => props.onComplete(account._id));
    }

    return ( 
        <div className={'account-picker ' + (open ? 'visible' : '')}>
            <div className="account-picker-backdrop"
                 onClick={onCancel}></div>

            <form className="account-picker-content"
                onSubmit={handleSubmit}>

                <h3>Transfer From</h3>
                
                <div id="accounts">
                    { accounts.map((account, index) => {
                            let className = 'account-choice';
                            className += selectedAccount === index ? ' selected' : '';

                            return (
                                <button key={account._id} className={className} 
                                    type="button" onClick={() => setSelectedAccount(index)}>
                                    {account.name}

                                    <i className="icon">
                                        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                    </i>
                                </button>
                            );
                        
                        }) 
                    }
                </div>

                <div className="account-picker-actions layout center justified">
                    <FlButton onClick={ onCancel } type="button" className="account-picker-action" inverse rounded>
                        Cancel
                    </FlButton>
                    
                    <FlButton type="submit" className="account-picker-action" inverse rounded>
                        Okay
                    </FlButton>
                </div>
            </form>
        </div>
    );
}
 
export default AccountPicker;
