/* eslint-disable no-console */
import axios from 'axios';
import * as localForage from "localforage";
import * as serviceWorker from '../../serviceWorker';
import { fetchUserNotifications, setNotificationReadStatus, fetchSupportedBanks } from '../../services/API';

export const APP_INITIALIZED = 'APP_INITIALIZED';
export const FORM_LOADING = 'FORM_LOADING';
export const UPDATE_AVAILABLE = 'UPDATE_AVAILABLE';
export const FETCH_NOTIFICATIONS_START = 'FETCH_NOTIFICATIONS_START';
export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const SET_NOTIFICATION_AS_READ = 'SET_NOTIFICATION_AS_READ';
export const SET_SUPPORTED_BANKS = 'SET_SUPPORTED_BANKS';

export const initApp = (initialized = true) => ({
    type: APP_INITIALIZED,
    initialized
});

export const formLoading = (formLoading) => ({
    type: FORM_LOADING,
    formLoading
});

const updateAvailable = (newReleaseDetails = null) => ({
    type: UPDATE_AVAILABLE,
    newReleaseDetails
});

const fetchNotificationsStart = () => ({
    type: FETCH_NOTIFICATIONS_START
});

const fetchNotificationsError = (error) => ({
    type: FETCH_NOTIFICATIONS_ERROR,
    error
});

const fetchNotificationsSuccess = (notifications) => ({
    type: FETCH_NOTIFICATIONS_SUCCESS,
    notifications
});

const setNotificationAsRead = (notificationId) => ({
    type: SET_NOTIFICATION_AS_READ,
    notificationId
});

export const setSupportedBanks = (banks) => ({
    type: SET_SUPPORTED_BANKS,
    payload: banks
});

export const checkForUpdates = () => async dispatch => {
    try {
        const BASE_URL = process.env.REACT_APP_RELEASE_API_URL;
        const releaseApiAppName = process.env.REACT_APP_RELEASE_API_APP_NAME;
        const response = await axios.get(BASE_URL + `/projects/${releaseApiAppName}/?latest=true`, {
            headers: {"Content-Type": "application/json"}
        });
        const latestVersion = response.data;
        const currentLocalAppVersion = await localForage.getItem("FINLINK_APP_VERSION");
        if(!currentLocalAppVersion)
            localForage.setItem("FINLINK_APP_VERSION", latestVersion.number);
        else if(latestVersion.number !== currentLocalAppVersion)
            dispatch(updateAvailable(latestVersion));

    } catch (error) {
        console.error('APP Versions fetch Error', error);
    }
};

export const updateApp = (latestVersion) => async dispatch => {
    try {
        await localForage.setItem("FINLINK_APP_VERSION", latestVersion);
        serviceWorker.unregister();
        dispatch(updateAvailable(false));
        window.location.reload();
    } catch (error) {
        console.error('APP Versions fetch Error', error);
    }
};

export const getNotifications = (page = 1) => async dispatch => {
    dispatch(fetchNotificationsStart());
    try {
        const notifications = await fetchUserNotifications(page);
        dispatch(fetchNotificationsSuccess(notifications));
    } catch (error) {
        dispatch(fetchNotificationsError("Failed to fetch notifications."));
        console.error('Error fetching notifications', error.response);
    }
};

export const markNotificationAsRead = (notificationId) => async dispatch => {
    try {
        await setNotificationReadStatus(notificationId);
        dispatch(setNotificationAsRead(notificationId));
    } catch (error) {
        console.error('Error setting notification as read', error);
    }
};

export const getSupportedBanks = () => async dispatch => {
    try {
        const res = await fetchSupportedBanks();
        dispatch(setSupportedBanks(res.data));
    } catch (error) {
        if(error.message !== "You're offline!")
            console.error('Fetch supported banks error', error);
    }
};