import React from 'react';
import { connect } from 'react-redux';
import { replace, push, goBack } from 'connected-react-router';
import { Link } from 'react-router-dom';
import _values from 'lodash/values';
import _flatten from 'lodash/flatten';

import classnames from 'classnames/bind';
import styles from './styles.scss';
import FlButton from '../../../../components/FlButton';
import NavBar from '../../components/NavBar';
import { authLogout } from '../../../../store/actions/auth';
import { cardOptions } from '../../../../constants';

import Switch from '../../../../components/Switch';
import PaymentCards from './PaymentCards';
import HashRoute from '../../../../Router/HashRoute';
import AddPaymentCard from './PaymentCards/AddPaymentCard';

const cx = classnames.bind(styles);

class Profile extends React.Component {
    UNSAFE_componentWillMount(){
        // if(!this.props.user)
            // return this.props.redirectToHome();
    }

    render() {
        const { user } = this.props;
        const paymentMethods = _flatten(_values(this.props.paymentMethods));
        const fullName = user ? `${user.firstName} ${user.lastName}` : '';
        const cardType = user.idType && cardOptions[user.idType] ? cardOptions[user.idType].label : 'Unknown';

        return ( 
            <React.Fragment>
                <NavBar className="account-detail-nav" onGoBack={this.props.goBack}>
                    <div className="top-bar layout center">
                        <span className="flex"></span>

                        <FlButton style={{fontSize: '16px'}} flat
                            onClick={this.props.logout}>
                            LOGOUT
                        </FlButton>
                    </div>
                </NavBar>

                <div className={cx('mainFragment profileScene')}>
                    <div id="pageTitle" className="layout center">
                        <div>
                            <h2>{ fullName }</h2>
                            <small>{ user.phoneNumber }</small>
                            <Link to='/main/editProfile' role="link">
                                <FlButton style={{fontSize: '16px'}} flat>
                                    CHANGE DETAILS
                                </FlButton>
                            </Link>
                        </div>
                        <div id="dp" className="layout center-center">
                            { user.firstName.charAt(0) }
                            { user.lastName.charAt(0) }
                        </div>
                    </div>

                    <div id="preferences" className="profile-section" style={{ display: "none" }}>
                        <h1 className="profile-section-title">Preferences</h1>

                        <div className="layout center justified">
                            <div className='profile-detail layout vertical start'>
                                <small>
                                    See broadcast loan requests
                                </small>
                            </div>
                            
                            <div style={{flexShrink: 0}}>
                                <Switch />
                            </div>
                        </div>
                        
                        <div className="layout center justified">
                            <div className='profile-detail layout vertical start'>
                                <small>
                                    Allow private loan requests
                                </small>
                            </div>
                            
                            <div style={{flexShrink: 0}}>
                                <Switch value={true} />
                            </div>
                        </div>
                        
                        <div className="layout center justified">
                            <div className='profile-detail layout vertical start'>
                                <small>
                                    Receive message notifications
                                </small>
                            </div>
                            
                            <div style={{flexShrink: 0}}>
                                <Switch value={true} />
                            </div>
                        </div>
                    </div>

                    <PaymentCards />

                    { paymentMethods && paymentMethods.length > 0 &&
                        <div className="profile-section">
                            <h1 className="profile-section-title">Payment Methods</h1>
        
                            {
                                paymentMethods.map((paymentMethod, index) => {
                                    const selected = index === paymentMethods.length - 1;

                                    return (
                                        <div key={paymentMethod._id} role="listitem" className="profile-detail layout start">
                                            <div className="payment-method-icon layout center-center">
                                                { selected && 
                                                    <svg width="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                }
                                                
                                                { !selected && 
                                                    <span>{paymentMethod.name.charAt(0).toUpperCase()}</span>
                                                }
                                            </div>
                                            <div className="layout vertical start">
                                                <small>{ paymentMethod.name }</small>
                                                <h2>{ paymentMethod.number }</h2>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            
                            <FlButton flat>
                                ADD PAYMENT METHOD
                            </FlButton>
                        </div>
                    }

                    {/* <div className="profile-detail last-item">
                        <FlButton id="logoutBtn" block flat rounded onClick={this.props.logout}>
                            LOGOUT
                        </FlButton>
                    </div> */}
                </div>

                <HashRoute exact path="#finlinkModal/AddPaymentCard" component={AddPaymentCard} />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state){
    return {
        user: state.auth.user,
        paymentMethods: state.auth.user.paymentMethods
    }
}

function mapDispatchToProps(dispatch){
    return {
        goBack: () => {
            dispatch(goBack());
        },
        redirectToHome: () => {
            dispatch(replace('/'));
        },
        editAccount: () => {
            dispatch(push('/main/editProfile'))
        },
        logout: () => {
            dispatch(authLogout())
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Profile);