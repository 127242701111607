import React, {useState, useEffect} from 'react';

import ListItem from "../ListItem";
import "./styles.scss";

const OptionPicker = ({multiple, defaultValue, options, onChange, ...props}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(-1);

    useEffect(() => {
        if(!defaultValue)
            return;
            
        if(multiple){
            if(typeof defaultValue !== Array)
                throw('Default value should be Array for multiple options.');
            
            const selectedLabels = defaultValue.map(({label}) => label);
            let selectedIndices = options.filter(({label}) => selectedLabels.includes(label));
            selectedIndices = selectedIndices.map((_, index) => index);
            setSelectedOptions(selectedIndices);
        }
        else{
            const selectedIndex = options.findIndex(({label}) => defaultValue.label === label);
            setSelectedOption(selectedIndex);
        }
    }, [defaultValue]);

    useEffect(() => {
        const containsInvalidOption = options.some(({label}) => label === undefined);
        if(containsInvalidOption)
            throw('Default value should be Array for multiple options.');

    }, [options]);

    function handleOptionClicked(option, index, selected){
        if(multiple){
            let newSelectedOptions;

            if(selected)
                newSelectedOptions = selectedOptions.filter((i) => i !== index);
            else
                newSelectedOptions = [...selectedOptions, index];

            setSelectedOptions(newSelectedOptions);

            const choices = options.filter((_, index) => selectedOptions.indexOf(index) !== -1);
            onChange(choices);
        }
        else{
            setSelectedOption(index);
            onChange(option)
        }
    }

    return (
        <div className="OptionPicker" {...props}>
            {
                options.map((option, index) => {
                    let selected = false;
                    if(multiple)
                        selected = selectedOptions.indexOf(index) !== -1;
                    else
                        selected = selectedOption === index;

                    return (
                        <ListItem
                            key={index}
                            selectable
                            selected={selected}
                            data={option}
                            onClick={() => handleOptionClicked(option, index, selected)}
                        />
                    )
                })
            }
        </div>
    );
}
 
export default OptionPicker;

OptionPicker.defaultProps = {
    options: [], 
    onChange: () => {}
}