import React from 'react';
import formatDate from 'date-fns/format'

import FlexBox from '../../../../components/FlexBox';
import Spacer from '../../../../components/Spacer';
import Loader from '../../../../components/Loader';
import GenericTransaction from './GenericTransaction';
import AccountTransaction from './AccountTransaction';

import './styles.scss';

const Transactions = (props) => {
    const { fetchingTransactions, reloading, filters, accountId } = props;
    let filteredTransactions = props.transactions;
    let transactions = filteredTransactions.map(t => {
        const localTransactions = [
            "transfer", 'interest', 'loanrepayment', 'loandisbursment',
            "commission", "guaranteereleased"
        ];
        const date = localTransactions.includes(t.type.toLowerCase()) ? t.createdAt : t.paidAt || t.updatedAt;
        t.date = formatDate(new Date(date), 'Do MMM YYYY');

        if(t.amount)
            t.amount = parseInt(t.amount);

        if(t.accountTransactions && t.accountTransactions.length){
            const accountTransactionDetails = t.accountTransactions[0];
            
            if(accountTransactionDetails){
                const { amount, fromAccount, fromExternalAccount, toAccount } = accountTransactionDetails;
                t = {
                    ...t, fromAccount, fromExternalAccount, toAccount
                }

                if(accountId && amount)
                    t.amount = t.amount = parseInt(amount);
            }
        }
        return t;
    });
    transactions = transactions.sort((t1, t2) => {
        const date1 = t1.type === "transfer" ? t1.createdAt : t1.paidAt;
        const date2 = t2.type === "transfer" ? t2.createdAt : t2.paidAt;

        return new Date(date2) - new Date(date1);
    });

    let loaderMessage = "Fetching";
    loaderMessage += accountId ? " account " : "";
    loaderMessage += "transactions";
    return ( 
        <React.Fragment>
            { transactions && !reloading &&
                transactions.map(transaction => (
                    <React.Fragment key={transaction._id}>
                        { accountId && 
                            <AccountTransaction key={transaction._id} transaction={ transaction } />
                        }
                        { !accountId && 
                            <GenericTransaction key={transaction._id} transaction={ transaction } />
                        }
                    </React.Fragment>
                ))
            }

            { (fetchingTransactions || reloading) && 
                <FlexBox vertical center>
                    <Spacer height="15" />
                    <Loader color="#aaa" />
                    <Spacer height="20" />
                    <span className="transactionsLoading">
                        { loaderMessage }
                    </span>
                </FlexBox>
            }
            
            { !fetchingTransactions && !transactions.length && 
                <span className="transactionsLoading">No transactions found</span>
            }
        </React.Fragment>
    );
}
 
export default Transactions;
