import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';

import { checkUserVerification } from '../../../utils';
import { canNotify } from '../../../components/FlNotifications';

import PhoneNotVerified from "./phone-not-verified";
import EmailNotVerified from "./email-not-verified";

const NotVerified = (props) => {
    useEffect(() => {
        const { location } = props.history;
        const queryString = location.search;

        if(queryString && queryString.length){
            const verificationToken = queryString.replace('?token=', '');
            props.onVerifyUserAccount(verificationToken);
        }

        if(!props.user)
            props.onRedirectToLogin();
        else if(checkUserVerification(props.user))
            props.onRedirectToHome();
    }, []);

    const loggedInWithEmail = props.user && props.user.username && props.user.username.indexOf('@') !== -1;

    return (
        <React.Fragment>
            { loggedInWithEmail && <EmailNotVerified  {...props} /> }
            { !loggedInWithEmail && <PhoneNotVerified {...props} /> }
        </React.Fragment>
    );
}

function mapStateToProps(state){
    return {user: state.auth.user}
}

function mapDispatchToProps(dispatch){
    return {
        onRedirectToLogin: () => {
            dispatch(replace('/login'));
        },
        onRedirectToHome: () => {
            dispatch(replace('/'));
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(canNotify(NotVerified));