import React from 'react';
import { Route } from 'react-router-dom';
import FlModal from '../components/FlModal';

const HashRoute = ({ component: Component, exact, path, children, ...routeProps }) => (
    <Route 
        {...routeProps}
        component={({ location, ...props }) => {
            const matchesExactly = exact && location.hash === path;
            const matchesPartially = !exact && location.hash.indexOf(path) !== -1;
            const pathMatches = matchesExactly || matchesPartially;
            let pageTitle; 

            if(routeProps.title){
                pageTitle = routeProps.title;                
            }
            else{
                pageTitle = location.hash.replace('#finlinkModal/', '');
                /*
                * Convert from camel case path to spaced title
                * e.g. #finlinkModal/EditAccount to Edit Account
                */
                pageTitle = pageTitle.replace(/([A-Z])/g, ' $1')
                                .replace(/^./, (str) => str.toUpperCase());
            }

            const allProps = { children, pageTitle, ...props };
            const { onBeforeClose } = routeProps;

            return (
                pathMatches && 
                <FlModal pageTitle={pageTitle} onBeforeClose={onBeforeClose}>
                    <Component {...allProps} />
                </FlModal>
            );
        }}
    />
)

export default HashRoute;