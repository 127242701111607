import React from 'react';
import classnames from 'classnames/bind';
import styles from './styles.scss';
const cx = classnames.bind(styles);

const PickPaymentMethodType = ({paymentMethods, onAddPaymentMethod, onTypeSelected}) => {
    const handlePaymentMethodClicked = (paymentMethodType) => {
        const type = paymentMethodType.toLowerCase().replace(' ', '');
        const paymentMethodsOfType = paymentMethods ? paymentMethods[type] : [];

        if(!paymentMethods || !paymentMethodsOfType.length){
            onAddPaymentMethod(paymentMethodType);
            return;
        }

        onTypeSelected(paymentMethodType);
    }

    return ( 
        <div id="pickPaymentMethodType">
            <label>Withdraw from</label>

            <button type="button" className={cx('accountChoiceItem')} 
                onClick={() => handlePaymentMethodClicked('Mobile Wallet')}>
                Mobile Wallet
            </button>

            <button type="button" className={cx('accountChoiceItem')} 
                onClick={() => handlePaymentMethodClicked('Bank Account')}>
                Bank Account
            </button>
        </div>
    );
}
 
export default PickPaymentMethodType;