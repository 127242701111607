import React from 'react';
import './styles.scss'

const FlRadioButton = (props) => {
    const { checked, label, onChange, name, ...inputProps} = props;
    let className = 'finlink-radio-button';
    className += checked ? ' checked' : '';
    const randomId = `flRadioButton${Math.random().toString(36).substr(2, 5)}`;

    return ( 
        <div className={className}>
            <input type='radio'
                id={randomId}
                checked={checked}
                name={name}
                onChange={onChange}
                { ...inputProps }
            />

            <label name={name} htmlFor={randomId} tabIndex="0" onClick={onChange}>
                <svg viewBox="0 0 24 24">
                    <circle x="12" y="12" r="9" />
                </svg>

                { label }
            </label>
        </div>
    );
}
 
export default FlRadioButton;