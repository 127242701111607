import React from 'react';
import classnames from 'classnames/bind';
import styles from './styles.scss';
const cx = classnames.bind(styles);

const AccountChoiceItem = ({account, onSelect}) => {
    return ( 
        <button type="button"
            className={cx('accountChoiceItem', {selected: account.transferAmount && account.transferAmount !== 0})}
            onClick={onSelect}>
            <span style={{textAlign: 'left'}} className="layout vertical flex">
                <span>
                    {account.name}

                    { account.type && account.type.toLowerCase() === 'main' ? 
                        '(Main)' : ''
                    }
                </span>
                <span>
                    { account.transferAmount !== 0 &&
                        <React.Fragment>
                            <strong className="currency">TZS </strong>
                            <small className="amount">
                                { account.transferAmount.toLocaleString() }
                            </small>
                        </React.Fragment>
                    }
                    { account.transferAmount === 0 &&
                        <small className="amount" style={{color: "#9c9c9c"}}>
                            (tap to set amount)
                        </small>
                    }
                </span>
            </span>

            <svg fill="#aaa" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>
        </button>
    );
}
 
export default AccountChoiceItem;