import { FETCHING_USER_TRANSACTIONS, USER_TRANSACTIONS_FETCHED, SET_USER_TRANSACTION_FILTERS } from '../actions/user-transactions';
const initialState = { fetching: false, fetched: false, filters: null, currentPage: 0, totalPages: 0, transactions: []};

const userTransactions = (state = initialState, action) => {
    switch (action.type) {
        case FETCHING_USER_TRANSACTIONS:
            return {
                ...state,
                fetching: action.status
            };
        case USER_TRANSACTIONS_FETCHED:{
            const {data, pagination} = action.transactions;
            let transactions = state.transactions;
            if(pagination.currentPage === 1 || pagination.currentPage === state.currentPage)
                transactions = data;
            else
                transactions = [...transactions, ...data];

            return {
                ...state,
                fetched: true,
                fetching: false,
                transactions,
                currentPage: pagination.currentPage, 
                totalPages: pagination.totalPages
            };
        }
        case SET_USER_TRANSACTION_FILTERS:
            return {
                ...state,
                filters: action.filters
            };
        default:
            return state;
    }
};

export default userTransactions;
