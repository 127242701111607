export function makePhoneNumberValid(unformattedNumber){
    let number = unformattedNumber.trim();
    if(number.charAt(0) === "0" || number.charAt(0) === "+")
        number = number.substring(1);

    if(number.substring(0, 3) === "255")
        number = number.substring(3);

    return "+255" + number;
}

export function checkUserVerification(user){
    if(!user.username)
        return user.verifiedAt !== undefined || user.phoneNumberVerifiedAt !== undefined;

    const loggedInWithEmail = user.username.indexOf('@') !== -1;
    const emailIsVerified = !loggedInWithEmail || user.verifiedAt !== undefined;
    const phoneNumberIsVerified = loggedInWithEmail || user.phoneNumberVerifiedAt !== undefined;
    
    return emailIsVerified && phoneNumberIsVerified;
}

export function formatCurrency(num){
    const n = String(num),
          p = n.indexOf('.')
    return n.replace(
        /\d(?=(?:\d{3})+(?:\.|$))/g,
        (m, i) => p < 0 || i < p ? `${m},` : m
    )
}