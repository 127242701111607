import React from 'react';
import { connect } from "react-redux";
import { removeNotification, Toast, Alert } from '../../store/actions/notifications';
import Notifications from './Notifications';

const FlNotifications = React.createContext();

export const canNotify = (Component) => {
    return function WrapperComponent(componentProps){
        return (
            <FlNotifications.Consumer>
                { ({...notifitionProps}) => 
                    <Component {...componentProps} {...notifitionProps} />
                }
            </FlNotifications.Consumer>
        );
    };
}

const FlNotificationsProvider = (props) => {
    const { children, notifications, 
            onAlert, onToast, onRemoveNotification} = props;
            
    return (
        <FlNotifications.Provider
            value={{Toast: onToast, Alert: onAlert}}>
            {children}

            <Notifications
                notifications={notifications} 
                onCloseAlert={onRemoveNotification} />
        </FlNotifications.Provider>
    );
}

function mapStateToProps(state){
    return {
        notifications: state.notifications
    }
}

function mapDispatchToProps(dispatch){
    return {
        onToast: (message) => {
            dispatch(Toast(message))
        },
        onAlert: (...props) => {
            dispatch(Alert(...props))
        },
        onRemoveNotification: (notificationId) => {
            dispatch(removeNotification(notificationId))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlNotificationsProvider);