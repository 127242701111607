import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import { Login, ForgotPassword,
        Register, ConfirmIdentity, NotVerified, 
        ResetPassword, ResetPasswordSent } from '../Scenes/Auth';
import Main from '../Scenes/Main';

const Routes = () => {
    return ( 
        <Switch>
            <Route path='/login' component={Login} />
            <Route exact path='/forgot-password' component={ForgotPassword} />
            <Route exact path='/reset-password-sent' component={ResetPasswordSent} />
            <Route exact path='/users/passwords/reset' component={ResetPassword} />
            <Route exact path='/register' component={Register} />
            <PrivateRoute exact path='/confirm-identity' component={ConfirmIdentity} />
            <Route exact path='/not-verified' component={NotVerified} />
            <Route path='/users/verify' component={NotVerified} />
            <PrivateRoute path='/main' component={Main} />
            <Redirect path='/' to='/main' />
        </Switch>
    );
}
 
export default Routes;