export const SET_RECEPIENT_ACCOUNTS = 'SET_RECEPIENT_ACCOUNTS';
export const SET_RECEPIENT_TYPE = 'SET_RECEPIENT_TYPE';
export const SET_SELECTED_RECEPIENT = 'SET_SELECTED_RECEPIENT';
export const SET_SELECTED_RECEPIENT_AMOUNT = 'SET_SELECTED_RECEPIENT_AMOUNT';
export const SET_INVESTOR = 'SET_INVESTOR';
export const SET_INVESTOR_TRANSFER_AMOUNT = 'SET_INVESTOR_TRANSFER_AMOUNT';

export const setRecepientAccounts = (recepientAccounts) => ({
    type: SET_RECEPIENT_ACCOUNTS,
    recepientAccounts
});

export const setRecepientType = (recepientType) => ({
    type: SET_RECEPIENT_TYPE,
    recepientType
});

export const setSelectedRecepient = (selectedRecepient) => ({
    type: SET_SELECTED_RECEPIENT,
    selectedRecepient
});

export const setSelectedRecepientAmount = (selectedRecepientAmount) => ({
    type: SET_SELECTED_RECEPIENT_AMOUNT,
    selectedRecepientAmount
});

export const setInvestor = (investor) => ({
    type: SET_INVESTOR,
    investor
});

export const setInvestorTransferAmount = (amount) => ({
    type: SET_INVESTOR_TRANSFER_AMOUNT,
    amount
});