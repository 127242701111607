import React from 'react';

import ThaTitle from './ThaTitle';
import './styles.css';

const DateFilter = ({ startMonth, endMonth, onSetStartMonth, onSetEndMonth }) =>{
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    function setMonth(selectedMonth){
        if(startMonth === -1 || endMonth !== -1){
            onSetStartMonth(selectedMonth);
            onSetEndMonth(-1);
        }
        else{
            if(startMonth !== selectedMonth)
                onSetEndMonth(selectedMonth);
            else
                onSetStartMonth(-1);
        }
    }

    return ( 
        <div className="date-filter-wrapper">
            <ThaTitle startMonth={startMonth} endMonth={endMonth} /> 

            <div id="numbers">
                { months.map( (month, index) => {
                        const greaterThanStartMonth = startMonth !== -1 && index >= startMonth;
                        const lessThanEndMonth = endMonth !== -1 && index <= endMonth;
                        const active = index === startMonth || (greaterThanStartMonth && lessThanEndMonth);
                        let className = active ? 'active' : '';
                        className += index < startMonth && endMonth === -1 ? 'inactive' : '';

                        return (
                            <button key={month} className={className} 
                                type="button" onClick={() => setMonth(index)}>
                                {month}
                            </button>
                        );
                    
                    }) 
                }
            </div>
        </div>
    );
}
 
export default DateFilter;