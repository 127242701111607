import React, { useState, useEffect } from 'react';
import * as Dragdealer from 'dragdealer';
import { push } from 'connected-react-router';

import slideImage1 from "./img/home-slides/anywhere.jpg";
import slideImage2 from "./img/home-slides/fingertips.jpg";
// import slideImage3 from "./img/home-slides/freedom.jpg";
// import slideImage4 from "./img/home-slides/relax.jpg";

import FlButton from "../../../../components/FlButton";
import { connect } from 'react-redux';

const Carousel = ({openPendingLoanRequests, openGuarantorPool}) => {
    const [carouselStep, setCarouselStep] = useState(0);
    const slideItems = [
        {
            image: slideImage1,
            label: "Pending Loan</br> Requests",
            actionText: "View Requests",
            action: openPendingLoanRequests
        },
        {
            image: slideImage2,
            label: "Loan Guarantee</br> Requests",
            actionText: "View Requests",
            action: openGuarantorPool
        }
    ];

    useEffect(() => {
        // Fix weird jest error
        if(typeof(Dragdealer) == 'object')
            return;

        const homeCarousel = new Dragdealer('carousel', {
            steps: slideItems.length,
            speed: 0.3,
            loose: true,
            dragStopCallback: () => {
                setCarouselStep(homeCarousel.getStep()[0] - 1)
            }
        });
    }, []);

    return (
        <div id='carousel' className='image-carousel dragdealer'>
            <div className='handle' style={{width: `${slideItems.length * 100}vw`}}>
                { slideItems.map((item, index) => (
                    <div key={`slideItem${index}`} className="slide pos-r">
                        <div className="pos-r">
                            <img src={item.image} className="pos-a" alt=""/>
                            <div className="slide-text layout vertical start center-justified">
                                <span dangerouslySetInnerHTML={{__html:item.label}} />
                                <FlButton primary flat rounded onClick={item.action}>
                                    {item.actionText}
                                </FlButton>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className='indicators'>
                { slideItems.map((_item, index) => (
                    <span key={`slideMarker${index}`}
                        className={carouselStep === index ? 'active' : ''} />
                ))}
            </div>
        </div>
    );
}

function mapDispatchToProps(dispatch){
    return {
        openPendingLoanRequests: () => {
            dispatch(push('/main/loanRequests?filter=pending'));
        },
        openGuarantorPool: () => {
            dispatch(push('/main/guarantor-pool'));
        }
    }
}

export default connect(null, mapDispatchToProps)(Carousel);
