import React from 'react';
import './styles.css';

const FlButton = ({id, className, style, onClick, disabled, primary, loading, type = "button", ariaLabel, ...props}) => {
    let classes = 'finlink-btn ' + className + ' ';
    classes += Object.keys(props).reduce((agg, k) => agg + ' ' + k, '');
    classes += primary ? ' primary' : '';
    classes += loading ? ' loading' : '';
    classes += disabled ? ' disabled' : '';

    return ( 
        <button aria-label={ariaLabel} id={id} className={classes} style={style} onClick={onClick} type={type}>
            { props.action && props.children }
            { !props.action && <span> { props.children } </span> }
            { !props.action && loading && 
                <svg className="loader-icon" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
                    width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" 
                    style={ { background: 'none'} }>
                    <circle cx="50" cy="50" fill="none" stroke="#f3f5f5" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138" transform="rotate(269.874 50 50)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></circle>
                </svg> 
            }
        </button>
    );
}
 
export default FlButton;

export { default as FAB } from "./FAB";
export { default as OutlineButton } from "./OutlineButton";