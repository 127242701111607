import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import Routes from './Routes';

function Router({ history }){
    return (
        <ConnectedRouter history={ history }>
            <Routes />
        </ConnectedRouter>
    );
}
  
export default Router;