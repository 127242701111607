import React from 'react';
import classnames from 'classnames/bind';

import styles from './styles.scss';
import Loader from '../../../../../components/Loader';
import { useState } from 'react';

const cx = classnames.bind(styles);

const AccountSwitcher = (props) => {
    const [accountBalanceType, setAccountBalanceType] = useState("total");
    const { currentAccountIndex, accounts, animateSwitch, onChangeAccount } = props;
    const canGoNext = accounts && currentAccountIndex < accounts.length - 1;
    const canGoPrev = currentAccountIndex !== 0;
    
    function goNext(){
        const currentIndex = currentAccountIndex;
        const nextIndex = currentIndex + 1;
        if(canGoNext)
            onChangeAccount(nextIndex);
        else
            onChangeAccount(0, false);
    }

    function toggleAccountBalanceType(){
        setAccountBalanceType(
            accountBalanceType === "total" ? "available" : "total"
        );
    }
    
    function goPrev(){
        const currentIndex = currentAccountIndex;
        const prevIndex = currentIndex - 1;
        if(canGoPrev)
            onChangeAccount(prevIndex);
        else
            onChangeAccount(accounts.length - 1, false);
    }

    let scrollPercent = 0;
    if(accounts)
        scrollPercent = 100 / accounts.length;

    const transform = `translateX(${currentAccountIndex * -scrollPercent}%)`;

    return ( 
        <div id="accountSwitcher" className={'accountSwitcher dragdealer' + ( animateSwitch ? ' animate-switch' : '' )}>
            <div className="handle" style={ { transform: transform } }>
                { 
                    props.accounts.map((account, index) => (
                        <div key={index} 
                            className={cx('card', { active: index === currentAccountIndex })}>

                            <div className="cardContent">
                                <span className="accountName layout center-center">
                                    { account.name ? account.name.toUpperCase() : "" }
                                </span>

                                {/* <span className="accountInterest layout center-center">
                                    interest: { account.interest }
                                </span> */}
                                
                                <div className={`accountBalanceInfo ${accountBalanceType}`}
                                    onClick={toggleAccountBalanceType}>
                                    
                                    <span className="layout vertical center-center">
                                        { account.reloadingDetails && 
                                            <Loader size="36" color="#a8fdff" />
                                        }
                                        { !account.reloadingDetails &&
                                            <div style={{width: "100%"}}>
                                                <span className="accountBalance">
                                                    <small>Balance</small>
                                                    { account.currency ? account.currency.toUpperCase() : "TZS" }
                                                    &nbsp;{ parseInt(account.balance).toLocaleString() }
                                                </span>

                                                {/* Or something like.....but he'd only gone because he wanted to pay homage to the baby's pearl blues that were too close to those of Carly's 'bestfriend' from college. */}
                                                
                                                {
                                                    account && account.type && account.type.toLowerCase() !== 'main' &&
                                                    <span className="accountBalance">
                                                        { account.currency ? account.currency.toUpperCase() : "TZS" }
                                                        &nbsp;{ parseInt(account.loanBalance).toLocaleString() }
                                                        <span>( loan )</span>
                                                    </span>
                                                }
                                                
                                                <span className="accountBalance">
                                                    { account.currency ? account.currency.toUpperCase() : "TZS" }
                                                    &nbsp;{ parseInt(account.availableBalance || 0).toLocaleString() }
                                                    <span>( available )</span>
                                                </span>
                                            </div>
                                        }
                                    </span>
                                </div>
                            </div>
                            
                        </div>
                    ))
                }
            </div>
            
            <div className="accountSwitcherButtons layout center justified">
                <button onClick={goPrev}>
                    <svg viewBox="0 0 24 24" stroke="currentColor"><polyline points="15 18 9 12 15 6"></polyline></svg>
                </button>
                <button onClick={goNext}>
                    <svg viewBox="0 0 24 24" stroke="currentColor"><polyline points="9 18 15 12 9 6"></polyline></svg>
                </button>
            </div>
        </div>
    );
}
 
export default AccountSwitcher;