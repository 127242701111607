/* eslint-disable no-console */
import { requestLoan, fetchLoanMinimumAmount, fetchGuaranteeRequests, fetchLoanRequests, submitGuaranteeForLoanRequest, rejectLoanGuaranteeRequest } from "../../services/API";
import { formLoading } from "./app";
import { Alert } from "./notifications";
import { replace } from "react-router-redux";
import { fetchAccounts } from "./account";
import { fetchUserTransactions } from "./user-transactions";

export const FETCHING_LOAN_REQUESTS = 'FETCHING_LOAN_REQUESTS';
export const SET_LOAN_REQUESTS = 'SET_LOAN_REQUESTS';
export const SET_CURRENT_LOAN_REQUEST = 'SET_CURRENT_LOAN_REQUEST';
export const SET_LOAN_REQUESTS_FILTER = 'SET_LOAN_REQUESTS_FILTER';
export const SET_LOAN_MIN_REQUEST_AMOUNT = 'SET_LOAN_MIN_REQUEST_AMOUNT';
export const LOAN_REQUEST_SUBMITTED = 'LOAN_REQUEST_SUBMITTED';
export const FETCHING_GUARANTEE_REQUESTS = 'FETCHING_GUARANTEE_REQUESTS';
export const GUARANTEE_REQUESTS_FETCHED = 'GUARANTEE_REQUESTS_FETCHED';
export const SET_CURRENT_GUARANTEE_REQUEST = 'SET_CURRENT_GUARANTEE_REQUEST';
export const GUARANTEE_REQUEST_SAVING = 'GUARANTEE_REQUEST_SAVING';
export const REMOVE_GUARANTEE_REQUEST = 'REMOVE_GUARANTEE_REQUEST';

export const fetchingLoanRequests = (status) => ({
    type: FETCHING_LOAN_REQUESTS,
    payload: status
});

export const setLoanRequests = (loans) => ({
    type: SET_LOAN_REQUESTS,
    payload: loans
});

export const setCurrentLoanRequest = (loanId) => ({
    type: SET_CURRENT_LOAN_REQUEST,
    payload: loanId
});

export const setLoanRequestsFilter = (filter) => ({
    type: SET_LOAN_REQUESTS_FILTER,
    payload: filter
});

export const loanRequestSubmitted = (loan) => ({
    type: LOAN_REQUEST_SUBMITTED,
    payload: loan
});

export const setLoanMinRequestAmount = (amount) => ({
    type: SET_LOAN_MIN_REQUEST_AMOUNT,
    payload: amount
});

export const fetchingGuaranteeRequests = (status) => ({
    type: FETCHING_GUARANTEE_REQUESTS,
    payload: status
});

export const guaranteeRequestsFetched = (requests) => ({
    type: GUARANTEE_REQUESTS_FETCHED,
    payload: requests
});

export const setCurrentGuaranteeRequest = (request) => ({
    type: SET_CURRENT_GUARANTEE_REQUEST,
    payload: request
});

export const guaranteeRequestSaving = (status) => ({
    type: GUARANTEE_REQUEST_SAVING,
    payload: status
});

export const removeGuaranteeRequest = (type, requestId) => ({
    type: REMOVE_GUARANTEE_REQUEST,
    payload: {type, requestId}
});

export const getLoanMinRequestAmount = () => async dispatch => {
    try {
        const res = await fetchLoanMinimumAmount();
        dispatch(setLoanMinRequestAmount(Number(res.value)));
    } catch (error) {
        if(error.message !== "You're offline!")
            console.error('Loan min amount error', error);
    }
};

export const submitLoanRequest = (data) => async dispatch => {
    dispatch(formLoading(true));

    try {
        let newLoanRequest = await requestLoan(data);
        dispatch(formLoading(false));
        dispatch(loanRequestSubmitted(newLoanRequest));
        dispatch(replace('/main'));

        dispatch(Alert(null, 'Loan Request Submitted', () => {
            dispatch(fetchAccounts(null, true));
            dispatch(fetchUserTransactions());
        }));
    } catch (error) {
        dispatch(formLoading(false));
        if(error.message !== "You're offline!"){

            if(error.response && error.response.data){
                const devmessage = error.response.data.developerMessage;
                if(devmessage && devmessage.length)
                    dispatch(Alert(devmessage));
                else
                    dispatch(Alert('Loan request wasn\'t submitted'));
            }
            else
                dispatch(Alert('Loan request wasn\'t submitted'));

            console.error('Loan request error', error);
        }
    }
};

export const getLoanRequests = () => async dispatch => {
    dispatch(fetchingLoanRequests(true));

    try {
        let guaranteeRequests = await fetchLoanRequests();
        dispatch(fetchingLoanRequests(false));
        dispatch(setLoanRequests(guaranteeRequests));
    } catch (error) {
        dispatch(fetchingLoanRequests(false));
        if(error.message !== "You're offline!"){
            dispatch(Alert('Failed to fetch loan requests'));
            console.error('Fetch loan requests', error);
        }
    }
};

export const getGuaranteeRequests = () => async dispatch => {
    dispatch(fetchingGuaranteeRequests(true));

    try {
        let guaranteeRequests = await fetchGuaranteeRequests();
        dispatch(guaranteeRequestsFetched(guaranteeRequests));
    } catch (error) {
        dispatch(fetchingGuaranteeRequests(false));
        if(error.message !== "You're offline!"){
            dispatch(Alert('Failed to fetch guarantee requests'));
            console.error('Fetch guarantee requests', error);
        }
    }
};

export const guaranteeLoanRequest = ({type, loanId, ...payload}) => async dispatch => {
    dispatch(guaranteeRequestSaving(true));

    try {
        await submitGuaranteeForLoanRequest(loanId, payload);
        dispatch(guaranteeRequestSaving(false));
        dispatch(Alert(null, 'Successfully guaranteed!', () => {
            dispatch(removeGuaranteeRequest(type, payload.loanId));
            dispatch(fetchAccounts(null, true));
            dispatch(fetchUserTransactions());
            dispatch(replace('/main'));
        }));
    } catch (error) {
        dispatch(guaranteeRequestSaving(false));
        if(error.message !== "You're offline!"){
            if(error.response && error.response.data){
                const devmessage = error.response.data.developerMessage;
                if(devmessage === "Insufficient funds for this transaction!")
                    dispatch(Alert('You don\'t have enough funds!'));
                else if(devmessage === "This loan is already guaranteed.")
                    dispatch(Alert(
                        'Loan has already been fully guaranteed.',
                        null, 
                        () => dispatch(replace('/main'))
                    ))
            }
            else
                dispatch(Alert('Loan guarantee failed'));
                
            console.error('Loan guarantee error', error);

        }
    }
};

export const rejectLoanRequest = ({type, _id}) => async dispatch => {
    dispatch(guaranteeRequestSaving(true));

    try {
        await rejectLoanGuaranteeRequest(_id);
        dispatch(guaranteeRequestSaving(false));
        dispatch(Alert(null, 'Rejected guarantee request!', () => {
            dispatch(removeGuaranteeRequest(type, _id));
            dispatch(replace('/main'));
        }));
    } catch (error) {
        dispatch(guaranteeRequestSaving(false));
        if(error.message !== "You're offline!"){
            dispatch(Alert('Failed to reject guarantee request'));
            console.error('Loan guarantee error', error);
        }
    }
};