import React from 'react';
import { FlRadioButtonGroup } from '../../../../../components/FormControls';
import RangeInput from '../../../../../components/FormControls/RangeInput';

const LoanRepaymentPlan = ({state, onValueChange}) => {
    function onInstallmentsChanged(value){
        const changeTarget = {
            name: "repaymentInstallments", 
            value
        };

        onValueChange({target: changeTarget});
    }

    return (
        <div id="LoanRequestRepaymentPlan" className="RequestLoanSection">  
            <h3 className="loan-request-section-title text-center">
                Pick your payment plan
            </h3>

            <FlRadioButtonGroup
                alignCenter
                name='repaymentFrequency'
                value={state.repaymentFrequency}
                options={["Monthly", "Weekly"]}
                value={state.repaymentFrequency}
                onChange={onValueChange}
                errors={state.validationErrors.repaymentFrequency} />
            
            <div id="installmentCount">
                <h3>Set your preferred number of installments</h3>

                <div>
                    <RangeInput
                        value={state.repaymentInstallments}
                        onChange={onInstallmentsChanged}
                    />
                    
                    <div id="installmentCountNumber" className="layout center-center">
                        <button disabled={state.repaymentInstallments === 1} 
                            onClick={() => onInstallmentsChanged(state.repaymentInstallments - 1)}>
                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M19 13H5v-2h14v2z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                        </button>
                        <h3>{state.repaymentInstallments}</h3>
                        <button disabled={state.repaymentInstallments === 12}
                            onClick={() => onInstallmentsChanged(state.repaymentInstallments + 1)}>
                            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default LoanRepaymentPlan;