import * as API from './server_calls';
import { getToken } from "../prefs";

export const registerUser = (data) => {
    return API.post('/users/register', data);
};

export const sendVerifyEmail = async () => {
    const token = await getToken();
    return API.post('/users/verify', null, token);
};

export const verifyUserAccount = async (verificationToken) => {
    const token = await getToken();
    return API.patch(`/users/verify?token=${verificationToken}`, null, token);
};

export const verifyUserByOtp = async (otp) => {
    const token = await getToken();
    return API.patch('/users/verifyPhoneNumber', {otp}, token);
};

export const resendOtp = async (phoneNumber) => {
    const token = await getToken();
    return API.patch(`/users/resendOTP/`, {phoneNumber}, token);
};