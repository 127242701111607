// import _findIndex from 'lodash/findIndex';

import { 
    LOAN_REQUEST_SUBMITTED,
    SET_LOAN_REQUESTS_FILTER,
    SET_CURRENT_LOAN_REQUEST,
    SET_LOAN_MIN_REQUEST_AMOUNT,
    FETCHING_GUARANTEE_REQUESTS,
    GUARANTEE_REQUESTS_FETCHED,
    SET_CURRENT_GUARANTEE_REQUEST,
    GUARANTEE_REQUEST_SAVING,
    REMOVE_GUARANTEE_REQUEST,
    FETCHING_LOAN_REQUESTS,
    SET_LOAN_REQUESTS
} from '../actions/loan';

const initialState = { 
    requests: null,
    fetching: false,
    requestsFilter: "All",
    currentRequestId: null,
    minRequestAmount: null,
    fetchingGuaranteeRequests: false,
    directGuaranteeRequests: null,
    otherGuaranteeRequests: null,
    currentGuaranteeRequest: null,
};

const loan = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOAN_REQUESTS_FILTER: {
            return {
                ...state,
                requestsFilter: action.payload
            };
        }
        case SET_LOAN_MIN_REQUEST_AMOUNT: {
            return {
                ...state,
                minRequestAmount: action.payload
            };
        }
        case SET_CURRENT_LOAN_REQUEST: {
            return {
                ...state,
                currentRequestId: action.payload
            };
        }
        case LOAN_REQUEST_SUBMITTED: {
            const requests = state.requests || [];
            return {
                ...state,
                requests: [
                    ...requests,
                    action.payload
                ]
            };
        }
        case FETCHING_LOAN_REQUESTS: {
            return {
                ...state,
                fetching: action.payload
            };
        }
        case SET_LOAN_REQUESTS: {
            return {
                ...state,
                requests: action.payload
            };
        }
        case FETCHING_GUARANTEE_REQUESTS: {
            return {
                ...state,
                fetchingGuaranteeRequests: action.payload
            };
        }
        case GUARANTEE_REQUESTS_FETCHED: {
            const {broadcastedRequests, myGuaranteeRequests} = action.payload;
            
            return {
                ...state,
                fetchingGuaranteeRequests: false,
                directGuaranteeRequests: myGuaranteeRequests,
                otherGuaranteeRequests: broadcastedRequests
            };
        }
        case SET_CURRENT_GUARANTEE_REQUEST: {
            return {
                ...state,
                currentGuaranteeRequest: action.payload
            };
        }
        case GUARANTEE_REQUEST_SAVING: {
            let currentGuaranteeRequest = state.currentGuaranteeRequest;

            if(currentGuaranteeRequest){
                currentGuaranteeRequest = {
                    ...currentGuaranteeRequest, 
                    saving: action.payload
                }
            }
                
            return {
                ...state,
                currentGuaranteeRequest
            }
        }
        case REMOVE_GUARANTEE_REQUEST: {
            const { type, requestId } = action.payload;
            if(type === "direct"){
                const directGuaranteeRequests = state.directGuaranteeRequests.filter(request => {
                    return request._id !== requestId;
                });
                return {
                    ...state,
                    directGuaranteeRequests
                }
            }
            
            else if(type === "other"){
                const otherGuaranteeRequests = state.otherGuaranteeRequests.filter(request => {
                    return request._id !== requestId
                });
                return {
                    ...state,
                    otherGuaranteeRequests
                }
            }

            return state;
        }
        default:
            return state;
    }
};

export default loan;
