import React from 'react'
import { connect } from 'react-redux';
import { bootstrapApp } from './store/actions';
import { checkForUpdates } from './store/actions/app';
import Router from './Router';
import FlNotifications from './components/FlNotifications';

class AppWrapper extends React.Component {
  async componentDidMount() {
    await this.props.bootstrapApp();
    // hide splash screen
    document.body.classList.add('loaded');
    this.props.checkForUpdates();
  }

  shouldComponentUpdate() {
    return !this.props.appInitialized;
  }

  render() {
    const { children, history, appInitialized } = this.props;
    return (
      <React.Fragment>
        { appInitialized && 
          <FlNotifications>
            <Router history={history}>
              { children }
            </Router>
          </FlNotifications>
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(state){
  return { 
    appInitialized: state.app.initialized 
  }
}

function mapDispatchToProps(dispatch){
  return {
    bootstrapApp: () => {
      dispatch(bootstrapApp())
    },
    checkForUpdates: () => {
      dispatch(checkForUpdates())
    }
  }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);