import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { replace, goBack, push } from 'connected-react-router';

import { canNotify } from '../../../../components/FlNotifications';
import { fetchUserTransactions, setUserTransactionFilters } from '../../../../store/actions/user-transactions';
import SearchRoute from '../../../../Router/SearchRoute';
import Transactions from '../../components/Transactions';
import TransactionsFilters from '../../components/TransactionsFilters';
import NavBar from '../../components/NavBar';
import FlFab from '../../../../components/FlFab';

import './styles.css'

const Activity = ({ fetchingTransactions, transactionFilters, location, ...props }) => {
    const userTransactions = props.userTransactions
        .filter(transaction => transaction && (transaction.type !== "transfer" || transaction.amount && transaction.amount > 0));

    useEffect(() => {
        if(!props.accounts || !props.accounts.length)
            props.redirectToHome();

        props.fetchUserTransactions();
        props.setFilters(null);
    }, []);

    function handleFiltersSet(filters){
        props.setFilters(filters);
        props.hideFilters();
    }
    
    function handleResetFilters(){
        props.setFilters(null);
        props.hideFilters();
    }

    return (
        <React.Fragment>
            <NavBar noborder onGoBack={props.history.goBack}>
                <div className="top-bar layout center">
                    <span className="ot-header-title left">
                        Activity
                    </span>
                </div>
            </NavBar>

            <div className="mainFragment transactionsFragment">
                { userTransactions &&
                    <React.Fragment>
                        <Transactions
                            fetchingTransactions={ fetchingTransactions }
                            transactionsFetched={ !fetchingTransactions }
                            transactions={ userTransactions }
                            filters={ transactionFilters } />
                        
                        {/* <FlFab id="filterFab" hidden={ !userTransactions || !userTransactions.length || (location.search && location.search.length) } centered onClick={ props.showFilters }>
                            <svg viewBox="0 0 24 24"><path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                            <span>Filter</span>
                        </FlFab> */}
                        
                        <SearchRoute path="showFilters" 
                            component={() => 
                                <TransactionsFilters
                                    accounts={ props.accounts }
                                    filters={ transactionFilters }
                                    onCancel={ props.hideFilters }
                                    onComplete={ handleFiltersSet }
                                    onResetFilters={ handleResetFilters } />
                            } 
                        />
                    </React.Fragment>
                }
            </div>
        </React.Fragment>
    );
}

function mapStateToProps(state){
    return {
        fetchingTransactions: state.userTransactions.fetching,
        transactionsFetched: state.userTransactions.fetched,
        userTransactions: state.userTransactions.transactions,
        transactionFilters: state.userTransactions.filters,
        accounts: state.account.accounts
    }
}

function mapDispatchToProps(dispatch){
    return {
        redirectToHome: () => {
            dispatch(replace('/'));
        },
        fetchUserTransactions: () => {
            dispatch(fetchUserTransactions());
        },
        hideFilters: () => {
            dispatch(goBack());
        },
        showFilters: () => {
            dispatch(push({
                search: 'showFilters'
            }));
        },
        setFilters: ( filter ) => {
            dispatch(setUserTransactionFilters(filter));
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(canNotify(Activity));