import React from 'react';

import FlButton from '../';

import './styles.scss';

const OutlineButton = ({className, ...props}) => {
    className += " OutlineButton";

    return <FlButton {...props} className={className} />
}
 
export default OutlineButton;