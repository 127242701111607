/* eslint-disable no-console */
import { replace, goBack } from 'react-router-redux';
import { signIn, sendResetPasswordLink, setNewPassword, updateProfile, resendOtp, verifyUserAccount, verifyUserByOtp } from '../../services/API'
import { setPrefs, USER_KEY, clearPrefs, getPrefs } from '../../services/prefs';
import { formLoading } from './app';
import { Toast, Alert } from './notifications'
import { checkUserVerification } from '../../utils';
import { sendVerificationText } from './register';

export const AUTH_DESTROY = 'AUTH_DESTROY';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILS = 'AUTH_FAILS';
export const USER_CHANGED = 'USER_CHANGED';

const logout = () => ({
    type: AUTH_DESTROY
});

export const userChanged = (user) => ({
    type: USER_CHANGED,
    user
});

export const authSuccess = (user, idCardIsSet = true, verified = true) => ({
    type: AUTH_SUCCESS,
    user,
    idCardIsSet,
    verified
});

export const authFails = () => ({
    type: AUTH_FAILS,
    isAuthenticated: false
});

export const authLogout = () => async dispatch => {
    await clearPrefs();
    dispatch(logout());
    localStorage.removeItem('A_FINLINK_USER_EXISTS');
}

export const authLogin = (username, password) => async dispatch => {
    dispatch(formLoading(true));

    try {
        const user = await signIn({username, password});
        
        if (!user) {
            dispatch(formLoading(false));
            dispatch(authFails());
            dispatch(Alert('Wrong username or password'));
        } else {
            const cardTypeSet = user.idType && user.idType !== 'NAN';
            const cardNumberSet = user.idNumber && user.idNumber !== 'NAN';
            const cardSet = cardTypeSet && cardNumberSet;

            if(!cardSet)
                user._temp = true;
            
            user.username = username;

            await setPrefs(USER_KEY, user);
            
            dispatch(formLoading(false));
            dispatch(authSuccess(user, cardSet, checkUserVerification(user)));
            dispatch(replace('/'));
            localStorage.setItem('A_FINLINK_USER_EXISTS', true);
        }
    } catch (error) {
        dispatch(formLoading(false));
        if(error.message !== "You're offline!"){
            dispatch(authFails());

            console.log(JSON.stringify(error));

            if(error && error.code === "ECONNABORTED")
                dispatch(Alert('Request took too long to respond, please check your internet connection and try again'));
            else
                dispatch(Alert('Wrong username or password'));
        }
    }
};

export const sendResetPasswordEmail = (email) => async dispatch => {
    dispatch(formLoading(true));

    try {
        const emailSent = await sendResetPasswordLink({email});

        dispatch(formLoading(false));

        if(emailSent){
            dispatch(replace('/reset-password-sent?email=' + email));
            dispatch(Toast('Reset email sent'));
        }
    } catch (error) {
        dispatch(formLoading(false));
        if(error.message !== "You're offline!"){
            dispatch(Toast(`Error sending reset password`));
            console.error(JSON.stringify(error));
        }
    }
};

export const sendResetPasswordOTP = (phoneNumber) => async dispatch => {
    dispatch(formLoading(true));

    try {
        await resendOtp(phoneNumber);
        dispatch(formLoading(false));

        dispatch(replace('/forgot-password/?enterOtpFor=' + phoneNumber));
        dispatch(Toast('Reset otp sent'));
    } catch (error) {
        dispatch(formLoading(false));
        if(error.message !== "You're offline!"){
            dispatch(Alert('Failed to send verification code to phone number'));
            console.error(JSON.stringify(error));
        }
    }
};

export const getResetTokenFromOTP = (otp, phoneNumber) => async dispatch => {
    dispatch(formLoading(true));

    try {
        const {verifyToken} = await verifyUserByOtp(otp);
        dispatch(formLoading(false));
        dispatch(replace(`/users/passwords/reset?token=${verifyToken}&usingPhone=true`));
    } catch (error) {
        if(error.message !== "You're offline!"){
            if(error && error.response && error.response.data && error.response.data.userMessage){
                if(error.response.data.userMessage.indexOf("Unknown OTP") !== -1){
                    dispatch(Alert('Invalid verification code', 'Enter the code sent to your phone and if that deosn\'t work, click \'Resend Code\' to get a new one.'));
                    return dispatch(formLoading(false));
                }
                else if(error.response.data.userMessage === "OTP expired, generate new OTP."){
                    const notificationPayload = {
                        title:"Verification token expired",
                        message:"The verification token you used has expired, resend token?",
                        cancelText:"Cancel",
                        okText:"Resend Token",
                        onCancel: () => dispatch(formLoading(false)),
                        onOkay: () => dispatch(sendVerificationText(phoneNumber))
                    };
        
                    return dispatch(Alert(notificationPayload));
                }
            }

            dispatch(formLoading(false));
            dispatch(Alert('Error verifying account, please try again!'));
        }
        else{
            dispatch(formLoading(false));
        }
    }
};

export const resetPassword = (data, resetToken, usingPhone) => async dispatch => {
    dispatch(formLoading(true));
    try {
        const tokenParamName = usingPhone ? "verifyToken" : "token";
        await setNewPassword(data, resetToken, tokenParamName);
        dispatch(formLoading(false));
        dispatch(replace('/login'));
        dispatch(Toast('Password reset successful'));
    } catch (error) {
        dispatch(formLoading(false));
        if(error.message !== "You're offline!"){
            dispatch(Toast('Error resetting password'));
            console.error(JSON.stringify(error));
        }
    }
};

export const updateProfileDetails = (updatedDetails) => async dispatch => {
    dispatch(formLoading(true));

    try {
        await updateProfile(updatedDetails);
        const authUser = await getPrefs(USER_KEY);
        const updatedUser = {...authUser, ...updatedDetails};
        dispatch(formLoading(false));
        dispatch(authSuccess(updatedUser, true, true));
        dispatch(Alert("", "Details have been saved", () => dispatch(goBack())));
        setPrefs(USER_KEY, updatedUser);
    } catch (error) {
        dispatch(formLoading(false));
        if(error.message !== "You're offline!"){
            dispatch(Toast("Failed to update profile"));
            console.log("Error to update profile details: ", error);
        }
    }
};