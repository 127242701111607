import React, { useState, useEffect } from 'react';

import { OutlineButton } from '../../../../../../components/FlButton';
import { fetchLoanInterestByPurpose } from '../../../../../../services/API';

import './styles.scss';

function PreviewLoan({loanData, onChangeSectionData, onInterestRateChange}){
    const loanPurposeMap = {
        'personal': 'Personal',
        'rent': 'Pay Rent',
        'school' : 'School Fees'
    };
    
    const [purposeInterestMap, setPurposeInterestMap] = useState({
        'personal': null, 'rent': null, 'school' : null
    });

    const interestRate = purposeInterestMap[loanData.loanPurpose];

    async function getLoanInterest(purpose){
        const interest = await fetchLoanInterestByPurpose(purpose);
        const newPuposeInterestMap = {...purposeInterestMap};
        newPuposeInterestMap[purpose] = interest.value;
        setPurposeInterestMap(newPuposeInterestMap);

        onInterestRateChange(interest.value);
    }

    useEffect(() => {
        const newInterestRate = purposeInterestMap[loanData.loanPurpose];

        if(loanData.loanPurpose && loanData.loanPurpose.length && (newInterestRate === null || newInterestRate !== interestRate))
            getLoanInterest(loanData.loanPurpose);
    }, [loanData]);

    return (
        <div id="PreviewLoan" className="RequestLoanSection layout vertical">
            <div className="flex">
                <h3 className="loan-request-section-title">
                    Confirm loan details
                </h3>
                
                <div className="loan-preview-section">
                    { interestRate &&
                        <React.Fragment>
                            <h3>Loan Interest</h3>
                            <ul className="loanInterestRate">
                                <li>
                                    <h5>
                                        { interestRate }%
                                    </h5>
                                </li>
                            </ul>
                        </React.Fragment>
                    }

                    <h3>Loan Details</h3>
                    <ul>
                        <li>
                            <h5>Loan Type</h5>
                            <p>{loanData.loanType}</p>
                        </li>
                        <li className="layout center justified">
                            <div>
                                <h5>Loan Purpose</h5>
                                <p>{loanPurposeMap[loanData.loanPurpose]}</p>
                            </div>

                            <OutlineButton rounded onClick={() => onChangeSectionData(2)}>
                                <svg viewBox="0 0 24 24" width="24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                                Change
                            </OutlineButton>
                        </li>
                        <li className="layout center justified">
                            <div>
                                <h5>Loan Amount</h5>
                                <p>{loanData.amount.toLocaleString()}</p>
                            </div>

                            <OutlineButton rounded onClick={() => 
                                onChangeSectionData(loanData.loanPurpose === 'personal' ? 3 : 4)
                            }>
                                <svg viewBox="0 0 24 24" width="24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                                Change
                            </OutlineButton>
                        </li>
                        <li className="layout center justified">
                            <div>
                                <h5>Loan Guarantors</h5>
                                <p>
                                    { loanData.guarantors.map(({name}) => name).join(', ') }
                                </p>
                            </div>

                            <OutlineButton rounded onClick={() => 
                                onChangeSectionData(loanData.loanPurpose === 'personal' ? 4 : 5)
                            }>
                                <svg viewBox="0 0 24 24" width="24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                                Change
                            </OutlineButton>
                        </li>
                        <li className="layout center justified">
                            <div>
                                <h5>Repayment Plan</h5>
                                <p>
                                    {loanData.repaymentFrequency} for {loanData.repaymentInstallments} {loanData.repaymentFrequency.replace('ly', 's').toLowerCase()}
                                </p>
                            </div>

                            <OutlineButton rounded onClick={() => 
                                onChangeSectionData(loanData.loanPurpose === 'personal' ? 5 : 6)
                            }>
                                <svg viewBox="0 0 24 24" width="24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                                Change
                            </OutlineButton>
                        </li>
                    </ul>
                </div>
                { loanData.loanPurpose !== 'personal' && 
                    <div className="loan-preview-section">
                        <h3>Bank Details</h3>
                        <ul>
                            <li>
                                <h5>Bank</h5>
                                <p>{loanData.bank}</p>
                            </li>
                            <li>
                                <h5>Bank A/c Name</h5>
                                <p>{loanData.bankAccountName}</p>
                            </li>
                            <li>
                                <h5>Bank A/c No.</h5>
                                <p>{loanData.bankAccountNumber}</p>
                            </li>

                            { loanData.loanPurpose === 'school' && 
                                <li>
                                    <h5>Student Name</h5>
                                    <p>{loanData.studentName}</p>
                                </li>
                            }
                        </ul>

                        <OutlineButton rounded onClick={() => onChangeSectionData(3)}>
                            <svg viewBox="0 0 24 24" width="24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                            Change
                        </OutlineButton>
                    </div>
                }
            </div>
        </div>
    )
}
 
export default PreviewLoan;