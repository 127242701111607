import React from 'react';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _flatten from 'lodash/flatten';

import FormValidator from '../../services/FormValidator';
import { canNotify } from '../../components/FlNotifications';

import { authLogin } from '../../store/actions/auth';

import styles from './styles.scss';

import { FlInput } from '../../components/FormControls';
import FlButton from '../../components/FlButton';
import { makePhoneNumberValid } from '../../utils';

const cx = classnames.bind(styles);

class Login extends React.Component {
    state = {
        username: '',
        password: '',
        validationErrors: {},
        initialValidation: false
    }

    validator = new FormValidator({
        username: 'required',
        password: 'min:6'
    });

    validateForm = async () => {
        const validationErrors = await this.validator.validate(this.state);
        this.setState({validationErrors, initialValidation: true});
        const firstInvalidInput = Object.keys(validationErrors)[0];
        const inputNode = document.querySelector(`[name=${firstInvalidInput}]`);
    
        return [_flatten(Object.values(validationErrors)), inputNode];
    }

    handleChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, 
          () => this.state.initialValidation ? this.validateForm() : null
        );
    }
    
    handleSubmit = async (e) => {
        e.preventDefault();
        const [errors, inputNode] = await this.validateForm();
    
        if(errors.length){
            if(inputNode)
                inputNode.focus();

            return this.props.Toast('Form has some errors');
        }
    
        const { username, password } = this.state;
        const formattedUsername = username.indexOf('@') !== -1 ? username : makePhoneNumberValid(username);
        this.props.onLogin(formattedUsername, password);
    }

    render() {
        const {
            username,
            password,
            validationErrors
        } = this.state;

        return ( 
            <div className={cx('authScene')}>
                <form className={cx('form')}
                    onSubmit={this.handleSubmit}>

                    <img src='logo.png' className={cx('logoImage')} alt="" />

                    <div className={cx('formTitle')}>
                        Finlink Login
                    </div>

                    <FlInput
                        type="text"
                        label="Email or phone"
                        name='username'
                        value={username} 
                        onChange={this.handleChange}
                        errors={validationErrors.username} />
                    
                    <FlInput
                        type="password"
                        label="Password"
                        name='password'
                        value={password} 
                        onChange={this.handleChange}
                        errors={validationErrors.password} />

                    <FlButton type="submit" block primary large 
                        loading={this.props.loading}>
                        Login
                    </FlButton>

                    <Link className="forgotPwd" to="/forgot-password">
                        Forgot password?
                    </Link>

                    <div className="siblingPageLink">
                        Not a member? &nbsp;<Link to="/register">Register</Link>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state){
    return {
        loading: state.app.formLoading
    }
}

function mapDispatchToProps(dispatch){
    return {
        onLogin: (username, password) => {
            dispatch( authLogin(username, password) )
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(canNotify(Login));