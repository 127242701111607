import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Route } from 'react-router';

import { getNotifications, markNotificationAsRead } from '../../../../store/actions/app';
import Loader from '../../../../components/Loader';
import FlButton from '../../../../components/FlButton';
import NavBar from '../../components/NavBar';
import FlexBox from '../../../../components/FlexBox';
import Spacer from '../../../../components/Spacer';

import NotificationDetail from './NotificationDetail';
import './styles.scss';

function notificationTitle(notification, authUser){
    let notificationTitle = "";
    if(notification.topic.indexOf('loan') !== -1){
        const { applicant, reason, amount, bankDetails } = notification.resource;

        if(reason === "personal")
            notificationTitle = `Your personal loan of ${amount.toLocaleString()}`;
        else if(reason === "rent")
            notificationTitle = `Your rent payment loan of ${amount.toLocaleString()} to ${bankDetails.bankAccountName}`;
        else if(reason === "fees")
            notificationTitle = `Your school fees loan of ${amount.toLocaleString()} for ${bankDetails.studentName} at ${bankDetails.bankAccountName}`;
        
        if(notification.topic === "loan-broadcasted" && applicant !== authUser._id)
            notificationTitle = `A loan of ${amount.toLocaleString()}`;
    
        notificationTitle += ` has been ${notification.topic.replace("loan-", "")}`;
    }
    else if(notification.topic === "guarantee-request"){
        const { displayName } = notification.actor;
        notificationTitle = `${displayName} sent you a guarantee request`;
    }

    return notificationTitle;
}

function notificationIcon(notification){
    let notificationTitle = "";
    if(notification.topic.indexOf('loan') !== -1){
        return (
            /* <svg viewBox="0 0 16 16"><g><g><path d="M2.5,16h-2C0.224,16,0,15.776,0,15.5v-7C0,8.224,0.224,8,0.5,8h2C2.776,8,3,8.224,3,8.5v7C3,15.776,2.776,16,2.5,16z     M1,15h1V9H1V15z"/><g><path d="M9.66,15H2.5v-1h7.16c0.325,0,0.65-0.112,0.914-0.315l4.229-3.289c0.108-0.084,0.177-0.204,0.193-0.338     c0.016-0.13-0.02-0.257-0.1-0.358c-0.086-0.111-0.206-0.18-0.34-0.196c-0.126-0.01-0.256,0.02-0.358,0.1l-3.562,2.763     C10.12,12.773,9.467,13,8.8,13H5.5v-1h3.3c0.442,0,0.876-0.15,1.22-0.423l3.563-2.763c0.31-0.243,0.704-0.354,1.097-0.304     c0.401,0.049,0.758,0.253,1.006,0.573c0.243,0.309,0.353,0.699,0.304,1.096c-0.049,0.401-0.253,0.758-0.573,1.006l-4.229,3.289     C10.747,14.813,10.205,15,9.66,15z"/></g><path d="M5.5,13C5.224,13,5,12.776,5,12.5S5.224,12,5.5,12c1.208,0,2.217-0.86,2.45-1.999L2.5,10C2.224,10,2,9.776,2,9.5    S2.224,9,2.5,9l0,0l6,0.001c0.276,0,0.5,0.224,0.5,0.5C9,11.431,7.43,13,5.5,13z"/></g><g><path d="M9.5,8h-1C8.224,8,8,7.776,8,7.5S8.224,7,8.5,7h1C9.775,7,10,6.776,10,6.5S9.775,6,9.5,6C8.673,6,8,5.327,8,4.5    S8.673,3,9.5,3h1C10.776,3,11,3.224,11,3.5S10.776,4,10.5,4h-1C9.225,4,9,4.224,9,4.5S9.225,5,9.5,5C10.327,5,11,5.673,11,6.5    S10.327,8,9.5,8z"/><path d="M9.5,4C9.224,4,9,3.776,9,3.5v-1C9,2.224,9.224,2,9.5,2S10,2.224,10,2.5v1C10,3.776,9.776,4,9.5,4z"/><path d="M9.5,9C9.224,9,9,8.776,9,8.5v-1C9,7.224,9.224,7,9.5,7S10,7.224,10,7.5v1C10,8.776,9.776,9,9.5,9z"/></g><path d="M4.91,8C4.716,8,4.531,7.886,4.45,7.696C4.151,6.996,4,6.257,4,5.5C4,2.467,6.467,0,9.5,0C12.532,0,15,2.467,15,5.5   c0,0.756-0.151,1.495-0.45,2.196c-0.107,0.254-0.4,0.372-0.656,0.264c-0.254-0.108-0.372-0.402-0.264-0.656   C13.875,6.728,14,6.121,14,5.5C14,3.019,11.981,1,9.5,1S5,3.019,5,5.5c0,0.622,0.125,1.229,0.37,1.804   C5.479,7.558,5.36,7.852,5.106,7.96C5.042,7.987,4.976,8,4.91,8z"/></g></svg> */
            <svg viewBox="0 0 66 66"><g><path d="M65,49.2c0-3.6-3.5-6.1-6.8-5l-13.3,4.2l-3-0.2c0.5-0.7,0.8-1.5,0.9-2.3c0.3-2.7-1.8-5-4.5-5c0,0,0,0,0,0l-9.2,0   c-3.6-2.6-8.1-4.1-12.6-4.1h-3.3v-2.4c0-0.6-0.4-1-1-1H2c-0.6,0-1,0.4-1,1v20.8c0,0.6,0.4,1,1,1h10.2c0.6,0,1-0.4,1-1v-1.7L33,59.4   c0.8,0.2,1.5,0.3,2.3,0.3c0.9,0,1.8-0.1,2.6-0.4l26.4-9.2C64.7,50,65,49.6,65,49.2z M11.2,54.2H3V35.4h8.2   C11.2,40.1,11.2,49.6,11.2,54.2z M37.2,57.4c-1.2,0.4-2.5,0.4-3.7,0.1l-20.4-6.1V38.8h3.3c4.2,0,8.3,1.4,11.6,3.9   c0.2,0.1,0.4,0.2,0.6,0.2h0l9.6,0c0,0,0,0,0,0c1.5,0,2.7,1.3,2.5,2.8c-0.1,1.4-1.3,2.4-2.7,2.3l-9.3-0.7c-0.5,0-1,0.4-1.1,0.9   c0,0.5,0.4,1,0.9,1.1l16.2,1.2c0.1,0,0.3,0,0.4,0l13.5-4.3c1.9-0.6,3.8,0.6,4.2,2.4L37.2,57.4z"/><path d="M37.5,38.7c0,0,0.1,0,0.1,0c4.3,0,8.4-1.8,11.4-4.8c3-3.1,4.7-7.2,4.7-11.5c0-4.3-1.7-8.4-4.8-11.4   c-3.1-3-7.1-4.7-11.4-4.7c0,0-0.1,0-0.1,0c-8.9,0.1-16.2,7.4-16.1,16.3C21.4,31.5,28.6,38.7,37.5,38.7z M37.4,8.3c0,0,0.1,0,0.1,0   c3.8,0,7.3,1.5,10,4.1c2.7,2.7,4.2,6.2,4.2,10c0,3.8-1.4,7.4-4.1,10.1c-2.7,2.7-6.2,4.2-10,4.2c0,0-0.1,0-0.1,0   c-7.8,0-14.2-6.3-14.2-14.1C23.2,14.7,29.6,8.3,37.4,8.3z"/><path d="M31,27.9c1.8,1.5,3.7,2.4,5.9,2.6l0,1.8l1.5,0l0-1.7c3.1-0.2,5.4-1.9,5.4-4.9c0-3.2-2.3-4.2-5.4-4.9l-0.1,0l0-4.5   c1.2,0.2,2.4,0.6,3.5,1.4l1.5-2.2c-1.6-1.1-3.2-1.7-5-1.8l0-1.2l-1.5,0l0,1.2c-3,0.2-5.2,2-5.2,4.9c0,3.1,2.2,4.1,5.3,4.8l0,4.7   c-1.4-0.2-2.8-0.9-4.2-2.1L31,27.9z M41,25.8c0,1.3-1.2,1.9-2.5,2.1l0-4.3C39.9,24,40.9,24.5,41,25.8z M34.6,18.1   c0-1.2,1.1-1.9,2.3-2l0,4.2C35.5,19.8,34.6,19.4,34.6,18.1z"/></g></svg>
        );
    }
    else if(notification.topic === "guarantee-request"){
        const { displayName } = notification.actor;
        notificationTitle = `${displayName} sent you a guarantee request`;
        return displayName.split(" ").map(name => name.charAt(0)).join("");
    }
}

const Notifications = ({history, notifications, authUser, fetchingNotifications, fetchNotificationsError, fetchUserNotifications, setNotificationReadStatus, viewNotification}) => {
    useEffect(() => {
        // if(!notifications && !fetchingNotifications)
            fetchUserNotifications();
    }, []);

    function handleNotificationClicked(notification){
        if(!notification.readAt)
            setNotificationReadStatus(notification._id);

        viewNotification(notification);
    }

    return (
        <React.Fragment>
            <div id="Notifications" className="layout vertical mainFragment">
                <NavBar 
                    title="Notifications"
                    onGoBack={history.goBack}
                />
    
                <div id="NotificationsContent" className="flex">
                    { fetchingNotifications && 
                        <FlexBox vertical center>
                            <Spacer height="15" />
                            <Loader color="#aaa" />
                            <Spacer height="20" />
                            <span className="accountFetchLoading">
                                Fetching your notifications.
                            </span>
                        </FlexBox>
                    }
    
                    { fetchNotificationsError && (
                        <p>
                            Failed to fetch notifications <FlButton flat onClick={fetchUserNotifications}>RETRY</FlButton>
                        </p>
                    ) }
    
                    { notifications &&
                        notifications.map(notification => {
                            const title = notificationTitle(notification, authUser)
                            if(!title.length)
                                return null;
    
                            return (
                                <div key={notification._id} className={`notification-item layout start ${!notification.readAt ? 'unread' : ''}`}
                                    onClick={() => handleNotificationClicked(notification)}>
                                    <div className="icon layout center-center">
                                        { notificationIcon(notification) }
                                    </div>

                                    <h5>{ title }</h5>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            
            <Route path='/main/notifications/detail' component={NotificationDetail} />
        </React.Fragment>
    );
}

function mapStateToProps(state){
    return {
        authUser: state.auth.user,
        notifications: state.app.notifications.list,
        fetchingNotifications: state.app.notifications.fetching,
        fetchNotificationsError: state.app.notifications.fetchError
    }
}

function mapDispatchToProps(dispatch){
    return {
        fetchUserNotifications: () => {
            dispatch(getNotifications())
        },
        setNotificationReadStatus: (notificationId) => {
            dispatch(markNotificationAsRead(notificationId))
        },
        viewNotification: (notification) => {
            dispatch(push("/main/notifications/detail", notification))
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);