import { APP_INITIALIZED, FORM_LOADING, UPDATE_AVAILABLE, FETCH_NOTIFICATIONS_START, FETCH_NOTIFICATIONS_ERROR, FETCH_NOTIFICATIONS_SUCCESS, SET_NOTIFICATION_AS_READ, SET_SUPPORTED_BANKS } from '../actions/app';

let initialState = { 
  initialized: false, newReleaseDetails: null, formLoading: false,
  supportedBanks: null,
};
initialState.notifications = {
  list: null, 
  fetching: false,
  fetchError: null
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case APP_INITIALIZED:
      return {
        ...state,
        initialized: action.initialized
      };
    case FORM_LOADING:
      return {
        ...state,
        formLoading: action.formLoading
      };
    case UPDATE_AVAILABLE:
      return {
        ...state,
        newReleaseDetails: action.newReleaseDetails
      };
    case FETCH_NOTIFICATIONS_START:
      return {
        ...state,
        notifications: {
          fetching: true,
          fetchError: null,
          list: null
        }
      };
    case FETCH_NOTIFICATIONS_ERROR:
      return {
        ...state,
        notifications: {
          fetching: false,
          list: null,
          fetchError: action.error
        }
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: {
          fetching: false,
          fetchError: null,
          list: action.notifications
        }
      };
    case SET_NOTIFICATION_AS_READ:{
      if(!state.notifications)
        return;
        
      const notifications = state.notifications.map(notification => {
        if(notification._id === action.notificationId)
          notification.readAt = new Date();

        return notification;
      });

      return {
        ...state,
        notifications
      };
    }
    case SET_SUPPORTED_BANKS: {
      return {
          ...state,
          supportedBanks: action.payload
      };
    }
    default:
      return state;
  }
};

export default auth;
