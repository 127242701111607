import React, { useState, useEffect } from 'react';
import _findIndex from 'lodash/findIndex';

import FlButton from '../../../../components/FlButton';
import DateFilter from './DateFilter';

import './styles.css';

const TransactionFilters = ({ accounts, filters, active,  ...props }) => {
    const [open, setOpen] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(-1);
    const [startMonth, setStartMonth] = useState(-1);
    const [endMonth, setEndMonth] = useState(-1);

    useEffect(() => {
        if(filters){
            if(filters.account)
                setSelectedAccount(_findIndex(accounts, ['name', filters.account]));

            if(filters.months && filters.months.length){
                let [ startMonth, endMonth ] = filters.months;
                setStartMonth(startMonth);
                setEndMonth(endMonth);
            }
        }

        setTimeout(() => {
            setOpen(true);
        }, 50);
    }, []);

    function resetFilters(){
        setSelectedAccount(-1);
        setStartMonth(-1);
        setEndMonth(-1);
    }
    
    function onCancel(){
        setOpen(false);

        setTimeout(() => {
            props.onCancel();
        }, 100);
    }
    
    function handleSubmit(e){
        e.preventDefault();
        setOpen(false);
        
        const account = selectedAccount === -1 ? {} : accounts[selectedAccount];
        const months = [startMonth, endMonth].filter(month => month !== -1);

        setTimeout(() => {
            props.onComplete({account: account.name, months});
        }, 100);
    }

    const canReset = selectedAccount !== -1 || startMonth !== -1;

    return ( 
        <div className={'transaction-filters ' + (open ? 'visible' : '')}>
            <div className="transaction-filters-backdrop"
                onClick={onCancel}></div>

            <form className="transaction-filters-content"
                onSubmit={handleSubmit}>

                <div className="transaction-filters-title">
                    Filter Transactions
                </div>
                
                <div className="transaction-filters-body">
                    { !props.fromAccountDetailScreen && 
                        <React.Fragment>
                            <h5>Accounts</h5>
                            <div id="accounts">
                                { accounts.map((account, index) => {
                                        let className = 'account-choice';
                                        className += selectedAccount === index ? ' selected' : '';
        
                                        return (
                                            <button key={account._id} className={className} 
                                                type="button" onClick={() => setSelectedAccount(index)}>
                                                {account.name}
        
                                                <i className="icon">
                                                    <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                </i>
                                            </button>
                                        );
                                    
                                    }) 
                                }
                            </div>
                        </React.Fragment>
                    }
                    <h5>By Month(<small>s</small>)</h5>
                    <div>
                        <DateFilter
                            startMonth={startMonth} 
                            endMonth={endMonth} 
                            onSetStartMonth={setStartMonth}
                            onSetEndMonth={setEndMonth} />
                    </div>
                </div>

                <div className="transaction-filters-actions layout center justified">
                    <FlButton disabled={!canReset} onClick={ resetFilters } type="button" className="transaction-filters-action" inverse rounded>
                        Reset
                    </FlButton>
                    
                    <FlButton type="submit" className="transaction-filters-action" inverse rounded>
                        Done
                    </FlButton>
                </div>
            </form>
        </div>
    );
}
 
export default TransactionFilters;