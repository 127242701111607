import React, { useEffect, useState } from 'react';
import { FlInput } from '../../../../../components/FormControls';
import FlButton from '../../../../../components/FlButton';
import { makeDeposit } from '../../../../../services/API';
import { connect } from 'react-redux';
import { setDepositer } from '../../../../../store/actions/deposit-modal';
import { fetchAccounts } from '../../../../../store/actions/account';
import FlexBox from '../../../../../components/FlexBox';
import Loader from '../../../../../components/Loader';
import { canNotify } from '../../../../../components/FlNotifications';

function AddPaymentCard({ history, authUser, accounts, onFetchAccounts, ...props }){
    const [cardName, setCardName] = useState("Visa Main");
    const [savingCard, setSavingCard] = useState(false);

    useEffect(() => {
        if(!accounts || !accounts.length) onFetchAccounts();
    }, []);

    function handleClose(){
        onFetchAccounts();
        history.replace("/main/profile");
    }

    async function handleSubmit(e){
        e.preventDefault();
        const card = {
            amount: "1000",
            number: authUser.phoneNumber || "+255712",
            provider: "card",
            cardName,
        };

        setSavingCard(true);
        const cardDepositRes = await makeDeposit(accounts[0]._id, card);
        console.log("Add card res: ", cardDepositRes);
        setSavingCard(false);

        if((!cardDepositRes.redirectUrl || !cardDepositRes.redirectUrl.length)){
            if(cardDepositRes.error)
                props.Alert("Failed to create your card!", "Please check card details and try again.");
            else
                props.Alert(null, "Card created successfully");
            
            handleClose();

            return;
        }

        props.Alert(null, "You'll be redirected shortly to finish entering card details");
        setTimeout(() => {
            window.open(cardDepositRes.redirectUrl);
        }, 1500);

        handleClose();
    }

    if(!accounts || !accounts.length){
        return (
            <FlexBox align="center">
                <Loader size={40} /> &emsp; 
                <span style={{color: "#ccc"}}>Please wait...</span>
            </FlexBox>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <FlInput
                label="Amount"
                defaultValue={cardName}
                onChange={e => setCardName(e.target.value)}
            />

            <FlButton type="submit" rounded primary block loading={savingCard}>
                Save Card
            </FlButton>
        </form>
    );
}

function mapStateToProps(state){
    return {
        authUser: state.auth.user,
        accounts: state.account.accounts || [],
    }
}

function mapDispatchToProps(dispatch){
    return {
        onSetDepositor: (account) => {
            dispatch(setDepositer(account))
        },
        onFetchAccounts: (reloading = false) => {
            dispatch( fetchAccounts(null, reloading) )
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(canNotify(AddPaymentCard));