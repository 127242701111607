import React from 'react';
import _flatten from 'lodash/flatten';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router';
import { push, replace } from 'connected-react-router';

import { submitLoanRequest, getLoanMinRequestAmount } from '../../../../store/actions/loan';
import { getSupportedBanks } from '../../../../store/actions/app';

import Slides from '../../../../components/Slides';
import FormValidator from '../../../../services/FormValidator';

import { FlMaskedInput } from '../../../../components/FormControls';
import OptionPicker from '../../../../components/OptionPicker';
import FlModal from '../../../../components/FlModal';
import FlAlert from '../../../../components/FlAlert';
import FlButton, { FAB } from '../../../../components/FlButton';

import AddLoanGuarantor from './Scenes/AddLoanGuarantor';
import BankInformation from './Scenes/BankInformation';
import LoanGuarantors from './Scenes/LoanGuarantors';
import PreviewLoan from './Scenes/PreviewLoan';
import LoanRepaymentPlan from './Scenes/LoanRepaymentPlan';

import './styles.scss';

class RequestLoan extends React.Component {
    state = {
        accountId: '',
        loanType: 'individual',
        loanPurpose: 'personal',
        bank: '',
        bankAccountName: '',
        bankAccountNumber: '',
        studentName: '',
        amount: '',
        guarantors: [],
        repaymentFrequency: "",
        repaymentInstallments: 4,
        inPreviewMode: false,
        currentLoanRequestStep: 1,
        totalLoanRequestSteps: 6,
        validationErrors: {},
        initialValidationTriggered: false,
    };

    constructor(props) {
        super(props);
        this.amountInputRef = React.createRef();
    }

    componentDidMount(){
        const { 
            location, hideAlerts, 
            minLoanRequestAmount, 
            supportedBanks, 
            fetchSupportedBanks,
            fetchLoanMinRequestAmount 
        } = this.props;
        
        if(!minLoanRequestAmount || supportedBanks){
            fetchLoanMinRequestAmount();
            fetchSupportedBanks();
        }

        if(location){
            const currentPath = location.pathname;
            const errorAlertOpen = currentPath.indexOf("error") !== -1;
            const addGuarantorAlertOpen = currentPath.indexOf("addGuarantor") !== -1;

            if(location.search && location.search.length){
                this.setState({
                    accountId: location.search.replace("?accountId=", "")
                })
            }
            else
                this.props.redirectToHome();

            if(errorAlertOpen || addGuarantorAlertOpen)
                hideAlerts();
        }
    }

    validateCurrentStep = async (fromInput) => {
        const step = this.state.currentLoanRequestStep - 1;
        let bankDetailRules = {
            bank: 'required',
            bankAccountName: 'min:3',
            bankAccountNumber: 'min:3'
        };

        if(this.state.loanPurpose === 'school')
            bankDetailRules.studentName = 'min:3';

        const configAmount = this.props.minLoanRequestAmount;
        const amountMinRule = `min:${configAmount ? configAmount : 5000}`;
        let stepRules = [
            { loanType: 'required' },
            { loanPurpose: 'required' },
            bankDetailRules,
            {
                amount: `number|${amountMinRule}`
            },
            {
                guarantors: 'array'
            },
            {
                repaymentFrequency: 'required',
                repaymentInstallments: 'required'
            }
        ];

        if(this.state.loanPurpose === 'personal')
            stepRules.splice(2, 1);
            
        const validationErrors = await new FormValidator(stepRules[step]).validate(this.state);
        const validationPassed = Object.keys(validationErrors).length === 0;
        this.setState({
            validationErrors,
            initialValidationTriggered: true
        });

        if(!validationPassed && !fromInput)
            this.props.showErrorAlert();

        const {currentLoanRequestStep, totalLoanRequestSteps} = this.state;
        
        if(validationPassed && currentLoanRequestStep === totalLoanRequestSteps - 2){
            if(!this.guarantorsCanCoverAmount())
                return false;
        }

        return validationPassed;
    }

    guarantorsCanCoverAmount = () => {
        const totalGuarantorBalance = this.state.guarantors.reduce((sum, {availableBalance}) => sum + availableBalance, 0)

        if(Number(totalGuarantorBalance) < Number(this.state.amount)){
            this.setState({
                validationErrors: {
                    insufficientInvestors: [
                        "Please add one more guarantor"
                    ]
                }
            });
            this.props.showErrorAlert();

            return false;
        }

        return true;
    }

    handleChange = event => {
        const { name, type, checked, value } = event.target;
        this.setState({ [name]: type === 'checkbox' ? checked : value }, () => {
            if(this.state.initialValidationTriggered)
                this.validateCurrentStep(true);
        });
    }

    handleSaveSlide = async () => {
        const { loanPurpose, currentLoanRequestStep, totalLoanRequestSteps } = this.state;
        const stepValidationPassed = await this.validateCurrentStep();
        if(!stepValidationPassed) return;

        if(currentLoanRequestStep === 2 && loanPurpose !== "personal"){
            this.setState({
                currentLoanRequestStep: 3
            });
            return;
        }
        
        const loanIsPersonal = loanPurpose === 'personal';
        const onAmountScreen = (loanIsPersonal && currentLoanRequestStep === 3) || (!loanIsPersonal && currentLoanRequestStep === 4);
        const totalGuarantorBalance = this.state.guarantors.reduce((sum, {balance}) => sum + balance, 0)
        const amountIsLargerThanGuarantorsBalance = Number(totalGuarantorBalance) < Number(this.state.amount);
        if(onAmountScreen && amountIsLargerThanGuarantorsBalance){
            this.setState({
                currentLoanRequestStep: loanIsPersonal ? 4 : 5
            });
            return;
        }
            
        this.setState({
            currentLoanRequestStep: totalLoanRequestSteps
        });
    };

    handleGoToNextSlide = async () => {
        const { currentLoanRequestStep, totalLoanRequestSteps } = this.state;
        
        if(currentLoanRequestStep === totalLoanRequestSteps)
            this.handleSubmit();
        else{
            const stepValidationPassed = await this.validateCurrentStep();

            if(!stepValidationPassed) return;

            let updatedState = {
                initialValidationTriggered: false,
                currentLoanRequestStep: currentLoanRequestStep + 1
            };
                
            if(currentLoanRequestStep === totalLoanRequestSteps - 1)
                updatedState.inPreviewMode = true;

            this.setState(updatedState, () => {
                const { 
                    currentLoanRequestStep, loanPurpose
                } = this.state;

                const loanIsPersonal = loanPurpose === 'personal';
                const onAmountScreen = (loanIsPersonal && currentLoanRequestStep === 3) || (!loanIsPersonal && currentLoanRequestStep === 4);

                setTimeout(() => {
                    if(onAmountScreen && this.amountInputRef && this.amountInputRef.current)
                        this.amountInputRef.current.focus();
                }, 330);
            });
        }
    }

    addGuarantor = (guarantor) => {
        this.setState({guarantors: [...this.state.guarantors, guarantor]})
    }
    
    removeGuarantor = (guarantor) => {
        const guarantors = this.state.guarantors.filter(({_id}) => _id !== guarantor._id);
        this.setState({guarantors});
    }

    handleClose = () => {
        this.props.history.replace('/');
    }

    handleSubmit = async (e) => {
        if(e) e.preventDefault();

        let {
            accountId,
            loanPurpose,
            amount,
            guarantors,
            bank,
            bankAccountName,
            bankAccountNumber,
            repaymentFrequency,
            repaymentInstallments,
            interest
        } = {...this.state};

        let loanRequestData = {
            accountId,
            amount,
            reason: loanPurpose,
            interest,
            repaymentFrequency: repaymentFrequency.toLowerCase(),
            repaymentInstallments: Number(repaymentInstallments),
            guarantors: guarantors.map(({user}) => ({user}))
        }

        if(loanPurpose !== 'personal')
            loanRequestData.bankDetails = {
                bank,
                bankAccountName,
                bankAccountNumber
            };

        this.props.submitLoanRequest(loanRequestData);
    }

    setLoanPurpose = (loanPurpose) => {
        const totalLoanRequestSteps = loanPurpose === 'personal' ? 6 : 7;

        this.setState({loanPurpose, noSlideAnimation: true}, () => {
            this.setState({totalLoanRequestSteps}, () => {
                setTimeout(() => {
                    this.setState({noSlideAnimation: false});
                });
            });
        });
    }

    render() { 
        const {
            loanPurpose,
            amount,
            currentLoanRequestStep,
            totalLoanRequestSteps,
            noSlideAnimation,
            inPreviewMode,
            validationErrors,
        } = this.state;

        const loanTypeOptions = [
            { label: 'Individual', value: 'individual' }
        ];
        
        const loanPurposeOptions = [
            { label: 'Personal', value: 'personal' },
            // { label: 'Pay Rent', value: 'rent' },
            // { label: 'School Fees', value: 'school' }
        ];

        const loanIsPersonal = loanPurpose === 'personal';
        const onAmountScreen = (loanIsPersonal && currentLoanRequestStep === 3) || (!loanIsPersonal && currentLoanRequestStep === 4);
        const onPaymentPlanScreen = currentLoanRequestStep === 1;
        const onLastSlide = currentLoanRequestStep === totalLoanRequestSteps;
        
        return ( 
            <div id="RequestLoan">
                <FlModal pageTitle="Request Loan"
                    onGoback={this.handleClose}>
                    <div id="RequestLoanContent" className="pos-r">
                        <Slides hideProgress 
                            hideActions={inPreviewMode}
                            noSlideAnimation={noSlideAnimation || inPreviewMode}
                            centerCurrentStepLabel={onAmountScreen || onPaymentPlanScreen}
                            step={currentLoanRequestStep} 
                            totalSteps={totalLoanRequestSteps}
                            onPrev={currentLoanRequestStep == 1 ? null : () => this.setState({currentLoanRequestStep: currentLoanRequestStep - 1})}
                            onNext={this.handleGoToNextSlide}
                            renderSubmit={!onLastSlide ? null : () => (
                                <FlButton type="button" block primary
                                    loading={this.props.requestingLoan}
                                    onClick={this.handleSubmit}>
                                    SUBMIT LOAN REQUEST
                                </FlButton>
                            )}>
                            
                            <div className="RequestLoanSection">
                                <h3 className="loan-request-section-title">
                                    What type of loan is this?
                                </h3>

                                <OptionPicker 
                                    options={loanTypeOptions}
                                    defaultValue={loanTypeOptions[0]}
                                    onChange={option => this.setState({loanType: option.value})}
                                />
                            </div>
                            
                            <div className="RequestLoanSection">
                                <h3 className="loan-request-section-title">
                                    What purpose is this loan<br/>going to serve?
                                </h3>

                                <OptionPicker 
                                    options={loanPurposeOptions}
                                    defaultValue={loanPurposeOptions[0]}
                                    onChange={option => this.setLoanPurpose(option.value)}
                                />
                            </div>

                            { !loanIsPersonal &&
                                <BankInformation 
                                    supportedBanks={this.props.supportedBanks}
                                    paymentFor={loanPurpose}
                                    onValueChange={this.handleChange}
                                    state={this.state}
                                />
                            }
                                
                            <div id="loanAmountSection" className="RequestLoanSection">  
                                <h3 className="loan-request-section-title text-center">
                                    Enter loan amount <small>(TZS)</small>
                                </h3>

                                <FlMaskedInput
                                    textCenter
                                    type="text"
                                    name='amount'
                                    mask="currency"
                                    placeholder="enter amount here"
                                    value={amount}
                                    onChange={this.handleChange}
                                    inputRef={this.amountInputRef}
                                    errors={validationErrors.amount} />
                            </div>

                            <LoanGuarantors 
                                guarantors={this.state.guarantors}
                                onRemoveGuarantor={this.removeGuarantor}
                                onAddGuarantor={() => this.props.history.push('/main/requestLoan/addGuarantor')}
                            />
                            
                            <LoanRepaymentPlan
                                onValueChange={this.handleChange}
                                state={this.state}
                            />
                            
                            <PreviewLoan 
                                hidden={currentLoanRequestStep !== totalLoanRequestSteps}
                                loanData={this.state}
                                requestingLoan={this.props.requestingLoan}
                                onChangeSectionData={(currentLoanRequestStep) => this.setState({currentLoanRequestStep})}
                                onInterestRateChange={interest => this.setState({interest})}
                                onSubmit={this.handleSubmit}
                            />

                            { loanIsPersonal && 
                                <div className="RequestLoanSection">
                                    <h3 className="loan-request-section-title">
                                        Dummy placeholder
                                    </h3>
                                </div>
                            }
                        </Slides>

                        { inPreviewMode && !onLastSlide &&
                            <FAB primary ariaLabel="Done" className="SaveFab" 
                                onClick={this.handleSaveSlide}>
                                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="20 6 9 17 4 12"></polyline></svg>
                            </FAB>
                        }

                        <Route path='/main/requestLoan/error' render={() => {
                            if(Object.keys(validationErrors).length > 0){
                                const theErrors = _flatten(Object.values(validationErrors));
                                
                                if(theErrors.includes("Please add one more guarantor")){
                                    return (
                                        <div className="MoreGuarantorsAlert">
                                            <FlAlert>
                                                <p>
                                                    Add another guarantor to speed up processing of your loan.
                                                </p>

                                                <div className="layout center vertical">
                                                    <FlButton large primary onClick={() => this.props.history.replace('/main/requestLoan/addGuarantor?withBack')}>
                                                        Add another guarantor
                                                    </FlButton>
                                                    <FlButton flat large onClick={() => { this.props.hideAlerts(); this.setState({inPreviewMode: true, currentLoanRequestStep: currentLoanRequestStep + 1})} }>
                                                        No, go to next step
                                                    </FlButton>
                                                </div>
                                            </FlAlert>
                                        </div>
                                    )
                                }
                            }

                            return (
                                <FlAlert
                                    message={Object.keys(validationErrors).length === 0 ? '' : Object.values(validationErrors)[0][0]}
                                    onOkay={() => this.props.hideAlerts()}
                                />
                            )}
                        } />
                        
                        <Route path='/main/requestLoan/addGuarantor' render={() => (
                            <AddLoanGuarantor 
                                authUser={this.props.authUser}
                                addedGuarantors={this.state.guarantors}
                                onGuarantorFetched={this.addGuarantor} />
                        )} />
                    </div>
                </FlModal>
            </div>
        );
    }
}

function mapStateToProps(state){
    return {
        authUser: state.auth.user,
        minLoanRequestAmount: state.loan.minRequestAmount,
        supportedBanks: state.app.supportedBanks,
        requestingLoan: state.app.formLoading
    }
}

function mapDispatchToProps(dispatch){
    return {
        redirectToHome: () => {
            dispatch(replace('/'))
        },
        showErrorAlert: () => {
            dispatch(push('/main/requestLoan/error'))
        },
        hideAlerts: () => {
            dispatch(replace('/main/requestLoan'))
        },
        fetchLoanMinRequestAmount: () => {
            dispatch(getLoanMinRequestAmount())
        },
        fetchSupportedBanks: () => {
            dispatch(getSupportedBanks())
        },
        submitLoanRequest: (data) => {
            dispatch(submitLoanRequest(data))
        }
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RequestLoan));