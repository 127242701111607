import * as API from './server_calls';
import { clearPrefs, getToken } from "../prefs";

export const signIn = (data) => {
    return API.post('/users/login', data);
};

export const signOut = () => {
    clearPrefs();
};

export const sendResetPasswordLink = (data) => {
    return API.post('/users/passwords/forgot', data);
};

export const setNewPassword = (data, resetToken, tokenParamName) => {
    return API.post(`/users/passwords/reset?${tokenParamName}=${resetToken}`, data);
};

export const updateProfile = async (data) => {
    const token = await getToken();
    return API.patch('/users/me', data, token);
};