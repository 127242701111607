import { AUTH_DESTROY, AUTH_FAILS, AUTH_SUCCESS, USER_CHANGED } from '../actions/auth';

const initialState = { user: null, idCardIsSet: false, verified: true };

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_DESTROY:
      return initialState;
    case AUTH_SUCCESS:
      return {
        user: action.user,
        idCardIsSet: action.idCardIsSet,
        verified: action.verified
      };
    case USER_CHANGED:
      return {
        ...state,
        user: action.user
      };
    case AUTH_FAILS:
      return initialState;
    default:
      return state;
  }
};

export default auth;