import React from 'react';
import { Route } from 'react-router-dom';
import queryString from 'query-string';

const SearchRoute = ({ component: Component, path, ...routeProps }) => (
    <Route 
        {...routeProps}
        component={({ location, ...props }) => {
            const pathMatches = location.search.indexOf(path) !== -1;
            const value = queryString.parse(location.search)[path];
            const allProps = { ...props, value, location }

            return (
                pathMatches && <Component {...allProps} />
            );
        }}
    />
)

export default SearchRoute;