import { createSelector } from 'reselect';

const getAllLoanRequests = state => state.loan.requests;
const getCurrentLoanRequestId = state => state.loan.currentRequestId;
export const getLoanRequestsFilter = state => state.loan.requestsFilter ? state.loan.requestsFilter.toLowerCase() : "";

export const getLoanRequests = createSelector(
  [getAllLoanRequests, getLoanRequestsFilter],
  (loanRequests, filter) => loanRequests ? null : loanRequests.filter(({status}) => filter === "all" || status.toLowerCase() === filter)
);

export const getCurrentLoanRequest = createSelector(
  [getAllLoanRequests, getCurrentLoanRequestId],
  (loanRequests, loanId) => !loanRequests ? null : loanRequests.find(({_id}) => _id === loanId)
);