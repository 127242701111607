import React, { useEffect, useRef } from 'react';

import FlButton, { FAB } from '../FlButton';

import "./styles.scss";

const Slides = ({
    step, 
    totalSteps, 
    children, 
    hideProgress, 
    hideCurrentStepLabel, 
    centerCurrentStepLabel,
    hideActions,
    noSlideAnimation,
    onPrev, 
    onNext,
    onSubmit,
    submitText = "SUBMIT",
    renderSubmit
}) => {
    const slidesContent = useRef(null);

    useEffect(() => {
        if(slidesContent && slidesContent.current)
            slidesContent.current.scrollTop = 0;
    }, [step]);

    function displaySubmitButton(){
        if(typeof renderSubmit === 'function')
            return renderSubmit();
        else 
            return (
                <FlButton type="button" onClick={onSubmit}>
                    { submitText }
                </FlButton>
            );
    }

    const showSubmitButton = typeof onSubmit === 'function' || typeof renderSubmit === 'function';
    let className= 'layout vertical pos-r';
    className += hideActions || showSubmitButton ? ' no-actions' : '';
    className += showSubmitButton ? ' show-submit-button' : '';
    className += noSlideAnimation ? ' no-slide-animation' : '';

    return (
        <div id="Slides" className={className}>
            { !hideProgress && <div id="SlidesProgress" className="pos-a fill-x" style={{width: `${100 * step / totalSteps}vw`}} /> }

            { !hideCurrentStepLabel && (
                <React.Fragment>
                    <div id="SlidesCurrentStepLabel" 
                        className={`pos-a centered ${centerCurrentStepLabel ? 'visible' : '' }`}>
                        STEP { step } OF { totalSteps }
                    </div> 
                    
                    <div id="SlidesCurrentStepLabel" 
                        className={`pos-a ${!centerCurrentStepLabel ? 'visible' : '' }`}>
                        STEP { step } OF { totalSteps }
                    </div> 
                </React.Fragment>
            )}

            <div id="SlidesContent" ref={slidesContent}>
                <div className="flex layout" style={{width: `${totalSteps * 100}vw`, transform: `translateX(${-100 * (step - 1) / totalSteps}%)`}}>
                { children }
                </div>
            </div>

            <div id="SlidesActions" className="layout center justified">
                <FlButton className={`SlideBackButton ${typeof onPrev != 'function'  ? 'disabled' : ''}`} flat onClick={onPrev}>
                    <svg role="image" height="20" width="20" viewBox="0 0 24 24"><path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"/></svg>
                    &nbsp;
                    Back
                </FlButton>

                <FAB ariaLabel="Go Next" className={typeof onNext != 'function' ? 'disabled': ''} onClick={onNext} mini style={{backgroundColor: "#999999", color: "#3a3a3a"}}>
                    <svg width="24" height="24" viewBox="0 0 24 24"><path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"/></svg>
                </FAB>
            </div>

            { showSubmitButton &&
                <div className="SubmitButton">
                    { displaySubmitButton() }
                </div> 
            }
        </div>
    );
}
 
export default Slides;

Slides.defaultProps = {
    onPrev: () => {},
    onNext: () => {}
}